<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F5f6f7;">
  <br>
  <div class="container h-100" style="background-color: #fff;">
    <div class=" row justify-content-center h-100">
      <div class=" col-lg-6">
        <img src="../../../../src/assets/img/helpdesk/empresa/eliminar.png" class="img-fluid" width="100" height="33" loading="lazy">
        <br /><br />
      </div>
      <div class=" text- col-lg-6">
        <img src="../../../../src/assets/img/helpdesk/conocimiento/guardar.png" class="img-fluid" width="100" height="33" loading="lazy">
        <img src="../../../../src/assets/img/helpdesk/conocimiento/publicar.png" class="img-fluid" width="100" height="33" loading="lazy">
      </div>
    </div>
  </div>
  <br>  <br>
 
  <div class="container h-100" style="background-color: #fff;">
    <div class=" row justify-content-center h-100">
      <div class="divscroll col-lg-12">
        <p id="roboto-help14">Nuevo Articulo</p>
  
        <form>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Titulo</label>
            <div class="col-sm-8">
              <input type="email" class="form-control" id="inputEmail3" placeholder="Email">
            </div>
          </div>
        
        </form>

        <form class="form-inline">
          <div class="form-group mx-sm-12 mb-2">
          
            <div class="form-group">
              <label > <pre>Tema   </pre></label>
              <select class="form-control" id="exampleFormControlSelect1">
                <option>ssssssssssss1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>

          <div class="form-group mx-sm-3 mb-2">
            <label>SubTema</label>
            <input type="text" class="form-control">
          </div>

        </form>
        <p>
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
        </p>

      </div>
      <div class="col-lg-6">
        <div class="container h-100">
          <p id="roboto-help14">Titulo</p>
          <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=" ">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="">First and last name</span>
            </div>
            <input type="text" class="form-control">
            <input type="text" class="form-control">
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>  <br>
</div>

<div class="content" role="main">
  <div class="card">
    <div class="form-group">
      <label for="search-text">Search Text</label>
      <input type="email" class="form-control" id="search-text" aria-describedby="search-text"
             [(ngModel)]="searchText" placeholder="Enter text to search"
             autofocus>
    </div>
    <ul class="list-group list-group-flush">
      <!-- results of ngFor is passed to appFilter with argument searchText -->
      <li class="list-group-item" *ngFor="let c of characters | busqueda: searchText">
        {{c}}
      </li>
    </ul>
  </div>
</div>

<br><br><br><br>
<br><br><br><br>
