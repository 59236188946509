<app-navbar></app-navbar>
<nav class="navbar zindex-syscom sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/busurbano']">
          <img src="../../../../src/assets/img/productos/logosmenu/busesm.png" width="200" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/busurbano#caracteristicas" title="Ir a caracteristicas"> Características</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/busurbano#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/buses/img1.png" class="img-fluid" alt="Responsive Image" width="800 " height="416">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/buses/img2.png" class="img-fluid" alt="Responsive Image" width="390" height="339">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        La aplicación <font class="font-weight-bold"> BUSES URBANOS SYSCOM, </font>tiene como
        objetivo brindar al sector transportador de pasajeros información sobre
        el control operativo, administración de cupos, cantidad de pasajeros movilizados,
        emisión de cartulinas, despachos y puntos de recibo de dinero, regulación
        de nómina de conductores, entre otros procesos.
      </p>
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br /><br /><br /><br />

<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 2-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Brinda una contabilidad automática y transparente, informes
        financieros y estadísticos de operación sobre la trazabilidad
        de su empresa y ofrece una estricta seguridad de los datos.
        Cuenta con la sección Recibo de recaudo Empresarial donde se puede
        importar al archivo del banco las consignaciones.
      </p>
    </div>

    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/buses/img3.png" class="img-fluid" alt="Responsive Image" width="380" height="386">
    </div>
  </div>
</div>

<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br> 
  <div class=" container col-sm-8">
    <p id="roboto-blue-dark"> El uso de este aplicativo le aportará significativamente en la toma de decisiones de forma asertiva, logrando con ello disminuir los riesgos, aumentar la productividad y elevar la rentabilidad de las operaciones.</p>
  </div>
  <br> 
</div>
<div id="funcionalidad"></div>
<br>
<br>
<br>
<br>
<br>
<div id="funcionalidad"></div>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li><p class="roboto">Recibo de Recaudo Empresarial.</p></li>
        <li><p class="roboto">Hoja de Vida de Conductores y Vehículos.</p></li>
        <li><p class="roboto">Contratos de Conductores y Vehículos.</p></li>
        <li><p class="roboto">Despachos—Cartulinas.</p></li>
        <li><p class="roboto">Causación Cuenta Corriente.</p></li>
        <li><p class="roboto">Recibos e Ingresos a Caja.</p></li>
        <li><p class="roboto">Tesorería.</p></li>
        <li><p class="roboto">Procesos Jurídicos—Siniestros.</p></li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li><p class="roboto">Control de Vales de Combustible.</p></li>
        <li><p class="roboto">Aprobaciones y Cuentas por Pagar.</p></li>
        <li><p class="roboto">Gestión de Nómina.</p></li>
        <li><p class="roboto">Conciliación Bancaria.</p></li>
        <li><p class="roboto">Medios Magnéticos y Contabilidad NIIF.</p></li>
        <li><p class="roboto">Alarmas y Notificaciones por Correo.</p></li>
        <li><p class="roboto">Activos Fijos y Diferidos.</p></li>
        <li><p class="roboto">Requisiciones y Compras.</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-7 align-self-center text-center">
      <p id="roboto-center18">
        ¿Tiene alguna pregunta sobre cómo la aplicación <font class="font-weight-bold"> Buses Urbanos </font>puede ayudar a su negocio?
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br>
      <img src=".../../../../src/assets/img/productos/activos.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="253" />
    </div>
  </div>
</div>
<br>
<br>
<br>
