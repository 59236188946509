  <nav class="navbar sticky-top navbar-expand-lg navbar-light " style="background-color: #F4F4F4;">
    <div class="container" style="background-color: #FFF;">
      <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <a class="navbar-brand" (click)="scrollTop()"[routerLink]="['/login']">
        <img src="../../../../src/assets/img/helpdesk/Nav/iconosyscom.png" width="45" height="48" class="img-fluid">
      </a>
      <div class="collapse navbar-collapse navbar-brand" id="navbarSupportedContent">
        <div class=" navbar-nav mr-auto ml-auto">
          
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item centrarvertical dropdown">
              <a class="nav-link" (click)="scrollTop()"[routerLink]="['/login']" title="Ir a Helpdesk">
                <img src="../../../../src/assets/img/helpdesk/Nav/Inicio.png" width="25" height="20" alt="Ir al correo corporativo">
                Inicio
              </a>

            </li>
            <li class="nav-item centrarvertical">
              <a class="nav-link" (click)="scrollTop()"[routerLink]="['/tickets']" title="Ver Tickets">
                <img src="../../../../src/assets/img/helpdesk/Nav/Tickets.png" width="25" height="20" alt="Ir al correo corporativo">
                Tickets
              </a>
            </li>

            <li class="nav-item centrarvertical dropdown">
              <a  class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true">
                <img src="../../../../src/assets/img/helpdesk/Nav/Empresas.png" width="25" height="20" />
                <span class="nav-label">Empresas</span> <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="scrollTop()"[routerLink]="['/empresas']">Empresas</a></li>
                <li><a (click)="scrollTop()"[routerLink]="['/contactoshelp']" >Contactos</a></li>
                <li class="dropdown-submenu">
                </li>
              </ul>
            </li>
            <li class="nav-item centrarvertical">
              <a class="nav-link" (click)="scrollTop()"[routerLink]="['/conocimiento']" title="Ir a base de conocimientos">
                <img src="../../../../src/assets/img/helpdesk/Nav/Conocimiento.png" width="25" height="20" alt="">
                Base del conocimiento
              </a>
            </li>
            <!--<li class="nav-item centrarvertical">
              <a class="nav-link"(click)="scrollTop()" [routerLink]="['/contactohelpdesk']" title="Ir a contacto">
                <img src="../../../../src/assets/img/helpdesk/Nav/Fac.png" width="25" height="20" alt="Ir al correo corporativo">
                FAQ
              </a>
            </li>-->

            <li class="nav-item  dropdown">
              <button class="btn " type="button" data-toggle="dropdown" title="Contactar agencias por Whatsapp">
                <img src="../../../../src/assets/img/helpdesk/Nav/nuevo1.png" width="100" height="31" />
              </button>
              <ul class="dropdown-menu">
                <li><a (click)="scrollTop()"[routerLink]="['/nuevoticket']">Nuevo Ticket</a></li>
                <li><a (click)="scrollTop()"[routerLink]="['/crearempresa']">Nueva Empresa</a></li>
                <li><a (click)="scrollTop()"[routerLink]="['/crearcontacto']">Nuevo Contacto</a></li>
                <li class="dropdown-submenu">
                </li>
              </ul>
            </li>
            <li class="nav-item centrarvertical">
              <form class="form-inline">
    <input id="form-helpdesk" type="search" placeholder="Buscar" aria-label="Search" title="Buscar contenido">
  </form>
            </li>
            <li class="nav-item centrarvertical">
              <a class="nav-link"(click)="scrollTop()" [routerLink]="['/contacto']" title="Ir a contacto">
                <img src="../../../../src/assets/img/helpdesk/Nav/Perfil.png" width="30" height="30" alt="Ir al correo corporativo">
                Hola, Yaqueline
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
