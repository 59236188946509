import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mantvehicular',
  templateUrl: './mantvehicular.component.html',
  styleUrls: ['./mantvehicular.component.scss']
})
export class MantvehicularComponent implements OnInit {
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  constructor() { }
 
  ngOnInit(): void {
  }

}
