<app-navhelpdesk></app-navhelpdesk>



<!--seccion 1-->
<div class="nav" style="background-color: #F4F4F4;">
  <div class="container" style="background-color: #FFF;">
    <div class="container">
      <form class="row gx-3 gy-2 align-items-center">
        <div class="col-sm-3">

          <div class="form-check">
            <label class="form-check-label" for="autoSizingCheck2">
              Tickets de servicio
            </label>
          </div>
        </div>
        <div class="col-sm-3">
        </div>
        <div class="col-sm-3">
        </div>
        <div class="col-auto">
          <p>9 de 26</p>
        </div>
        <div class="col-auto">
          <img src="../../../../src/assets/img/helpdesk/ticket/flecha1.png" width="80" height="10" class="img-fluid">
        </div>
      </form>
    </div>
  </div>
  <!--seccion 2-->
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-sm-7 align-self-center text-center" style="background-color: #F4F4F4;">
      </div>
      <div class="col-sm-1 align-self-center text-center" style="background-color: #F4F4F4;"> </div>
      <div class="col-sm-4 align-self-center text-center" style="background-color: #FFF;">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="divscroll col-sm-8 align-self-center text-center" style="background-color: #FFF;">
        <!--<div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3" style="background-color:#fff">
              Contacto
              <hr>
              <a routerLink="/verticket"> <p>Alejandro Cortez</p></a>
            </div>
            <div class="col-md-3" style="background-color:#fff">
              Solicitud
              <hr>
              <a routerLink="/verticket"> <p>101200 Syscom 20 Estaciones</p></a>
            </div>
            <div class="col-md-2" style="background-color:#fff">
              Clase
              <hr>
              <a routerLink="/verticket"><p>Actividad</p></a>
            </div>
            <div class="col-md-2" style="background-color:#fff">
              Estado
              <hr>
              <a routerLink="/verticket"><p class="bg-success text-white">Hecho</p></a>
            </div>
            <div class="col-lg-2" style="background-color:#fff">
              Creado
              <hr>
              <a routerLink="/verticket"><p>2 dic 2020 12:35</p></a>
            </div>
          </div>
          <hr>
        </div>-->
        <table style="width:100%">
          <tr>
            <th style="width:10px">Contacto </th>
            <th style="width:10px">Solicitud</th>
            <th style="width:10px">Clase</th>
            <th style="width:100px">Estado</th>
          </tr>

          <tr>
            <td style="width:10px"><p>Alejandro Cortez   </p></td>
            <td style="width:10px;"><p>Actividad</p></td>
            <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
            <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
          </tr>
          <tr>
            <td style="width:10px"><p>Alejandro Cortez   </p></td>
            <td style="width:10px;"><p>Actividad</p></td>
            <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
            <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
          </tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr><tr>
  <td style="width:10px"><p>Alejandro Cortez   </p></td>
  <td style="width:10px;"><p>Actividad</p></td>
  <td style="width:10px;"><p class="bg-success text-white">Hecho</p></td>
  <td style="width:100px;"><p>2 dic 2020 12:35</p></td>
</tr>
        </table>
        
        <ul class="pagination justify-content-end" style="margin:20px 0">
          <li class="page-item"><a class="page-link" href="#">Previous</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
        <!--<table class="table">
          <thead>
            <tr>
              <th style="width:15px;" scope="col">Contacto</th>
              <th style="width:15px;" scope="col">Solicitud</th>
              <th style="width:15px;" scope="col">Clase</th>
              <th style="width:15px;" scope="col">Estadosssssssssssssssssssssss</th>
            </tr>
          </thead>

          <tbody>
            <tr>

              <td><a routerLink="/verticket"> <p>Alejandro Cortez   </p></a></td>
              <td><a routerLink="/verticket"><p>Actividad</p></a></td>
              <td><a routerLink="/verticket"><p class="bg-success text-white">Hecho </p></a></td>
              <td><a routerLink="/verticket"><p>2 dic 2020 12:35</p></a></td>

            </tr>
            <tr>
              <td><p>Alejandro Cortez</p></td>
              <td><p>Base de datos</p></td>
              <td><p class="bg-primary text-white">En curso </p></td>
              <td><p>4 ene 2021 13:40</p></td>
            </tr>
          </tbody>

        </table>-->
        <!--<a routerLink="/verticket">
          <table class="table">
            <tr>
              <td><p>Alejandro Cssssssssssssssssssortez</p></td>
              <td><p>Activsssssssssssssssssssssssssidads</p></td>
              <td><p class="bg-success text-white">En szszszszszszszszszszszszcurso </p></td>
              <td><p>4 ene 2021 13:40</p></td>
            </tr>
          </table>
        </a>
        <a routerLink="/verticket">
          <table class="table">
            <tr>
              <td><p>Alejandro Cortez</p></td>
              <td><p>Base de datos</p></td>
              <td><p class="bg-primary text-white">En cursodddddddddddddddd </p></td>
              <td><p>4 ene 2021 13:40</p></td>
            </tr>
          </table>
        </a>-->
        <!--<div class="container">
          <hr>
          <a routerLink="/verticket" >
            <div class="row">
              <div class="col-md-3" style="background-color:#fff">
                Alejandro Cortez
              </div>
              <div class="col-md-3" style="background-color:#fff">
                101200 Syscom 20 Estaciones
              </div>
              <div class="col-md-2" style="background-color:#fff">
                Actividad
              </div>
              <div class="col-md-2 ">
                <p class="bg-success text-white">Hecho</p>
              </div>
              <div class="col-lg-2" style="background-color:#fff">
                2 dic 2020 12:35
              </div>
            </div>
          </a>
          <hr>
        </div>-->

      </div>
      <div class="col-sm-4  text-center" style="background-color: #F4F4F4;">
        <p id="roboto-help31">
          Filtro
        </p>
        <p id="roboto-help32">
          Creado
        </p>
        <select class="form-select" aria-label="Default select example">
          <option selected>Seleccione una opción</option>
          <option value="1">Hoy</option>
          <option value="2">Ayer</option>
          <option value="3">Hace una semana</option>
          <option value="3">Hace un mes</option>
        </select>
        <br><br>

        <p id="roboto-help32">Contacto</p>

        <select class="form-select" aria-label="Default select example">
          <option selected>Seleccione una opción</option>
          <option value="1">Alejandro Cortez</option>
          <option value="2">Carlos Jaimes</option>
          <option value="3">Daniela Moreno</option>
        </select>
        <br><br>
        <p id="roboto-help32">Clase</p>

        <select class="form-select" aria-label="Default select example">
          <option selected>Seleccione una opción</option>
          <option value="1">Carta</option>
          <option value="2">Contrato</option>
          <option value="3">Correctivos</option>
          <option value="3">Base de Datos</option>
        </select>
        <br><br>
        <p id="roboto-help32">Estado</p>

        <select class="form-select" aria-label="Default select example">
          <option selected>Seleccione una opción</option>
          <option value="1">En Curso</option>
          <option value="2">Hecho</option>
          <option value="3">Pendiente</option>
        </select>
        <br><br>

        <button routerLink="/verticket" class="btn btn-helpdesk2" type="submit" title="Crear el Ticket">Aplicar</button>
      </div>
    </div>
  </div>

</div>

<script src="https://unpkg.com/tableexport.jquery.plugin/tableExport.min.js"></script>
<script src="https://unpkg.com/bootstrap-table@1.18.2/dist/bootstrap-table-locale-all.min.js"></script>

<style>
  .select,
  #locale {
    width: 100%;
  }

  .like {
    margin-right: 10px;
  }
</style>

<div class="select">
  <select class="form-control" id="locale">
    <option value="af-ZA">af-ZA</option>
    <option value="ar-SA">ar-SA</option>
    <option value="ca-ES">ca-ES</option>
    <option value="cs-CZ">cs-CZ</option>
    <option value="da-DK">da-DK</option>
    <option value="de-DE">de-DE</option>
    <option value="el-GR">el-GR</option>
    <option value="en-US" selected>en-US</option>
    <option value="es-AR">es-AR</option>
    <option value="es-CL">es-CL</option>
    <option value="es-CR">es-CR</option>
    <option value="es-ES">es-ES</option>
    <option value="es-MX">es-MX</option>
    <option value="es-NI">es-NI</option>
    <option value="es-SP">es-SP</option>
    <option value="et-EE">et-EE</option>
    <option value="eu-EU">eu-EU</option>
    <option value="fa-IR">fa-IR</option>
    <option value="fi-FI">fi-FI</option>
    <option value="fr-BE">fr-BE</option>
    <option value="fr-FR">fr-FR</option>
    <option value="he-IL">he-IL</option>
    <option value="hr-HR">hr-HR</option>
    <option value="hu-HU">hu-HU</option>
    <option value="id-ID">id-ID</option>
    <option value="it-IT">it-IT</option>
    <option value="ja-JP">ja-JP</option>
    <option value="ka-GE">ka-GE</option>
    <option value="ko-KR">ko-KR</option>
    <option value="ms-MY">ms-MY</option>
    <option value="nb-NO">nb-NO</option>
    <option value="nl-NL">nl-NL</option>
    <option value="pl-PL">pl-PL</option>
    <option value="pt-BR">pt-BR</option>
    <option value="pt-PT">pt-PT</option>
    <option value="ro-RO">ro-RO</option>
    <option value="ru-RU">ru-RU</option>
    <option value="sk-SK">sk-SK</option>
    <option value="sv-SE">sv-SE</option>
    <option value="th-TH">th-TH</option>
    <option value="tr-TR">tr-TR</option>
    <option value="uk-UA">uk-UA</option>
    <option value="ur-PK">ur-PK</option>
    <option value="uz-Latn-UZ">uz-Latn-UZ</option>
    <option value="vi-VN">vi-VN</option>
    <option value="zh-CN">zh-CN</option>
    <option value="zh-TW">zh-TW</option>
  </select>
</div>

<div id="toolbar">
  <button id="remove" class="btn btn-danger" disabled>
    <i class="fa fa-trash"></i> Delete
  </button>
</div>
<table id="table"
       data-toolbar="#toolbar"
       data-search="true"
       data-show-refresh="true"
       data-show-toggle="true"
       data-show-fullscreen="true"
       data-show-columns="true"
       data-show-columns-toggle-all="true"
       data-detail-view="true"
       data-show-export="true"
       data-click-to-select="true"
       data-detail-formatter="detailFormatter"
       data-minimum-count-columns="2"
       data-show-pagination-switch="true"
       data-pagination="true"
       data-id-field="id"
       data-page-list="[10, 25, 50, 100, all]"
       data-show-footer="true"
       data-side-pagination="server"
       data-url="https://examples.wenzhixin.net.cn/examples/bootstrap_table/data"
       data-response-handler="responseHandler"></table>

<script>
  var $table = $('#table')
  var $remove = $('#remove')
  var selections = []

  function getIdSelections() {
    return $.map($table.bootstrapTable('getSelections'), function (row) {
      return row.id
    })
  }

  function responseHandler(res) {
    $.each(res.rows, function (i, row) {
      row.state = $.inArray(row.id, selections) !== -1
    })
    return res
  }

  function detailFormatter(index, row) {
    var html = []
    $.each(row, function (key, value) {
      html.push('<p><b>' + key + ':</b> ' + value + '</p>')
    })
    return html.join('')
  }

  function operateFormatter(value, row, index) {
    return [
      '<a class="like" href="javascript:void(0)" title="Like">',
      '<i class="fa fa-heart"></i>',
      '</a>  ',
      '<a class="remove" href="javascript:void(0)" title="Remove">',
      '<i class="fa fa-trash"></i>',
      '</a>'
    ].join('')
  }

  window.operateEvents = {
    'click .like': function (e, value, row, index) {
      alert('You click like action, row: ' + JSON.stringify(row))
    },
    'click .remove': function (e, value, row, index) {
      $table.bootstrapTable('remove', {
        field: 'id',
        values: [row.id]
      })
    }
  }

  function totalTextFormatter(data) {
    return 'Total'
  }

  function totalNameFormatter(data) {
    return data.length
  }

  function totalPriceFormatter(data) {
    var field = this.field
    return '$' + data.map(function (row) {
      return +row[field].substring(1)
    }).reduce(function (sum, i) {
      return sum + i
    }, 0)
  }

  function initTable() {
    $table.bootstrapTable('destroy').bootstrapTable({
      height: 550,
      locale: $('#locale').val(),
      columns: [
        [{
          field: 'state',
          checkbox: true,
          rowspan: 2,
          align: 'center',
          valign: 'middle'
        }, {
          title: 'Item ID',
          field: 'id',
          rowspan: 2,
          align: 'center',
          valign: 'middle',
          sortable: true,
          footerFormatter: totalTextFormatter
        }, {
          title: 'Item Detail',
          colspan: 3,
          align: 'center'
        }],
        [{
          field: 'name',
          title: 'Item Name',
          sortable: true,
          footerFormatter: totalNameFormatter,
          align: 'center'
        }, {
          field: 'price',
          title: 'Item Price',
          sortable: true,
          align: 'center',
          footerFormatter: totalPriceFormatter
        }, {
          field: 'operate',
          title: 'Item Operate',
          align: 'center',
          clickToSelect: false,
          events: window.operateEvents,
          formatter: operateFormatter
        }]
      ]
    })
    $table.on('check.bs.table uncheck.bs.table ' +
      'check-all.bs.table uncheck-all.bs.table',
      function () {
        $remove.prop('disabled', !$table.bootstrapTable('getSelections').length)

        // save your data, here just save the current page
        selections = getIdSelections()
        // push or splice the selections if you want to save all data selections
      })
    $table.on('all.bs.table', function (e, name, args) {
      console.log(name, args)
    })
    $remove.click(function () {
      var ids = getIdSelections()
      $table.bootstrapTable('remove', {
        field: 'id',
        values: ids
      })
      $remove.prop('disabled', true)
    })
  }

  $(function () {
    initTable()

    $('#locale').change(initTable)
  })
</script>


