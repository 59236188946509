<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F5f6f7;">
  <br>
  <div class="container h-100">
    <div class="row justify-content-center h-100">
      <div class="col-sm-6 align-self-center text-center" style="background-color: #FFF;">
        <p id="roboto-help12">
          T  I  C  K  E  T  S
        </p>
      </div>
    </div>
  </div>
  <div class="container">
    <br />
  </div>
  <div class="container h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-2">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-contend">
            Creados
          </p>
          <p id="roboto-help14">
            30
          </p>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-contend">
            Pendiente
          </p>
          <p id="roboto-help14">
            4
          </p>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-contend">
            En curso
          </p>
          <p id="roboto-help14">
            12
          </p>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-contend">
            Resueltos
          </p>
          <p id="roboto-help14">
            17
          </p>
        </div>
      </div>

    </div>
  </div>

  <div class="container h-100">
    <div class="row justify-content-center h-100">
      <div class="col-lg-6">
        <div class="col-sm-12 align-self-center text-justify" style="background-color: #FFF;">
          <p id="roboto-help14">
            Base del Conocimiento
          </p>
          <div class="divscroll col-lg-12">

            <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
 
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                    <button class="btn dropdown-toggle" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Activos Fijos 40
                    </button>
                  
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Errores Activos Fijos 40
                    <br>
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Errores Activos Fijos 40
                    <br>
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Tutorial Activos Fijos 40
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                    <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Gestión Comercial
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">

                    Errores Gestión Comercial
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                    <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Mantenimiento Preventivo
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Errores Mantenimiento Preventivo
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                    <button class=" btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Soporte técnico
                    </button>
                  </h5>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="card-body">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Errores Soporte técnico
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                    <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Syscom 20 Estaciones de Servicio
                    </button>
                  </h5>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                  <div class="card-body">
                    <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                    Errores Syscom 20 Estaciones de Servicio
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingSix">
                    <h5 class="mb-0">
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                      <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive">
                        Syscom 40 Activos Fijos
                      </button>
                    </h5>
                  </div>
                  <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                    <div class="card-body">
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                      Errores Syscom 40 Activos Fijos
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-help14">
            Actividad Reciente
          </p>
          <div id="accordion">
            <div class="card">
              <div class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="collapse show" aria-labelledby="headingFive" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="collapse show" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body">
                  Yaqueline Lizarazo, ha publicado un aticulo en <a href="https://syscom.com.co/" style="color:blue;">Syscom 20 Estaciones de Servicio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br><br><br><br>
<br><br><br><br>
