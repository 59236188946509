<app-navbar></app-navbar>
<br>
<!--<app-carousel Images="Images"></app-carousel>-->
<div id="demo" class="carousel slide" data-ride="carousel">
  <!--//Indicators-->
  <ul class="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" class="active"></li>
    <li data-target="#demo" data-slide-to="1"></li>
    <li data-target="#demo" data-slide-to="2"></li>
    <li data-target="#demo" data-slide-to="3"></li>
    <li data-target="#demo" data-slide-to="4"></li>
    <li data-target="#demo" data-slide-to="5"></li>
  </ul>
  <!--//The slideshow-->
  <div class="carousel-inner">
    <!--<div class="carousel-item active">
    <a (click)="scrollTop()" [routerLink]="['/post10']" target="_blank">
      <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel/temporal.jpg" alt="">
    </a>
  </div>-->
    <div class="carousel-item active">
      <a href="../../../../src/assets/otro/pasoapaso.pdf" target="_blank">
        <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel/carousel-06.jpg" alt="">
      </a>
    </div>
    <div class="carousel-item">
      <a href="https://autofactura.syscom.com.co" target="_blank">
        <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel-02.png" alt="">
      </a>

    </div>
    <div class="carousel-item">
      <a href="https://www.youtube.com/watch?v=Hc__27ggNwQ&list=PLqL6SDnjCgr48ut0iMX76YCNSx-n8ozpV" target="_blank">
        <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel-01.png" alt="">
      </a>

    </div>
    <div class="carousel-item">
      <a (click)="scrollTop()" [routerLink]="['/trabajemos']">
        <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel/carousel-07.png" alt="">
      </a>
    </div>
    <div class="carousel-item">
      <!--<a (click)="scrollTop()" [routerLink]="['/systotalcomercial']">-->
      <a href="https://www.youtube.com/watch?v=h64Qb4sHVC4&list=PLqL6SDnjCgr4zGjU4lmZTZzrvI7ZEkub4&index=2" target="_blank">
        <img class="d-block w-100 center-block" src="../../../../src/assets/img/carousel/carousel-03.png" alt="">
      </a>
</div>
    <div class="carousel-item">
      <a (click)="scrollTop()" [routerLink]="['/contabilidad']">
        <img class=" d-block  w-100 center-block" src="../../../../src/assets/img/carousel-04.png" alt="">
      </a>
    </div>
  </div>
  <br><br>
  <!--//Left and right controls-->
  <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>
<div class="container">
  <!-- ccp cod centrar-->
  <!--<br><br>
    <div class="row justify-content-center  ">

      <div id="botonimagen">-->
  <!--<div class="row justify-content-center ">
     <div class="col-xl-4 ">
       <br><br> <br><br>
     </div>

     <div class="col-xl-2">
       <br><br>
     </div>
     <div class="col-xl-4 ">
       <br>
       <button type="button" class="btn btn-light  right">
         aquí
       </button>
     </div>
   </div>
    </div>
  </div>-->
  <!--<a [routerLink]="['/login']" title="Ir a CCP Syscom " target="_blank">
    
    ir a helpdesk
  </a>-->

  <br><br>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-sm-4 align-self-center text-center">
        <a href="http://soporte.syscom.com.co/" title="Ir a CCP Syscom " target="_blank">
          <!--[routerLink]="['/login']"-->
          <img src="../../../../src/assets/img/compania3-1.png" class="img-fluid" width="400" height="123" loading="lazy">
        </a>
      </div>

      <div class="col-sm-4 align-self-center text-center">
        <a href="http://facturacion.syscom.com.co:4640/" title="Ir a Facturación Electrónica Syscom" target="_blank">
          <img src="../../../../src/assets/img/compania2-1.png" class="img-fluid" width="400" height="123" loading="lazy">
        </a>
      </div>
    </div>
  </div>
  <!-- contenedor y titulo -->
  <br><br>
  <div class="p-3 mb-2  ">
    <img src="../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
    <p class="Transportes">Nuestras Soluciones</p><br>
  </div>
  <!-- tabs  -->
  <!-- Nav tabs -->
  <ul class="nav nav-pills" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><p class="roboto18">Transportes</p></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><p class="roboto18">Comercial y contable </p></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false"><p class="roboto18">Estaciones de servicio</p></a>
    </li>
  </ul>
  <!-- Tab panes -->
  <div class="tab-content">
    <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
      <br><br>
      <div class="row justify-content-center">
        <!--tab Transpors-->
        <div class="container">
          <!-- fila 1  -->
          <div class="row">
            <div class="col-md-6">
              <p class="roboto font-weight-bold">El parque automotor en la palma de su mano</p>
              <p class="roboto18">Controla y administra todo el proceso operativo de transporte, de la mano del ministerio de transporte</p>

              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/transespecial']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/translogo.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/translogo-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/translogo.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/mantvehicular']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/mantenimiento.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/mantenimiento-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/mantenimiento.png' " />
                  </a>
                </div>
              </div>
              <!-- fila 2  -->
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/transcarga']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/carga.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/carga-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/carga.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/parqueadero']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/parking.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/parking-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/parking.png' " />
                  </a>
                </div>
              </div>
              <!-- fila 3  -->
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/busurbano']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/buses.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/buses-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/buses.png' " />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6"><img src="../../../../src/assets/img/Home/soluciones1.png" class="img-fluid"></div>
          </div>
        </div>
        <!--end-->
      </div>
    </div>

    <!-- tab estaciones de servicio   -->
    <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="messages-tab">
      <br><br>
      <div class="row justify-content-center">
        <br><br><br>
        <div class="container">
          <!-- fila 1  -->
          <div class="row">
            <div class="col-md-6">
              <p class="roboto font-weight-bold">Administra tu empresa con mayor eficiencia</p>
              <p class="roboto18">Con poco tiempo y esfuerzo puedes enviar facturas, cotización y estados de cuentas a tus clientes</p>
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/systotalcomercial']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/syscomtotal.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/syscomtotal-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/syscomtotal.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/contabilidad']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/contabilidad.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/contabilidad-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/contabilidad.png' " />
                  </a>
                </div>
              </div>
              <!-- fila 2  -->
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/nominarh']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/nomina.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/nomina-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/nomina.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/activosfijos']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/activos.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/activos-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/activos.png' " />
                  </a>
                </div>
                <!-- fila 3  -->
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/facturacioneinventario']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/inventario.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/inventario-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/inventario.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/facturacionelect']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/factuelectronica.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/factuelectronica-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/factuelectronica.png' " />
                  </a>
                </div>
              </div>
            </div>
            <!-- fila imagen  -->
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <br><br><br><br>
                </div>
                <div class="col-md-12">
                  <img src="../../../../src/assets/img/Home/soluciones3.png" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end-->
      </div>
    </div>
    <!-- tab contable   -->
    <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="profile-tab">
      <br><br>
      <div class="row justify-content-center">
        <div class="container">
          <!-- fila 1  -->
          <div class="row">
            <div class="col-md-6">
              <p class="roboto font-weight-bold">Herramienta ágil para estaciones ágiles</p>
              <p class="roboto18 text-justify">Una solución diseñada para simplificar la ventana-conmpraventa de combustibles,con una contabilidad automatizada y la gestión de servitecas y negocios comerciales (MINIMARKET)dentro de la estación</p>
              <div class="row">
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/estaciones']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/estaciones.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/estaciones-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/estaciones.png' " />
                  </a>
                </div>
                <div class="col-md-6">
                  <a (click)="scrollTop()" [routerLink]="['/plantas']">
                    <img src=".../../../../src/assets/img/Home/productosinicio/plantas.png"
                         width="250" height="68"
                         onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/plantas-over.png' "
                         onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/plantas.png' " />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src="../../../../src/assets/img/Home/soluciones2.png" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br> 


    <!-- nomina electronica -->
    <div class="p-3 mb-2  nav">
      <div class="col-xl-12 text-center">
        <a (click)="scrollTop()" [routerLink]="['/productos']">
          <img src="../../../../src/assets/img/Home/btnfactelectronica.png" class="img-fluid" alt="...">
        </a>
      </div>
      <div class="col-xl-6 text-center">
        <a href="https://autofactura.syscom.com.co/" title="Ir a Autofactura Syscom" target="_blank">
          <img src="../../../../src/assets/img/Home/btn_autofactura.png" class="img-fluid" alt="...">
        </a>
      </div>
      <div class="col-xl-6 text-center ">
        <a (click)="scrollTop()" [routerLink]="['/productos']">
          <img src="../../../../src/assets/img/Home/btnnominaelectronica.png" class="img-fluid" alt="...">
        </a>
      </div>
    </div>
    <!-- contenedor y titulo -->
    <div class="p-3 mb-2  ">
      <img src="../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
      <br>
      <p class="Transportes">Algunas palabras<br> de nuestros clientes felices</p>
    </div>
    <br>
    <!-- comentarios clientes -->
    <div class="p-3 mb-2  nav">
      <div class="col-xl-6 ">
        <img src="../../../../src/assets/img/client1.png" class="img-fluid" alt="...">
      </div>
      <div class="col-xl-6 ">
        <img src="../../../../src/assets/img/client2.png" class="img-fluid" alt="...">
      </div>
    </div>
  </div>
  <br><br><br>
  <br><br>
  <div class="p-3 mb-2  ">
    <img src="../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
  </div>
  <div class="p-3 mb-2  nav">
    <div class="col-xl-5 ">
      <p class="Transportes row">Certificados de calidad</p>
      <img src="../../../../src/assets/img/Home/unidad.png" class="img-fluid" alt="...">
    </div>
    <div class="col-xl-2 ">
      <br><br><br>
    </div>
    <div class="col-xl-5 ">
      <p class="Transportes row">Suscríbete a nuestra web</p>
      <p class="roboto">Puedes recibir en tu correo electrónico las ultimas publicaciones que compartimos en nuestra web.</p>
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Escribe tu email" aria-label="Recipient's username" aria-describedby="button-addon2">
        <div class="input-group-append">
          <button class="btn btn-syscom" type="button" id="button-addon2">Suscribete</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br /> <br /> <br />
