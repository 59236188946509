<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <p class="roboto-center18">CAMIONES Y VANS, LAS MEJORES ALTERNATIVAS EL TRANSPORTE DE CARGA PARA MOVERSE EN EL CAMPO</p>
    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-8 ">
        <img src="../../../../src/assets/img/blog/img3.jpg"
             class="img-fluid" alt="Responsive Image"
             width="880" height="400" />
        <br>  <br>
      </div>
      <br><br>
      <div class="col-xl-8 ">
        <p class="text-justify font-roboto ">
          Según la información presentada de la Asociación Nacional de Movilidad Sostenible, la reactivación de las ciudades ha permitido que segmentos como carga liviana (< 10,5 toneladas) comiencen a recuperarse, en octubre este segmento fue el único con una variación positiva 79,2% equivalente a 1.856 unidades vendidas. En el acumulado, tanto carga liviana como carga pesada (>10,5T) alcanzaron variaciones positivas. Los camiones son un elemento absolutamente esencial para lograr tener la población con la disponibilidad de comida y bebida necesaria, aprovechando las empresas comenzaron a reactivar sus planes de renovación de flota y en estos momentos hay una normalidad en la demanda. También los transportadores tienen sus ventajas como es el cobro de todo lo relacionado a la operación de llantas por kilómetro rodado en donde el cliente se desentiende de la compra unitaria de llantas, reencauches, servicios de montaje, alineación, balanceo, y despinche.
          <br><br>
          Las vans también son una buena opción ya que tienen la mezcla perfecta entre volumen de carga y potencia son eficientes con el combustible y ecológico, con reducidas emisiones de CO2, bajo ruido y vibración. Muchas marcas ofrecen vehículos 100% eléctricos como una van de capacidad de una tonelada con cinco metros cúbicos. También muchas de ellas cuentan con un camión mini truck de 2,3 toneladas de capacidad que se puede configurar de acuerdo a las necesidades que tenga cada cliente y en versión pick up con un furgón que puede llegar a 16 metros cúbicos.
          <br><br>
          Las diferentes marcas piensan cada vez más en la comodidad y bienestar de los conductores. La marca japonesa Hino lanzó este año al mercado el nuevo camión GH8J de peso bruto vehicular de 17 toneladas, producto completamente renovado y de gran aplicación para transporte de frutas, verduras y alimentos refrigerados y congelados. Este modelo tiene como características principales una cabina totalmente nueva pensando en la comodidad del conductor, chasis más resistente y de fácil adaptación a las diferentes carrocerías, y caja de nueve velocidades.
        </p>
      </div>
    </div>
    <br> <br>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-8">
          <table>
            <tr>
              <td width="12px"></td>
              <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
              <td width="8px"></td>
              <td>
                <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/facemouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                </a>
                <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/twitermouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/inmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                </a>
                <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</div>

<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
