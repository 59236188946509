import { Injectable, Inject, NgModule } from '@angular/core';
import { ClsEmail } from '../interfaces';
import { Observable, throwError } from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: "root"
})

export class ContactoService {
  private BaseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") agbaseUrl: string) {
    this.BaseUrl = agbaseUrl;
  }

  public SendEmail(dataemail: ClsEmail, tipoco: number): Observable<string> {
    dataemail.tipocorreo = tipoco;
    //alert(dataemail.tipocorreo);
    //Promise
    // Promise((resolve, reject) => {
    //  this.http.post<string>(this.BaseUrl + "/Contacto/SendEmail", dataemail, httpOptionsPlain).toPromise().then(res => {
    //    console.log(JSON.stringify(res));
    //    this.promise = JSON.stringify(res);
    //  });
    //});
    //this.http.post<string>(this.BaseUrl + "/Contacto/SendEmail", dataemail, httpOptionsPlain).toPromise().then((data: any) => {
    //  this.mensaje = data;
    //  console.log(data);
    //});

    return this.http.post<string>(this.BaseUrl + "/Contactogeneral/SendEmail", dataemail, httpOptionsPlain).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        return throwError('Respuesta not found!');
      })
    );
  }
  //errorHandler(error: HttpErrorResponse) {
  //  return Observable.throw(error.message || "Error Service");
  //}

  public SendGetWOutParam(ruta: string): any {
    return this.http.get(this.BaseUrl + ruta).toPromise();
  }

  public SendGetWOutParamObs(ruta: string): Observable<any> {
    return this.http.get<Observable<any>>(this.BaseUrl + ruta).pipe(map((data: any) => {
      return data;
    }));
  }
}


