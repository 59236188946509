<app-navhelpdesk></app-navhelpdesk>
<div class="nav" style="background-color: #F4F4F4;">
  <div class="container" style="background-color: #FFF;">
    <div class="container">
      <div class="col-sm-12 align-self-center text-center">
        <p id="roboto-help12">
          <img src="../../../../src/assets/img/helpdesk/Nav/tickets.png" width="45" height="48" class="img-fluid">
          Nuevo Ticket</p>
      </div>
    </div>
    <!-- formulario -->
      <div class="row justify-content-center ">
        <div class="col-sm-8 align-self-center text-center">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <p id="roboto-help13">Empresa </p>
                <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">
                  <div class="form-group">
                    <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Empresa" ngModel required #nombres="ngModel">
                    <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Empresa' es obligatorio</small>
                  </div>
                  <p id="roboto-help13">Sucursal </p>
                  <div class="form-group">
                    <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Sucursal" ngModel required #nombres="ngModel">
                    <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Sucursal' es obligatorio</small>
                  </div>
                  <p id="roboto-help13">Contacto</p>
                  <div class="form-group">
                    <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Contacto" ngModel required #nombres="ngModel">
                    <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Contacto' es obligatorio</small>
                  </div>
                  <p id="roboto-help13">Tipo de solicitud </p>
                  <div class="form-group">
                    <select class="form-help ">
                      <option>Asesoria</option>
                      <option>Auditoria</option>
                      <option>capacitación</option>
                      <option>Cartera</option>
                      <option>Compra</option>
                      <option>Comunicaciones</option>
                      <option>Contabilidad</option>
                      <option>Correo Electrónico</option>
                    </select>
                  </div>
                  <p id="roboto-help13">Clase </p>
                  <div class="form-group">
                    <select class="form-help ">
                      <option>Actividad</option>
                      <option>Base de Datos</option>
                      <option>Capacitaciones</option>
                      <option>Carta</option>
                      <option>Contrato</option>
                      <option>Correctivo</option>
                      <option>devolución</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <p id="roboto-help13">Descripción </p>
                    <textarea class="form-descrip" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
                </form>
                <div class="container">
                  <div class="row justify-content-center h-100">
                    <div class="col-sm-3 align-self-center text-center">
                      <button routerLink="/helpdesk" class="btn btn-light" type="submit" title="Cancelar el Ticket">cancelar</button>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <button routerLink="/verticket" class="btn btn-helpdesk" type="submit" title="Crear el Ticket">Crear</button>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
                </div>
              </div>
              </div>
        </div>
      </div>
      <br><br>  <br><br>  <br><br>
  </div>
</div>

