<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/nominarh']">
          <img src="../../../../src/assets/img/productos/logosmenu/nominarhm.png" width="230" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/nominarh#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src="/src/assets/img/productos/recursoshum/img1.png" class="img-fluid" alt="Responsive Image" width="886 " height="480">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="/src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        La aplicación Nómina y Recurso Humano
        optimiza el manejo del personal de las
        empresas, por medio de la elaboración de la
        nómina y las causaciones de la autoliquidación
        de aportes a fondos de pensiones, salud,
        administradoras de riesgos profesionales y
        prestaciones sociales.<br><br>

        al igual que todos los parafiscales, con la
        generación de su formato único de
        autoliquidación de aportes para que estos sean
        pagos ante cualquier operador de información.
        Administre todos y cada uno de sus
        trabajadores teniendo en cuenta cualquier
        novedad concerniente a vacaciones, sanciones
        o incapacidades.
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/recursoshum/img2.png" class="img-fluid" alt="Responsive Image" width="388" height="320">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!-- seccion dark -->
<div id="content-black">
  <br>
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark"> Maneje todos sus datos, dotaciones y controle los prestamos realizados a los empleados de una manera rápida, sencilla y eficiente, asegurando que toda esta información está disponible para ser consultada o exportada.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br>
<br>
<!--texto e imagen 2-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <img src="/src/assets/img/productos/recursoshum/img3.png" class="img-fluid" alt="Responsive Image" width="400 " height="375">
    </div>
    <div class="col-xl-5">
      <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
      <p class="text-justify roboto">
        El sistema se maneja en una interfaz amigable al usuario, lo que hace que el ingreso de la información sea de una manera sencilla, rápida y entendible para agilizar los procesos correspondientes al ingreso del personal, empezando por la generación de la hoja de vida y continuando con la creación del respectivo contrato laboral.<br><br>
        Generación de archivo plano para pago de aportes para fiscales, según norma vigente del Ministerio de Protección Social.<br><br>
        Pago por transferencia bancaria por medio de archivo plano, através de las principales entidades bancarias para el pago de Nómina, Liquidación de Prima, Vacaciones, Cesantías e intereses de Cesantías y Liquidaciones definitivas.
      </p>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<!--viñetas dos columnas-->
<div class="container ">
  <div class=" container col-sm-5">
    <p id="roboto-center18a">Nuestra aplicación de Nómina y Recurso Humano es tu complemento perfecto, ya que en el encontrarás:</p>
  </div>
  <br>
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <ul>
        <li><p class="text-justify roboto">100% ambiente bajo Windows.</p></li>
        <li><p class="text-justify roboto">Hojas de vida.</p></li>
        <li><p class="text-justify roboto">Contratos laborales.</p></li>
        <li><p class="text-justify roboto">Diferentes tipo de nómina y conceptos diversos de nómina.</p></li>
        <li><p class="text-justify roboto">Conceptos fijos y automáticos.</p></li>
        <li><p class="text-justify roboto">Cierre de nómina e integración.</p></li>
        <li><p class="text-justify roboto">Control de asistencia.</p></li>
      </ul>
    </div>
    <div class="col-xl-2">
      <br><br>
    </div>
    <div class="col-xl-4 ">
      <ul>
        <li><p class="text-justify roboto">Módulo de préstamos.</p></li>
        <li><p class="text-justify roboto">Dotaciones.</p></li>
        <li><p class="text-justify roboto">Pagos por transferencia bancaria.</p></li>
        <li><p class="text-justify roboto">Plantilla de autoliquidación de aportes en línea.</p></li>
        <li><p class="text-justify roboto">Cierre de nómina e integración en línea de contabilidad.</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!-- seccion dark 2 -->
<div id="content-black">
  <br>
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark"> Aprovecha la experiencia de SYSCOM SA, una compañía de tecnología reconocida con excelente respaldo y solidez,para optimizar los procesos de tu negocio.</p>
    <br>
  </div>
</div>
<br>
<br>
<br>
<!--imagen y texto-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="font-weight-bold" id="roboto-center18">Estamos Certificados por la UGPP</p>
      <br>
      <img src="/src/assets/img/home/unidad.png" class="img-fluid" alt="Responsive Image" width="170" height="67">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">¿Tiene preguntas sobre cómo la aplicación <font class="font-weight-bold"> Nómina y Recurso Humano</font> puede ayudar a su negocio?</p>
      <br>
      <p class="text-center roboto">
        Envíenos un correo electrónico y nospondremos en contacto en breve.
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br>
      <img src="/src/assets/img/productos/recursoshum/img5.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="257" />
    </div>
  </div>
</div>
<br>
<br>
<br>
