<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F4F4F4;">
  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-10" style="background-color: #FFF;">
        <div class="row  h-100 ">
          <div class="col-sm-2  ">
            <img src="../../../../src/assets/img/helpdesk/empresa/empresa.png" width="120" height="120" class="img-fluid">
          </div>
          <div class="col-sm-9 align-bottom text-center">
            <p id="roboto-help31">
              Transporte de Carga JVC S.A.S
            </p>
            <br>
            <p id="roboto-help29">
              Soporte: <img src="../../../../src/assets/img/helpdesk/empresa/activo-on.png" width="70" height="17" class="img-fluid">
            </p>
          </div>
          <div class="col-sm-5 ">
          </div>
        </div>
      </div>
      <div class="col-lg-1">
      </div>
      <div class="col-lg-3">
      </div>
    </div>
  </div>
  <!--segunda-->

  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-10">
        <div class="col-sm-12 align-self-center text-center">
          <p id="roboto-help30">
            Asignar servicios Syscom
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--Tercera-->

  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-10" style="background-color: #FFF;">
        <form>
          <div class="form-group">
            <label id="roboto-help29" for="exampleFormControlSelect1">Servicio Syscom</label>
            <select  class="form-control"d="exampleFormControlSelect1">
              <option>Activos Fijos</option>
              <option>Administración de Plantas</option>
              <option>Buses Urbanos</option>
              <option>Autofactura</option>
              <option>Contabilidad</option>
              <option>Facturación Electrónica</option>
              <option>Gestion Estaciones de servicio y de combustible</option>
            </select>
          </div>
          <br><br>
          <div class="form-group">
            <label id="roboto-help29" for="exampleFormControlSelect1">Versión</label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>4.0.2</option>
              <option>5.0.2</option>
              <option>6.0.2</option>
              <option>7.0.2</option>
              <option>8.0.2</option>
            </select>
          </div>
        </form>
        <br><br>
        <div class="container">
          <div class="row justify-content-center h-100">
            <div class="col-sm-3 align-self-center text-center">
              <button routerLink="/helpdesk" class="btn btn-light" type="submit" title="Cancelar el Ticket">cancelar</button>
            </div>
            <div class="col-sm-3 align-self-center text-center">
              <button routerLink="/verticket" class="btn btn-helpdesk" type="submit" title="Crear el Ticket">Crear</button>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
        </div>
        <br><br><br><br><br><br> 
      </div>
    </div>
  </div>
</div>

