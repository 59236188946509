import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-busurbano',
  templateUrl: './busurbano.component.html',
  styleUrls: ['./busurbano.component.scss']
})
export class BusurbanoComponent implements OnInit {
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  constructor() { }

  ngOnInit(): void {
  }

}
