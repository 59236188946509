<app-navbar></app-navbar>

<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light  zindex-syscom ">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/systotalcomercial']">
          <img src="../../../../src/assets/img/productos/logosmenu/systotalcomercialm.png" width="230" height="49" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/systotalcomercial#caracteristicas" title="Ir a funcionalidad"> Características</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/systotalcomercial#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="rojo">
  <div id="verde"></div>
</div>
<div class="gris"></div>
<div class="amarillo"></div>
<div class="azul"></div>

<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src="/src/assets/img/productos/syscomercial/img1.png" class="img-fluid" alt="Responsive Image" width="732" height="432">
    </div>
  </div>
</div>
<br><br><br><br><br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="/src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <img src="/src/assets/img/productos/syscomercial/img2.png" class="img-fluid" alt="Responsive Image" width="450" height="308">
    </div>
    <div class="col-xl-4 ">
      <p class="text-justify roboto">
        El Sistema Comercial Total Syscom se encuentra sujeto a la normatividad generada por la DIAN, agrupa toda la parte de manejo, administración de inventarios y facturación, con contabilidad y cartera, de manera transparente, sin reproceso y con una alta confiabilidad en el manejo de la información.
      </p>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!-- seccion dark  -->
<div id="content-black">
  <div class=" container col-sm-5">
    <br />
    <p id="roboto-blue"> Un software contable con las funcionalidades más ágiles para llevar la contabilidad.</p>
    <br />
  </div>
</div>
<div id="caracteristicas"></div>
<br><br><br><br><br><br>
<!-- separador azul -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="/src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas de texto-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <p class="text-justify roboto">Se encuentra sujeto a la normatividad generada por la DIAN, agrupa toda la parte de manejo, administración de inventarios y facturación, con contabilidad y cartera, de manera transparente, sin reproceso y con una alta confiabilidad en el manejo de la información.<br>Controla los inventarios y todas las operaciones que lo involucran; como compras, ventas, listas de precios, promociones, manejo de proveedores, etc.<br>Realice de forma sencilla cuadres de</p>
    </div>
    <div class="col-xl-4 ">
      <p class="text-justify roboto">caja y tenga acceso a múltiples compañías gracias a nuestro sistema multi-usuario.<br><br>Controle los inventarios y todas las operaciones que involucran su manejo, como las compras, ventas, listas de precios, promociones, manejo de proveedores, etc. Realice de forma sencilla cuadres de caja y tenga acceso a múltiples compañías e incluye un prevalidador de información exógena para la DIAN, que genera todos los archivos listos para subir al MUISCA (DIAN).</p>
    </div>
  </div>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="/src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5 ">
      <ul>
        <li><p class=" roboto">Compra de productos y / servicios.</p></li>
        <li><p class=" roboto">Control de mínimos y máximos.</p></li>
        <li><p class=" roboto">Amplia información en hojas de vida.</p></li>
        <li><p class=" roboto">Inventario físico y ajustes.</p></li>
        <li><p class=" roboto">Cartera devales crédito y prepago.</p></li>
        <li><p class=" roboto">Pagos por transferencia bancaria.</p></li>
        <li><p class=" roboto">Aprobaciones y Cuentas por pagar.</p></li>
        <li><p class=" roboto">Presupuesto contable.</p></li>
        <li><p class=" roboto">Gestion de nómina</p></li>
      </ul>
    </div>

    <div class="col-xl-5 ">
      <ul>
        <li><p class=" roboto">Control de formas de pago y conciliación bancaria.</p></li>
        <li><p class="roboto">Contabilidad en Línea (2649, Niif, Fiscal).</p></li>
        <li><p class=" roboto">Alarmas y notificaciones por correoActivos fijos y diferidos</p></li>
        <li><p class=" roboto">Prevalidador de información exógenapara la DIAN.</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!-- seccion dark 2  -->
<div id="content-black">
  <div class=" container col-sm-8">
    <br />
    <p id="roboto-blue">
      Permite mantener actualizada toda la información y obtener informes instantáneos de cualquier área de la empresa, sus gastos y cualquier dato que desee extraer de la empresa o de los diferentes centros de costos.
    </p>
    <br />
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">Te ayudamos a <font class="font-weight-bold">impulsar tu negocio,</font> con herramientas <font class="font-weight-bold">fáciles de usar</font> y el mejor servicio a clientes.</p>
      <br>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br><br><br>
      <img src="/src/assets/img/productos/recursoshum/img5.png"
           class="img-fluid" alt="Responsive Image"
           width="700" height="360" />
    </div>
  </div>
</div>
<br>
<br>
<br>
