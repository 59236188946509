import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transcarga',
  templateUrl: './transcarga.component.html',
  styleUrls: ['./transcarga.component.scss']
})
export class TranscargaComponent implements OnInit {
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  constructor() { }

  ngOnInit(): void {
  }

}
