<nav aria-label="...">
  <ul class="pagination">
    <li class="page-item disabled">
      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Anterior</a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item active" aria-current="page">
      <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
<!-- carrousel -->
<br><br><br><br>
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="/src/assets/img/tutorial/img2.png">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="/src/assets/img/tutorial/img3.png">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="/src/assets/img/tutorial/img4.png">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="/src/assets/img/tutorial/img5.png">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="/src/assets/img/tutorial/img6.png">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<br>
<br>
