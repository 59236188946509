<app-navbar></app-navbar>
<br>
<!--separador rojo-->
<div class="container ">
  <img src="../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive " alt="... ">
</div>
<br><br>
<!-- Comercial y Contable -->
<div class="container ">
  <p class="Transportes">Comercial y Contable</p>
  <p class=" roboto">Con poco tiempo y esfuerzo puedes enviar facturas, cotizaciones y estados de cuentas a tus clientes</p>
  <div class=" container">
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/systotalcomercial']">
          <img src=".../../../../src/assets/img/Home/productosinicio/syscomtotal.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/syscomtotal-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/syscomtotal.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/contabilidad']">
          <img src=".../../../../src/assets/img/Home/productosinicio/contabilidad.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/contabilidad-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/contabilidad.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/activosfijos']">
          <img src=".../../../../src/assets/img/Home/productosinicio/activos.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/activos-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/activos.png' " />
        </a>
      </div>
    </div>
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/nominarh']">
          <img src=".../../../../src/assets/img/Home/productosinicio/nomina.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/nomina-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/nomina.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/facturacionelect']">
          <img src=".../../../../src/assets/img/Home/productosinicio/factuelectronica.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/factuelectronica-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/factuelectronica.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/facturacioneinventario']">
          <img src=".../../../../src/assets/img/Home/productosinicio/inventario.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/inventario-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/inventario.png' " />
        </a>
      </div>
    </div>
  </div>
</div>
<br><br><br><br><br>
<!-- nomina electronica -->
<div class="container ">
  <div class="p-3 mb-2  nav">
    <div class="col-xl-12 text-center">
      <a (click)="scrollTop()" [routerLink]="['/productos']">
        <img src="../../../../src/assets/img/Home/btnfactelectronica.png" class="img-fluid" alt="...">
      </a>
    </div>
    <div class="col-xl-6 text-center">
      <a href="https://autofactura.syscom.com.co/" title="Ir a Autofactura Syscom" target="_blank">
      <img src="../../../../src/assets/img/Home/btn_autofactura.png" class="img-fluid" alt="...">
      </a>
    </div>
    <div class="col-xl-6 text-center ">
      <a (click)="scrollTop()" [routerLink]="['/productos']">
        <img src="../../../../src/assets/img/Home/btnnominaelectronica.png" class="img-fluid" alt="...">
      </a>
    </div>
  </div>
                </div>
<!-- separador -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!-- Transportes -->
<div class="container ">
  <p class="Transportes">Transportes</p>
  <p class="roboto">Controla y administra todo el proceso operativo de transporte, de la mano del ministerio de transportes.</p>
  <div class=" container">
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/transespecial']">
          <img src=".../../../../src/assets/img/Home/productosinicio/translogo.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/translogo-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/translogo.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/busurbano']">
          <img src=".../../../../src/assets/img/Home/productosinicio/buses.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/buses-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/buses.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/mantvehicular']">
          <img src=".../../../../src/assets/img/Home/productosinicio/mantenimiento.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/mantenimiento-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/mantenimiento.png' " />
        </a>
      </div>
    </div>
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/transcarga']">
          <img src=".../../../../src/assets/img/Home/productosinicio/carga.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/carga-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/carga.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/parqueadero']">
          <img src=".../../../../src/assets/img/Home/productosinicio/parking.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/parking-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/parking.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <table><tr></tr></table>
      </div>
    </div>
  </div>
</div>
<br><br><br><br><br>

<!-- separador negro -->
<div class="p-3 mb-2  ">
  <div class="container ">
    <img src="../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
  </div>
</div>
<br>
<!-- Estaciones de servicio -->
<div class="container ">
  <p class="Transportes">Estaciones de servicio</p>
  <p class=" roboto">Una solución diseñada para simplificar la venta - compraventa de combustibles, con una contabilidad automatizada y la gestión de servitecas y negocios comerciales (MINIMARKET) dentro de la estación.</p>
  <div class=" container">
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/estaciones']">
          <img src=".../../../../src/assets/img/Home/productosinicio/estaciones.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/estaciones-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/estaciones.png' " />
        </a>
      </div>
      <div class="col-xl-4 ">
        <a (click)="scrollTop()" [routerLink]="['/plantas']">
          <img src=".../../../../src/assets/img/Home/productosinicio/plantas.png"
               width="300" height="81"
               onMouseOver="this.src='.../../../../src/assets/img/Home/productosinicio/plantas-over.png' "
               onMouseOut="this.src='.../../../../src/assets/img/Home/productosinicio/plantas.png' " />
        </a>
      </div>
      <div class="col-xl-4 "></div>
    </div>
  </div>
</div>
<br><br>
<!-- seccion -->
<div id="content-black">
  <br>
  <div class=" container col-sm-6">
    <p id="roboto-blue-dark"> Más de 10.000 usuarios a nivel nacional <br /><font class="roboto-center">utilizan nuestras soluciones como herramienta de apoyo y mejoramiento en sus procesos.</font></p>
    <br>
  </div>
</div>
<br /><br />
<!-- informacion -->

<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-7 align-self-center text-center">
      <img src="../../../../src/assets/img/productos/logosys.png " width="70% " height="70% " class="responsive " alt="... ">
      <br /><br />
      <p class=" roboto18-center">Es la mejor opción a las necesidades de su empresa por:</p>
    </div>
  </div>
</div>
<br /> <br />

<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4">
      <img src="../../../../src/assets/img/productos/productos-2.png"
           class="img-fluid" alt="Responsive Image"
           width="300" height="277" />
    </div>
    <div class="col-xl-4">
      <ul>
        <p class="roboto18 ">
        <li>Licencia vitalicia</li>
        <li>"Compromiso de formación" Metodología de implantación y estandarización de los procesos y seguimiento.</li>
        <li>Personal calificado y experiencia en la implementación de soluciones.</li>
        <li>Amplia gama de productos.</li>
        <li>Fácil de utilizar Buenas posibilidades de personalización</li>
        <li>Solidez del proveedor. Solicitud de servicios en línea.</li>
        </p>
      </ul>
    </div>
  </div>
</div>


<br><br><br>

