import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facturacionelect',
  templateUrl: './facturacionelect.component.html',
  styleUrls: ['./facturacionelect.component.scss']
})
export class FacturacionelectComponent implements OnInit {
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  constructor() { }

  ngOnInit(): void {
  }

}
