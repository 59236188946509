<app-navbar></app-navbar>

<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" routerLink="/facturacioneinventario">
          <img src="../../../../src/assets/img/productos/logosmenu/facturacioneinventariom.png" width="210" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/facturacioneinventario#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/gestionmanejofi/img1.png " width="886 " height="480" class="img-fluid" alt="... ">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4">
      <img src=".../../../../src/assets/img/productos/gestionmanejofi/img2.png"
           width="454" height="413" class="img-fluid" alt="... ">
    </div>
    <div class="col-xl-4 ">
      <p class="text-justify roboto">
        La aplicación <font class="font-weight-bold">
          Gestión de Manejo
          Integrado de Facturación e Inventarios,
        </font>
        brinda control en las operaciones
        comerciales de la organización,
        permitiendo al usuario la compra y venta
        de artículos y servicios dentro del
        aplicativo, generando alertas,
        estadísticas e informes de control.
        <br><br>
        Controla de forma asertiva y eficaz todas
        las formas de pago aplicables en un
        punto de venta (efectivos, tarjetas débito,
        crédito, Sodexo, big pass, cheques, y las
        que deseen vincular en la operación),
        vincula perfiles de cajero y de usuario.
      </p>
    </div>
  </div>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br><br>
<!-- separador azul -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li><p class=" text-justify roboto">PUNTO DE VENTA SYSCOM permite el manejo integrado de facturación de contado (tipo POS).</p></li>
        <li><p class=" text-justify roboto">Control de comisiones por vendedor.</p></li>
        <li><p class="text-justify roboto">Trazabilidad permanente de costos: Costo promedio, último costo, costo anterior por producto de forma segura y automática.</p></li>
        <li><p class=" text-justify  roboto">Actualizaciones programadas de precios.</p></li>
      </ul>
    </div>
    <div class="col-xl-5">
      <ul>
        <li><p class="text-justify roboto">Alerta de mínimos y máximos deinventario (niveles de stock).</p></li>
        <li><p class="text-justify roboto">Permite el control de caja menor. Cálculo de impuestos automáticos(IVA, ICA, Impuesto al consumo, CREE, retenciones en la fuente etc.).</p></li>
        <li><p class="text-justify roboto">Genera informes estadísticos gerenciales.</p></li>
        <li><p class="text-justify roboto">Manejo histórico y actual de Kardex.</p></li>
      </ul>
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="font-weight-bold" id="roboto-center18">¿Tienes preguntas?</p>
      <br>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br>
      <img src=".../../../../src/assets/img/productos/gestionmanejofi/img3.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="257" />
    </div>
  </div>
</div>
<br>
<br>
<br>
