

<div class="nav" style="background-color: #F4F4F4;">

  <div class="container">
    <br><br><br>
    <div class="col-sm-12 align-self-center text-center">

      <img src="../../../../src/assets/img/helpdesk/Home/logosyscom.png"
           class="img-fluid" alt="Responsive Image"
           width="400" height="109" />
      <p id="roboto-help1">
        Bienvenido
      </p>
<p id="roboto-help2">
        Por favor ingrese su usuario y contraseña </p>
    </div>
  </div>
  <!-- formulario -->
  <div class="container h-100">
    <div class="row justify-content-center h-100">
      <div class="col-sm-8 align-self-center text-center">
        <div class="tab-content">
          <div class="row justify-content-center">
            <div class="col-lg-6">

              <p class="roboto18 font-weight-bold"></p>
              <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">
                <div class="form-group">
                  <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder="Usuario" title="Nombres y apellidos" ngModel required #nombres="ngModel">
                  <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Usuario' es obligatorio</small>
                </div>
                <div class="form-group">
                  <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder="Contraseña" title="Nombres y apellidos" ngModel required #nombres="ngModel">
                  <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Contraseña' es obligatorio</small>
                </div>
              </form>
              <button routerLink="/helpdesk" class="btn btn-helpdesk" type="submit" title="Realizar el envío">Ingresar</button>
              <div class="mt-3">
                <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <div class="col-sm-12 align-self-center text-center">
      <img src="../../../../src/assets/img/helpdesk/Home/logohelp.png"
           class="img-fluid" alt="Responsive Image"
           width="140" height="78" />
    </div>
    <br><br>  <br><br>  <br><br>
  </div>
</div>
