<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/transcarga']">
          <img src="../../../../src/assets/img/productos/logosmenu/transcargam.png" width="200" height="43" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/transcarga#caracteristicas" title="Ir a caracteristicas"> Características</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/transcarga#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/transcarga/img1.png" class="img-fluid" alt="Responsive Image" width="818 " height="418">
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 2-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        El software <font class="font-weight-bold">Transporte de Carga Syscom </font> permite a las empresas
        transportadoras controlar su proceso operativo y contable de transportes; desde la
        orden de cargue hasta el pago y cobro de los servicios que se presten.<br />
        Igualmente permite el manejo controlado de anticipos, remesas, manifiestos de carga, cumplimientos
        y liquidaciones, alerta con anterioridad el vencimiento de documentos tanto del Conductor
        como del Vehículo.
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/transcarga/img2.png" class="img-fluid" alt="Responsive Image" width="352" height="319">
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br> <br> <br> <br> <br>
<!--separador azul-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/transcarga/img3.png" class="img-fluid" alt="Responsive Image" width="380" height="392">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Permite el uso de parque automotor con vehículos propios o de terceros y
        la distribución de cualquier tipo de carga a nivel nacional.
        <br /><br />
        Ofrece la conectividad en línea con los servidores del Ministerio, autenticando en tiempo real todos
        sus registros, evitando reprocesos de envío y transmisión de datos a la entidad.
      </p>
    </div>
  </div>
</div>
<br /><br /><br /><br />
<!--texto e imagen 2-->
<div class="container ">
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Permite también el manejo controlado de facturación electrónica, anticipos,
        remesas, manifiestos de carga, cumplimientos y liquidaciones, alerta con
        anterioridad el vencimiento de documentos tanto del Conductor como del Vehículo.
        <br /><br />
        Diferencia el uso de vehículos propios o de terceros y la distribución de
        cualquier tipo de carga a nivel nacional. Comunica todo en línea a contabilidad
        y presenta en tiempo real toda la información a las entidades de control
        (Ministerio de Transporte, UIAF, DIAN).
      </p>
    </div>

    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/transcarga/img4.png" class="img-fluid" alt="Responsive Image" width="388" height="320">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark"> El software Gestión Transporte de Carga Syscom entrega codificación de colores, marcas, tipos de vehículos, líneas de carga, y demás parámetros exigidos y establecidos por el Ministerio de Transporte.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li>
          <p class="roboto text-justify">
            Se ajusta a la reglamentación emitida
            por el Ministerio de Transporte que
            regula el servicio de transporte
            terrestre automotor de carga y
            establece el uso del formato único de
            manifiesto de carga y demás
            normatividades ajustadas a la
            resolución actual vigente.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Tiene conexión en línea (tiempo real)
            con los servidores de Mintransporte,
            evitando al usuario reprocesos para
            envío de información a la entidad.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Nuestro sistema se encarga de validar
            y reportar todo lo requerido de forma
            automática.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Maneja la información relacionada con
            los departamentos, ciudades, marcas,
            líneas de vehículos, modelos, colores,
            productos a transportar, tipos de
            carrocerías, unidades de medida,
            naturaleza de la carga, unidades de
            empaque y exigidas por
            el ministerio de transporte.
          </p>
        </li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li>
          <p class="roboto text-justify">
            Permite la consulta de reportes en
            puestos de control de todos sus
            vehículos, ofreciendo módulo de
            seguridad e interfaces con empresas de
            seguridad y puestos de control.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Brinda múltiples informes de remesas,
            movimientos de anticipo,
            despachos-seguridad, manifiestos,
            facturación, informes financieros, etc.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Controle vencimiento de documentos
            de los vehículos y de los conductores
            gracias a nuestro módulo de alertas
            automáticas.
          </p>
        </li>
        <li><p class="roboto">Contabilidad automática y transparente.</p></li>
        <li>
          <p class="roboto text-justify">
            Módulos implícitos en la operación de
            transporte que facilitarán su operación
            diaria y aportarán considerablemente
            en la consulta de la situación de la
            compañía y la efectiva toma de
            decisiones sobre la misma.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-5">
    <p id="roboto-blue-dark">
      Dispone de un amplio número de informes e integra y automatiza toda la información de manera transparente a contabilidad.
    </p>
  </div>
  <br>
</div>
<br><br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">
    Nuestro software Transporte de Carga Syscom es tu complemento perfecto, ya que en el encontrarás:
  </p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li>
          <p class="roboto">Gestión Comercial.</p>
        </li>
        <li>
          <p class="roboto">Proceso de Despachos Carga.</p>
        </li>
        <li>
          <p class="roboto">Control de Viajes Vacíos.</p>
        </li>
        <li>
          <p class="roboto text-justify">Anticipos en Dinero y en Combustible.</p>
        </li>
        <li>
          <p class="roboto">Novedades en Ruta.</p>
        </li>
        <li>
          <p class="roboto">Pre cumplidos y Cumplidos.</p>
        </li>
        <li>
          <p class="roboto text-justify">Facturación Masiva e Individual y Facturación Electrónica.</p>
        </li>
        <li>
          <p class="roboto">Transp. Paqueteo - Masivo y Residual.</p>
        </li>
        <li>
          <p class="roboto">Liquidaciones / Ordenes de Pago.</p>
        </li>
        <li>
          <p class="roboto">Control de Vehículos.</p>
        </li>
        <li>
          <p class="roboto text-justify">Contabilidad en Línea (2649). Contabilidad.</p>
        </li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li>
          <p class="roboto">Contabilidad en Línea (Niif).</p>
        </li>
        <li>
          <p class="roboto">Contabilidad en Línea (Fiscal).</p>
        </li>
        <li>
          <p class="roboto">Medios Magnéticos (Exógena DIAN).</p>
        </li>
        <li>
          <p class="roboto">Presupuestos Contables.</p>
        </li>
        <li>
          <p class="roboto">Conciliación Bancaria.</p>
        </li>
        <li>
          <p class="roboto">Gestión de Nómina.</p>
        </li>
        <li>
          <p class="roboto">Cartera (C x C — C x P).</p>
        </li>
        <li>
          <p class="roboto">Requisiciones y Compras</p>
        </li>
        <li>
          <p class="roboto">Mantenimiento de Vehículos.</p>
        <li>
          <p class="roboto">Transp. Masivo Modulo Web.</p>
        </li>
        <li>
          <p class="roboto">Alarmas y Notificaciones por Correo.</p>
        </li>
        <li>
          <p class="roboto">Activos Fijos y Diferidos.</p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">
        ¿Tiene alguna pregunta sobre cómo la aplicación <font class="font-weight-bold">Gestión Transporte de Carga </font>puede ayudar a su negocio?
      </p>
      <p class="text-center roboto">
        Envíenos un correo electrónico y <br>nos pondremos en contacto en breve.
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br> <br>
      <img src=".../../../../src/assets/img/productos/transcarga/img5.png"
           class="img-fluid" alt="Responsive Image"
           width="490" height="404" />
    </div>
  </div>
</div>
<br>
<br>
<br>
