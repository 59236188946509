<app-navhelpdesk></app-navhelpdesk>
<div class="nav" style="background-color: #F4F4F4;">
  <div class="container" style="background-color: #FFF;">
    <div class="container">
      <div class="col-sm-12 align-self-center text-center">
        <p id="roboto-help12">
          Nueva Empresa
        </p>
      </div>
    </div>
    <!-- formulario -->


    <div class=" divscroll row justify-content-center ">
      <div class="col-sm-8 align-self-center text-center">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <p id="roboto-help13">Tipo de identificac&oacute;n </p>
            <div class="form-group">
              <select class="form-help ">
                <option>Registro civil</option>
                <option>tarjeta de identidad</option>
                <option>C&eacute;dula de ciudadan&iacute;a</option>
                <option>Tarjeta de extranjer&iacute;a</option>
                <option>C&eacute;dula de extranjer&iacute;a</option>
                <option>NIT</option>
                <option>Pasaporte</option>
                <option>Documento de identificaci&aacute;n extranjero</option>
                <option>NIT otro pa&iacute;s</option>
                <option>NUIP*</option>
              </select>
            </div>
            <p id="roboto-help13">Identificaci&oacute;n</p>
            <div class="form-group">
              <input name="nit" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="nit" ngModel required #nit="ngModel">
              <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Identificaci&oacute;n' es obligatorio</small>
            </div>
            <p id="roboto-help13">Empresa </p>
            <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">
              <div class="form-group">
                <input name="empresa" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Empresa" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Empresa' es obligatorio</small>
              </div>
              <p id="roboto-help13">Razón Social </p>
              <div class="form-group">
                <input name="nombres" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Razonsocial" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Razón social' es obligatorio</small>
              </div>

              <p id="roboto-help13">Dirección</p>
              <div class="form-group">
                <input name="direccion" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Direccion" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Contacto' es obligatorio</small>
              </div>
              <p id="roboto-help13">Correo Electrónico </p>
              <div class="form-group">
                <input name="correo" type="text" class="form-helpdesk" id="txtcorreo" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Correo" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Correo' es obligatorio</small>
              </div>
              <p id="roboto-help13">Teléfono</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
                <button class="btn btn-empresa align-content-end">Agregar teléfono</button>
              </div>
              <p id="roboto-help13">Ciudad</p>
              <div class="form-group">
                <input name="ciudad" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="ciudad" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Ciudad' es obligatorio</small>
              </div>
            </form>

          </div>
        </div>
      </div>
     
      <div class=" container col-sm-12 align-self-center text-center">
        <hr style="border: solid">
        
        <p id="roboto-help12">
          Sucursales
        </p>
      </div>
      <div class="col-sm-8 align-self-center text-center">
        <div class="row justify-content-center">
          <div class="col-lg-8">

            <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">

              <p id="roboto-help11">Ingrese la Información</p>
              <p id="roboto-help13">Nombre</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <p id="roboto-help13">Teléfono</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <p id="roboto-help13">Correo Electrónico</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <p id="roboto-help13">Dirección</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <p id="roboto-help13">Ciudad</p>
              <div class="form-group">
                <input name="telefono" type="text" class="form-helpdesk" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder=" " title="Teléfono" ngModel required #nombres="ngModel">
                <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <button class="btn btn-sucursal align-content-end">Agregar Nueva Sucursal</button><br><br>
              <div class="container">


                <div class="row justify-content-center h-100">
                  <div class="col-sm-4 align-self-center text-center">
                    <button routerLink="/helpdesk" class="btn btn-light" type="submit" title="Cancelar el Ticket">cancelar</button>
                  </div>
                  <div class="col-sm-4 align-self-center text-center">
                    <button routerLink="/empresas" class="btn btn-helpdesk" type="submit" title="Crear el Ticket">Guardar</button>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
      <br><br>  <br><br>  <br><br>
    </div>
  </div>

