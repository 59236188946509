<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/mantvehicular']">
          <img src="../../../../src/assets/img/productos/logosmenu/mantvehicularm.png" width="250" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
         <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/mantvehicular#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/mantenimiento/img1.png " width="886 " height="480" class="img-fluid" alt="Responsive Image">
    </div>
  </div>
</div>
<br><br><br><br><br><br><br>
<br>
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="img-fluid" alt="Responsive Image">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center " id="mantenimiento">
    <div class="col-xl-4">
      <p class="text-justify roboto">El programa de <font class="font-weight-bold">Mantenimiento Vehícular,</font> le brindará un control total de los mantenimientos preventivos y correctivos de todo su parque automotor. Garantizando el efectivo, oportuno, seguro y fácil control de todas las partes de sus vehículos, demostrando indicadores de históricos, duraciones, rotaciones y próximos cambios que se deben practicar en el taller de mantenimiento.</p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/mantenimiento/img2.png" width="450 " height="391" class="img-fluid" alt="Responsive Image">
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!-- seccion dark -->
<div id="content-black">
  <div class=" container col-sm-6">
    <br />
    <p id="roboto-blue"> Permite entradas de vehículos a taller, órdenes de trabajo, remisiones de servicios, facturas de compra, elementos y entre muchas otras bondades.</p>
    <br />
  </div>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br>
<br>
<!--texto e imagen 2-->
<div class="container ">
  <div class="row justify-content-center " id="Funcionalidad">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/mantenimiento/img3.png " width="450" height="390" class="img-fluid" alt="Responsive Image">
    </div>
    <div class="col-xl-5">
      <p class="font-weight-bold" id="roboto-center16">Funcionalidad</p>
      <p class="text-justify roboto">
        Se ajusta ala reglamentación emitida por el Ministerio de Transporte que regula el servicio de transporte terrestre automotor de carga y establece el uso del formato único de manifiesto de carga y demás normatividades ajustadas ala resolución actual vigente.<br><br>
        Tiene conexión en línea (en tiempo real) con los servidores de Mintransporte (RNDC), evitando al usuario reprocesos para envío de información a la entidad.<br><br>
        Nuestro sistema se encarga de validar y reportar todo lo requerido de forma automática.
      </p>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<!--texto e imagen 3-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5 ">
      <p class="text-justify roboto">
        Maneja la información relacionada con los departamentos, ciudades, marcas, líneas de vehículos, modelos, colores, productos a transportar, tipos de carrocerías, unidades de medida, naturaleza de la carga, unidades de empaque, etc. <br><br>
        Brinda múltiples informes de remesas, movimientos de anticipo, despachos-seguridad, manifiestos, facturación, informes financieros, etc.<br><br>
        Controle vencimiento de documentos de los vehículos y de los conductores gracias a nuestro módulo de alertas automáticas las cuales le avisarán a su correo electrónico la fecha próxima a vencerse de cada uno de éstos.
      </p><br>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/mantenimiento/img4.png " width="450" height="393" class="img-fluid" alt="Responsive Image">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<!--viñetas dos columnas-->
<div class="container ">
  <p id="roboto-center18"> Nuestra aplicación de Mantenimiento Vehícular es tu complemento perfecto, ya que en el encontrarás:</p>
  <br>
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <ul>
        <li><p class="text-justify roboto">Hojas de Vida de Vehículos.</p></li>
        <li><p class="text-justify roboto">Repuestos, Inventarios y Partes.</p></li>
        <li><p class="text-justify roboto">Sistemas del Vehículo.</p></li>
        <li><p class="text-justify roboto">Entrada y Salidas de Repuestos.</p></li>
        <li><p class="text-justify roboto">Requisiciones y Compras.</p></li>
        <li><p class="text-justify roboto">Órdenes de Trabajo.</p></li>
      </ul>
    </div>
    <div class="col-xl-4 ">
      <ul>
        <li><p class="text-justify roboto">Inventarios, Seriales.</p></li>
        <li><p class="text-justify roboto">Entrada de Vehículos a Taller.</p></li>
        <li><p class="text-justify roboto">Alarmas.</p></li>
        <li><p class="roboto">Mantenimientos Preventivos y Correctivos.</p></li>
        <li><p class="text-justify roboto">Control de Kárdex y Costos.</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="" id="roboto-center18">¿Tienes Preguntas?</p>
      <br>
      <p class="" id="roboto-center16">
        Escríbenos y Felizmente las resolvemos
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br> <br>
      <img src=".../../../../src/assets/img/productos/activos.png"
           class="img-fluid" alt="Responsive Image"
           width="577" height="293" />
    </div>
  </div>
</div>
<br>
<br>
<br>
