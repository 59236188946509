<app-navbar></app-navbar>


<br>
<!-- separador -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!-- titulo centrado -->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="Titulos_center">
        Nuestra compañía
      </p>
    </div>
  </div>
</div>
<br>
<!-- empresa -->
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-3">
      <img src="../../../../src/assets/img/compania/compania1.png"
           class="img-fluid" alt="Responsive Image"
           width="200" height="240" />
    </div>
    <div class="col-xl-6">
      <p class="text-justify font-roboto ">
        Sistemas Comerciales SYSCOM S. A. es una empresa colombiana especializada en la implantación de soluciones de software específico para diferentes sectores industriales y comerciales, contando para ello con una serie de colaboradores tecnológicos
        de reconocido prestigio: Microsoft.<br /> <br />
        Lo invitamos a que conozca los diferentes productos que tenemos para usted, contamos con soluciones de software para diversos sectores del país, somos los únicos proveedores de software que entregarán
        seguridad, rigidez y confianza en la información que se suministra en su organización.
      </p>
    </div>
  </div>
</div>

<br><br><br><br><br>
<div class="container ">
  <img src="../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive " alt="... ">
</div>
<br><br>
<!-- mision -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <img src="../../../../src/assets/img/compania/compania2.png" width="500" height="551"
           class="img-fluid" alt="Responsive Image" />
    </div>
    <div class="col-xl-4 ">
      <p class="font-weight-bold roboto ">Nuestra misión </p>
      <p class="text-justify font-roboto ">
        SISTEMAS COMERCIALES SYSCOM S.A. es una compañía colombiana que ofrece soluciones de software para diferentes sectores comerciales, especializados en estaciones de servicio, empresas de transporte y mayorista de combustible que permite a nuestros
        clientes gestionar sus operaciones de forma organizada y eficiente para aumentar su productividad brindando un excelente servicio con personal calificado.
      </p>
    </div>
  </div>
</div>
<br><br>
<div class="container ">
  <div class="col-md-6 offset-md-3 ">
    <a (click)="scrollTop()" [routerLink]="['/trabajemos']">
      <img src="../../../../src/assets/img/compania/btn2.png " width="350" height="67" class="img-fluid" alt="Trabaje con nosotros ">
    </a>
  </div>
</div>
<div id="bg-blue-syscom">
  <br><br>
  <div class="p-3 mb-2">
    <div class="container ">
      <img src="../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
    </div>
    <br><br>
    <!-- vision -->
    <div class="container ">
      <div class="row justify-content-center ">
        <div class="col-xl-4 ">
          <img src="../../../../src/assets/img/compania/compania3.png"
               class="img-fluid" alt="Responsive Image"
               width="400" height="328" />
        </div>
        <div class="col-xl-4 ">
          <p class="font-weight-bold roboto">Nuestra Visión</p>
          <p class="text-justify font-roboto ">
            En el año 2026, SISTEMAS COMERCIALES SYSCOM S.A., seguirá liderando el mercado nacional y se posicionará en Latinoamérica como una compañía líder en el desarrollo e implementación de software especializado reconocida por la calidad, innovación
            y confiabilidad de sus productos y servicios sustentados en estándares internacionales con tecnología de punta.
          </p>
        </div>
      </div>
      <br />   <br />  <br />
      <div class="p-3 mb-2 text-white ">
        <div class="container ">
          <div class="col-md-6 offset-md-3 ">
            <a (click)="scrollTop()" [routerLink]="['/historia']">
              <img src="../../../../src/assets/img/compania/btn1.png " width="350" height="77" class="img-fluid" alt="Historia Syscom ">
            </a>
            <!--<button type="button" class="btn btn-secondary">Click aquí</button>-->
          </div>
        </div>
      </div>
      <br />    <br />
    </div>
  </div>
</div>

