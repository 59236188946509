import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parqueadero',
  templateUrl: './parqueadero.component.html',
  styleUrls: ['./parqueadero.component.scss']
})
export class ParqueaderoComponent implements OnInit {
 scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  constructor() { }

  ngOnInit(): void {
  }

}
