<app-navbar></app-navbar>
<br>
<!-- titulo centrado -->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="Titulos_center">
        ¿Tienes algo en mente que decirnos?
      </p>
    </div>
  </div>
</div>
<br>
<!-- formulario -->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <div class="card shadow">
        <div class="card-body">
          <!-- Tab panes -->
          <div class="tab-content">
              <div class="row justify-content-center">
                <div class="col-lg-8">
                  <br>
                  <p class="roboto18 font-weight-bold"></p>
                  <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">
                    <div class="form-group">
                      <input name="nombres" type="text" class="form-control" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder="Nombres y apellidos" title="Nombres y apellidos" ngModel required #nombres="ngModel">
                      <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Nombres' es obligatorio</small>
                    </div>
                    <div class="form-group">
                      <input name="email" type="email" class="form-control" id="txtEmail" [class.is-invalid]="email.invalid && email.touched" placeholder="Email-correo@dominio.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Email de contacto" ngModel required #email="ngModel">
                      <small *ngIf="email.invalid && email.touched" class="validacion form-text text-danger">El campo 'Email' es obligatorio y debe tener un formato de correo</small>
                    </div>
                    <div class="form-group">
                      <input name="telefono" type="tel" class="form-control" id="txtTel" [class.is-invalid]="telefono.invalid && telefono.touched" placeholder="Celular" title="Celular de contacto" required ngModel #telefono="ngModel">
                      <small *ngIf="telefono.invalid && telefono.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
                    </div>
                    <div class="form-group">
                      <input name="empresa" type="text" class="form-control" id="txtEmpresa" [class.is-invalid]="empresa.invalid && empresa.touched" placeholder="Empresa" title="Empresa de la cual realiza la solicitud" required ngModel #empresa="ngModel">
                      <small *ngIf="empresa.invalid && empresa.touched" class="validacion form-text text-danger">El campo 'Empresa' es obligatorio</small>
                    </div>
                    <div class="form-group">
                      <textarea name="mensaje" class="form-control" id="txtaMensaje" placeholder="Mensaje" rows="3" [class.is-invalid]="mensaje.invalid && mensaje.touched" style="resize:none;" title="Mensaje de interes" required ngModel #mensaje="ngModel"></textarea>
                      <small *ngIf="mensaje.invalid && mensaje.touched" class="validacion form-text text-danger">El campo 'Mensaje' es obligatorio</small>
                    </div>
                    <button id="btnSendP1" class="btn btn-syscom" type="submit" title="Realizar el envío" name="btnEnviar1">Enviar</button>
                  </form>
                  <div class="mt-3">
                    <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
                  </div>
                </div>
              </div>
            </div>
        
        </div>
      </div>
    </div>
  </div>
  <br><br>
</div>
