import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CompaniaComponent } from './components/compania/compania.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ContactoService } from './services/contacto.service';
import { AlertsComponent } from './components/alerts/alerts.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollComponent } from './components/scroll/scroll.component';
import { NominarhComponent } from './components/nominarh/nominarh.component';
import { TransespecialComponent } from './components/productos/transespecial/transespecial.component';
import { TranscargaComponent } from './components/productos/transcarga/transcarga.component';
import { BusurbanoComponent } from './components/productos/busurbano/busurbano.component';
import { MantvehicularComponent } from './components/productos/mantvehicular/mantvehicular.component';
import { ParqueaderoComponent } from './components/productos/parqueadero/parqueadero.component';
import { SystotalcomercialComponent } from './components/productos/systotalcomercial/systotalcomercial.component';
import { ContabilidadComponent } from './components/productos/contabilidad/contabilidad.component';
import { ActivosfijosComponent } from './components/productos/activosfijos/activosfijos.component';
import { FacturacionelectComponent } from './components/productos/facturacionelect/facturacionelect.component';
import { FacturacioneinventarioComponent } from './components/productos/facturacioneinventario/facturacioneinventario.component';
import { EstacionesComponent } from './components/productos/estaciones/estaciones.component';
import { PlantasComponent } from './components/productos/plantas/plantas.component';
import { TrabajemosComponent } from './components/compania/trabajemos/trabajemos.component';
import { HistoriaComponent } from './components/compania/historia/historia.component';
import { CopyComponent } from './components/copy/copy.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { PoliticaComponent } from './components/politica/politica.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SlidetutorialComponent } from './components/tutorial/slidetutorial/slidetutorial.component';
import { ContactogeneralComponent } from './components/contactogeneral/contactogeneral.component';
import { ContactosoporteComponent } from './components/contactosoporte/contactosoporte.component';
import { BlogComponent } from './components/blog/blog.component';
import { Post1Component } from './components/blog/post1/post1.component';
import { Post2Component } from './components/blog/post2/post2.component';
import { Post3Component } from './components/blog/post3/post3.component';
import { Mugan86GoogleAnalyticsModule } from 'mugan86-ng-google-analytics';
import { Post4Component } from './components/blog/post4/post4.component';
import { Post5Component } from './components/blog/post5/post5.component';
import { HelpdeskComponent } from './components/helpdesk/helpdesk/helpdesk.component';
import { LoginComponent } from './components/helpdesk/login/login.component';
import { NavhelpdeskComponent } from './components/helpdesk/navhelpdesk/navhelpdesk.component';
import { MatSliderModule } from '@angular/material/slider';
import { BuscarComponent } from './components/helpdesk/buscar/buscar.component';
import { NuevoticketComponent } from './components/helpdesk/nuevoticket/nuevoticket.component';
import { VerticketComponent } from './components/helpdesk/verticket/verticket.component';
import { TicketsComponent } from './components/helpdesk/tickets/tickets.component';
import { EmpresasComponent } from './components/helpdesk/empresas/empresas.component';
import { CrearempresaComponent } from './components/helpdesk/crearempresa/crearempresa.component';
import { ContactohelpdeskComponent } from './components/helpdesk/contactohelpdesk/contactohelpdesk.component';
import { Post6Component } from './components/blog/post6/post6.component';
import { Post7Component } from './components/blog/post7/post7.component';
import { Post8Component } from './components/blog/post8/post8.component';

import { SitemapComponent } from './components/sitemap/sitemap.component';
import { ContactoshelpComponent } from './components/helpdesk/contactoshelp/contactoshelp.component';
import { CrearcontactoComponent } from './components/helpdesk/crearcontacto/crearcontacto.component';
import { ConocimientoComponent } from './components/helpdesk/conocimiento/conocimiento.component';
import { CambiarcontrasenaComponent } from './components/helpdesk/cambiarcontrasena/cambiarcontrasena.component';
import { NuevoarticuloComponent } from './components/helpdesk/nuevoarticulo/nuevoarticulo.component';
import { SucursalesComponent } from './components/helpdesk/sucursales/sucursales.component';
import { BusquedaPipe } from './busqueda.pipe';
import { Post9Component } from './components/blog/post9/post9.component';
import { Post11Component } from './components/blog/post11/post11.component';
import { ServiciosComponent } from './components/helpdesk/servicios/servicios.component';

//import { CarouselComponent } from './components/carousel/carousel.component';



@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ProductosComponent,
    CompaniaComponent,
    ContactoComponent,
    NavbarComponent,
    FooterComponent,
    AlertsComponent,
    ScrollComponent,
    NominarhComponent,
    TransespecialComponent,
    TranscargaComponent,
    BusurbanoComponent,
    MantvehicularComponent,
    ParqueaderoComponent,
    SystotalcomercialComponent,
    ContabilidadComponent,
    ActivosfijosComponent,
    FacturacionelectComponent,
    FacturacioneinventarioComponent,
    EstacionesComponent,
    PlantasComponent,
    TrabajemosComponent,
    HistoriaComponent,
    CopyComponent,
    TerminosComponent,
    PoliticaComponent,
    TutorialComponent,
    SlidetutorialComponent,
    ContactogeneralComponent,
    ContactosoporteComponent,
    BlogComponent,
    Post1Component,
    Post2Component,
    Post3Component,
    Post4Component,
    Post5Component,
    HelpdeskComponent,
    LoginComponent,
    NavhelpdeskComponent,
    BuscarComponent,
    NuevoticketComponent,
    VerticketComponent,
    TicketsComponent,
    EmpresasComponent,
    CrearempresaComponent,
    ContactohelpdeskComponent,
    Post6Component,
    Post7Component,
    Post8Component,
   
    SitemapComponent,
    ContactoshelpComponent,
    CrearcontactoComponent,
    ConocimientoComponent,
    CambiarcontrasenaComponent,
    NuevoarticuloComponent,
    SucursalesComponent,
    BusquedaPipe,
    Post9Component,
    Post11Component,
    ServiciosComponent
    //CarouselComponent,
  ],

  imports: [
    BrowserModule,
    MatSliderModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
      Mugan86GoogleAnalyticsModule.forRoot(
      {
          analyticsId: '',
        showLog: true
      }
    )
  ],
  //exports: [AlertsComponent],
  providers: [ContactoService],
  bootstrap: [AppComponent],
})
export class AppModule { }



