<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" routerLink="/inicio">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">

    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-8 ">
        <img src="../../../../src/assets/img/blog/img9.jpg"
             class="img-fluid" alt="Responsive Image"
             width="880" height="400" />
        <br>  <br>
      </div>
      <br><br>
      <div class="col-xl-8 ">
        <p class="text-justify font-roboto ">
          Proyecto de decreto propone cambios en tiempos de servicio y en contratación.
          <br><br>
          El decreto 1079 de 2015 será modificado en el capítulo que regula la modalidad de servicio público de transporte terrestre automotor especial. <br><br>
          El Ministerio de Transporte dio a conocer que todos los ciudadanos interesados podrán consultar el decreto 1079 de 2015 y manifestarle al gobierno nacional los comentarios, opiniones y sugerencias que consideren necesarias para mejorar la actividad de transporte especial en el país. <br><br>
          El decreto estará publicado para toda la ciudadanía hasta el próximo 25 de febrero en el siguiente enlace: https://mintransporte.gov.co/documentos/558/proyectos/. <br><br>
          El decreto incluye la posibilidad de que universitarios, personal de salud y otros usuarios soliciten el servicio, entre otras medidas.  El documento establece que los vehículos matriculados antes del 14 de marzo de 2017 podrán continuar prestando el servicio de transporte escolar hasta los veinte años de uso, contados a partir del 31 de diciembre del año del modelo del vehículo. <br><br>
          Aquellos matriculados antes de la emergencia sanitaria (12 de marzo de 2020) tendrán tres años de tiempo de uso adicionales a los veinte años reglamentarios para seguir funcionando antes de su desintegración. Esta medida no aplicará a los vehículos que ya habían cumplido el tiempo estipulado para ser desintegrados antes de la pandemia. <br><br>
          “El Gobierno nacional reconoce el rol fundamental de los transportadores, en el modo terrestre automotor especial, para atender situaciones de emergencia como la propiciada por el covid-19, por lo que, en cuanto a los procesos de contratación, este nuevo proyecto busca adaptarlos a las condiciones planteadas por la pandemia”, indicó la cartera de Transporte. <br><br>
          El decreto también prohibiría el ingreso de vehículos de otra modalidad al servicio público de transporte terrestre automotor especial.
        </p>
        <br><br>


      </div>
    </div>
    <br> <br>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-8">
          <table>
            <tr>
              <td width="12px"></td>
              <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
              <td width="8px"></td>
              <td>
                <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post9" target="_blank">
                  <img src=".../../../../src/assets/img/blog/facemouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                </a>
                <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post9" target="_blank">
                  <img src=".../../../../src/assets/img/blog/twitermouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post9" target="_blank">
                  <img src=".../../../../src/assets/img/blog/inmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                </a>
                <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post9" target="_blank">
                  <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</div>

<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
