<app-navbar></app-navbar>
<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light zindex-syscom">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/transespecial']">
          <img src="../../../../src/assets/img/productos/logosmenu/transespecialm.png" width="245" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/transespecial#caracteristicas" title="Ir a caracteristicas"> Características</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/transespecial#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/transespecial/img1.png" class="img-fluid" alt="Responsive Image" width="865" height="387">
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 2-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/transespecial/img2.png" class="img-fluid" alt="Responsive Image" width="361" height="340">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        El software <font class="font-weight-bold">Transportes Especiales Syscom</font> permite a las
        empresas transportadoras controlar y administrar todo su proceso operativo de transporte,
        desde la emisión del FUEC, hasta el pago y cobro de los servicios.
        <br /><br />
        Igualmente permite el manejo
        controlado de cotizaciones,
        contratos, reservas, cumplimientos y
        liquidaciones, alerta con
        anterioridad el vencimiento de
        documentos tanto del Conductor
        como del Vehículo.
      </p>
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br> <br> <br> <br> <br>
<!--separador azul-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/transespecial/img3.png" class="img-fluid" alt="Responsive Image" width="368" height="341">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Permite el uso de parque automotor
        con vehículos propios o de terceros y la
        prestación de cualquier tipo de servicio
        a nivel nacional.
        <br /><br />
        El Ministerio de Transporte, se
        encuentra adecuando la conectividad
        en línea con sus servidores,
        autenticando en tiempo real todos sus
        registros, evitando reprocesos de envío
        y transmisión de datos a la entidad.
      </p>
    </div>
  </div>
</div>
<br /><br /><br /><br />
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark">
      Dispone de un amplio número de informes e integra y automatiza
      toda la información de manera transparente a contabilidad.
    </p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li>
          <p class="roboto ">
            Gestión Comercial
          </p>
        </li>
        <li>
          <p class="roboto ">
            Pre-cumplidos y Cumplidos.
          </p>
        </li>
        <li>
          <p class="roboto ">
            Facturación.
          </p>
        </li>
        <li>
          <p class="roboto ">
            Contabilidad en Línea.
          </p>
        </li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li>
          <p class="roboto text-justify">
            Contabilidad en Línea (Niif)
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Medios Magnéticos.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Cartera, Requisiciones y Compras.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">
        ¡Es nuestra misión hacer que la administración de todo su
        proceso operativo de transportes sea fácil para las
        pequeñas empresas!
      </p>
      <p class="text-center roboto">
        Pero si necesita ayuda, ¡es bienvenido!
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br> <br>
      <img src=".../../../../src/assets/img/productos/rechumanos.png"
           class="img-fluid" alt="Responsive Image"
           width="742" height="382" />
    </div>
  </div>
</div>
<br>
<br>
<br>

