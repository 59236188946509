<app-navbar></app-navbar>
<nav class="navbar zindex-syscom sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/estaciones']">
          <img src="../../../../src/assets/img/productos/logosmenu/estacionesm.png" width="250" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/estaciones#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/estaciones/img1.png" class="img-fluid" alt="Responsive Image" width="800 " height="397">
    </div>
  </div>
</div>
<br><br><br><br><br><br><br><br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br /><br />
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        La aplicación <font class="font-weight-bold">Gestión Estación de Servicios y Mayorista de Combustibles </font>le permite
        administrar y controlar de forma total, el funcionamiento de su estación de forma
        ágil, eficiente y segura. Permite el práctico manejo de inventarios, compras y
        ventas, contabilidad automatizada y coste o al día de todos sus productos.
      </p>
    </div>

    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/estaciones/img2.png" class="img-fluid" alt="Responsive Image" width="352 " height="214">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>  <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-6">
    <p id="roboto-blue-dark"> Exporta sus informes por correo electrónico y otras aplicaciones de Windows.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li>
          <p class="roboto text-justify">
            Gracias al módulo de consultas, informes
            y estadísticas el sistema permite obtener
            información sobre el movimiento del
            producto y saldos actuales por tanque,
            bodega, tienda o sección de inventarios de
            su compañía.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Estaciones de servicio SYSCOM permite el
            manejo de cartera, es decir, hacer ventas
            de combustible a crédito (valeras) para
            facturarlas posteriormente y controlar sus
            respectivos movimientos.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Registra el movimiento diario de su
            Estación, en la que se registran las ventas
            de combustible realizadas por cada isla.
          </p>
        </li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li>
          <p class="roboto text-justify">
            Permite realizar el proceso de
            calibración de surtidores de forma
            fácil y segura.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Puede importar la lectura de los
            cortes reportados por sistemas
            instalados en surtidores, con el fin de
            realizar la planilla automáticamente,
            minimizando tiempo y márgenes de
            error.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Permite un manejo de usuarios por
            niveles.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Soporte personalizado, telefónico o
            vía internet.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-6">
    <p id="roboto-blue-dark">Desarrollado 100% con herramientas bajo ambiente Windows, manejo de ambiente gráfico Y Exporta sus informes por correo electrónico.</p>
  </div>
  <br>
</div>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-7 align-self-center text-center">
      <p id="roboto-center18">¿Tienes alguna pregunta?</p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br>
      <img src=".../../../../src/assets/img/productos/activos.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="253" />
    </div>
  </div>
</div>
<br>
<br>
<br>
