<app-navbar></app-navbar>
<br><br><br>
<!-- acerca_de -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <table>
        <tr>
          <td><br><br><br></td>
        </tr>
        <tr>
          <td>
            <img src="../../../../src/assets/img/compania/logohist.png "
                 class="img-fluid" alt="Responsive Image"
                 width="280" height="257" />
          </td>
        </tr>
        <tr>
          <td><br><br></td>
        </tr>
      </table>
    </div>
    <div class="col-xl-4 ">
      <p class="text-justify font-roboto ">
        Somos un ente corporativo creado en el año de 1995 para brindar soporte tecnológico para el mercado en general y de cubrir las necesidades informáticas y de nuevas tecnologías en el procesamiento de la información de las empresas y ayudarlos
        a su mejor desarrollo. Está conformado por un grupo de profesionales con más de 10 años de experiencia en los sectores del Transporte, Estaciones de Servicio, Informática, Redes y Soluciones Comerciales.<br><br> En la actualidad poseemos
        una amplia gama de aplicativos con servicios corporativos de gran valía, permitiéndole a cualquier Empresa Colombiana competir en la economía nacional e internacional, merced a la filosofía "ONLINE" que ofrecen dichas soluciones, orientando
        los procesos hacia Internet y complementando los resultados con un "Know How" de reconocimiento en el mercado colombiano. reconocimiento en el mercado colombiano.
      </p>
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!-- contenido texto2 -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <p class="text-justify font-roboto">
        Desde el principio de su creación se estableció como filosofía de empresa que el verdadero corazón de un sistema informático y el verdadero propulsor de una compañía es y debe ser, el uso de aplicaciones de gestión adaptadas a las necesidades
        particulares de las mismas y en segundo término, el soporte informático para mantenerlo en funcionamiento: estaciones de trabajo, servidores de red, redes de comunicaciones y de tecnologías de apoyo.
      </p>
    </div>
    <div class="col-xl-4 ">
      <img src="../../../../src/assets/img/acerca/img2.png "
           class="img-fluid" alt="Responsive Image"
           width="350" height="266" />
    </div>
  </div>
</div>
<br><br><br><br><br>
<!-- separador negro -->
<div class="p-3 mb-2  ">
  <div class="container ">
    <img src="../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
  </div>
  <br><br>
  <!-- contenido texyo3 -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-4 ">
        <img src="../../../../src/assets/img/acerca/img3.png "
              class="img-fluid" alt="Responsive Image"
              width="350" height="350" />
      </div>
      <div class="col-xl-4 ">
        <p class="text-justify ">
          SISTEMAS COMERCIALES SYSCOM S.A, ofrece soluciones comerciales de software a través de un excelente servicio y personal competente para satisfacer las necesidades y expectativas de nuestros clientes, cumpliendo con los requisitos de la
          organización, legales y reglamentarios mejorando continuamente nuestros procesos, para lograr el crecimiento y reconocimiento de la compañía.
        </p>
        <img src=".../../../../src/assets/img/acerca/img4.png "
              class="img-fluid" alt="Responsive Image"
              width="350" height="60" />
      </div>
    </div>
  </div>
  <br><br>
</div>
