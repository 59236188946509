<app-navbar></app-navbar>
<nav class="navbar  zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/parqueadero']">
          <img src="../../../../src/assets/img/productos/logosmenu/parqueaderom.png" width="170" height="48" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/parqueadero#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/parqueadero/img1.png" class="img-fluid" alt="Responsive Image" width="734 " height="456">
    </div>
  </div>
</div>
<br><br><br><br><br><br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 2-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        <font class="font-weight-bold"> Sysparking </font>se presenta como una
        alternativa de administración de parqueaderos de empresas que cuenten con
        el módulo Syscom40 Comercial, permitiéndole control en cantidad de espacio
        de parqueo, manejo de facturación integrada a contabilidad, tanto POS como
        crédito, dependiendo las condiciones de contrato establecidas con el cliente,
        manejo de tarifas especiales para socios.
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/parqueadero/img2.png" class="img-fluid" alt="Responsive Image" width="350" height="303">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-8">
    <p id="roboto-blue-dark"> Generación automática de factura POS al registrar la salida del vehículo para pagos bajo la modalidad contado.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-6">
      <ul>
        <li>
          <p class="roboto text-justify">
            Configuración de tarifas y descuentos por categoría
            de vehículo.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Reporte visual de espacios ocupados y disponibles
            por categoría de vehículo.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Manejo de contratos para definir modalidades de pago
            de los vehículos.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-7 align-self-center text-center">
      <p id="roboto-center18">
        ¿Tiene alguna pregunta sobre cómo la aplicación <font class="font-weight-bold">Parqueadero Sysparking </font>puede ayudar a su negocio?
      </p>
      <p class="text-center roboto">
        Envíenos un correo electrónico y nos pondremos en contacto en breve.
      </p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br><br>
      <img src=".../../../../src/assets/img/productos/activos.png"
           class="img-fluid" alt="Responsive Image"
           width="490" height="404" />
    </div>
  </div>
</div>
<br>
<br>
<br>

