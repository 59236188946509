<app-navhelpdesk></app-navhelpdesk>

<!--seccion 1-->
<div class="nav" style="background-color: #F4F4F4;">
  <div class="container" style="background-color: #FFF;">
    <div class="container">
      <div class="col-sm-2 align-self-center text-center">
        <p id="roboto-help20">
          Estado Ticket
        </p>
      </div>
      <div class="col-sm-1 align-self-center text-center" style="background-color: Highlight;">
        <p id="roboto-help22">
          En curso
        </p>
      </div>
    </div>
  </div>

  <!--seccion 2-->
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-sm-7 align-self-center text-center" style="background-color: #F4F4F4;">
        <div class="row justify-content-end h-100">
          <div class="col-sm-3">
            <img src="../../../../src/assets/img/helpdesk/ticket/nota.png" width="120" height="28" class="img-fluid">
          </div>
          <div class="col-sm-3">
            <img src="../../../../src/assets/img/helpdesk/ticket/certick.png" width="120" height="28" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="col-sm-1 align-self-center text-center" style="background-color: #F4F4F4;"> </div>
      <div class="col-sm-4 align-self-center text-center" style="background-color: #FFF;">
        <div class=" h-100">
          <p id="roboto-help31">Propiedades</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="divscroll col-sm-8 align-self-center text-center" style="background-color: #FFF;">
        <p id="roboto-help31">
          <img src="../../../../src/assets/img/helpdesk/Nav/tickets.png" width="45" height="48" class="img-fluid">
          10025 syscom 20 Estaciones de Servicio
        </p>
        <p id="roboto-help11" >Contacto: <font color="dodgerblue">Alejandro Cortez</font>
             Creado por: Yaqueline Lizarazo</p>
        <p id="roboto-help11" >Creado: 2 diciembre 2020 3:20 p.m.</p>
        <p id="roboto-help21">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum."
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum."
        </p>
        <p id="roboto-help21">
          Nota ticket
        </p>
        <p id="roboto-help21">
          Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem
          ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum
        </p>
        <div class="form-group">
          <textarea class="form-descrip" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="container">
          <div class="row justify-content-center h-100">
            <div class="col-sm-3 align-self-center text-center">
              <button routerLink="/helpdesk" class="btn btn-light" type="submit" title="Cancelar el Ticket">cancelar</button>
            </div>
            <div class="col-sm-3 align-self-center text-center">
              <button routerLink="/verticket" class="btn btn-helpdesk3" type="submit" title="Crear el Ticket">Crear Nota</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4  text-center" style="background-color: #FFF;">
        <p id="roboto-help21">
          Estado
        </p>

        <select class="form-control" size="3" aria-label="size 3 select example">
          <option selected>Pendiente</option>
          <option value="1">En espera de revision</option>
          <option value="2">En curso</option>
          <option value="3">Completado</option>
        </select>
        <br>
        <button routerLink="/verticket" class="btn btn-helpdesk2" type="submit" title="Crear el Ticket">Actualizar</button>

        <p id="roboto-helpblue">Alejandro Cortez</p>
        <p id="roboto-help32">Tickets creados para este contacto</p>
        <p id="roboto-helpblue">6 Tickets</p>
      </div>

    </div>
  </div>

</div>


