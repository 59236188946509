import { Component, OnInit, ElementRef, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { TipoMensaje, ClsAlert } from 'src/app/Interfaces';
import { transition, animate, trigger, state, style } from '@angular/animations';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
      trigger('openClose', [
        // ...
        state('open', style({
          //height: '200px',
          opacity: 10,
          //backgroundColor: 'yellow'
        })),
        state('closed', style({
          //height: '100px',
          opacity: 0.5,
          //backgroundColor: 'green'
        })),
        transition('open => closed', [
          animate('1s')
        ]),
        transition('closed => open', [
          animate('0.5s')
        ]),
      ]),
  ]
  //animations: [
  //  trigger('changeDivSize', [
  //    state('initial', style({
  //      backgroundColor: 'green',
  //      width: '100px',
  //      height: '100px'
  //    })),
  //    state('final', style({
  //      backgroundColor: 'red',
  //      width: '200px',
  //      height: '200px'
  //    })),
  //    transition('initial=>final', animate('1500ms')),
  //    transition('final=>initial', animate('1000ms'))
  //  ]),
  //]
})

export class AlertsComponent implements OnInit{
  @Input() Alerta: ClsAlert;
  @Input() VerAlerta: boolean;
  //@Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }


  ngOnInit(): void {
  }

  toggle() {
    this.VerAlerta = !this.VerAlerta;
  }

  //closeAlert() {
  //  this.onClose.emit(true);


  //}
}
