import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nominarh',
  templateUrl: './nominarh.component.html',
  styleUrls: ['./nominarh.component.scss']
})
export class NominarhComponent implements OnInit {

  constructor() { }
  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  ngOnInit(): void {
  }

}
