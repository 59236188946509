export interface ClsEmail {
  nombres: string,
  email: string,
  telefono: string,
  empresa: string,
  mensaje: string,
  tipocorreo: number,
  //nombres2: string,
  //email2: string,
  //telefono2: string,
  //empresa2: string,
  //mensaje2: string,
  //tipocorreo2: number
}

export interface ClsAlert {
  title: string,
  type: string,
  message: string
}

export enum TipoMensaje { Info, Warning, Success, Danger, Primary, Secondary, Light, Dark }
