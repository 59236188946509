<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F4F4F4;"> <!--color gris--> 
  <div class="container h-100">
    <div class="row  h-100">

      <div class="col-lg-8">
        <div class="col-sm-12    " style="background-color: #FFF;">
          <div class="row justify-content-end h-100 ">
            <div class="col-sm-3 align-bottom text-justify">
              <img src="../../../../src/assets/img/helpdesk/empresa/empresa.png" width="170" height="170" class="img-fluid">
            </div>
            <div class="col-sm-4 align-bottom text-center">
              <p id="roboto-help31">
                Transporte de Carga JVC S.A.S
              </p>
              <p id="roboto-help22">
                www.transportedecragajvc.com
              </p>
              <p id="roboto-help21">
                Empresa:<font id="roboto-help32">TRANSCARGA JVC</font>
              </p>
            </div>

            <div class="col-sm-3 align-bottom">
              <br>  <br><br><br><br><br>
              <img src="../../../../src/assets/img/helpdesk/empresa/editar.png" width="80" height="20" class="img-fluid">
            </div>
            <div class="col-sm-2 align-bottom">
              <br><br><br><br><br>
              Estado: <img src="../../../../src/assets/img/helpdesk/empresa/activo-on.png" width="80" height="20" class="img-fluid">
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-1">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-contend">
            Tickets Creados
            <br>
            3 0
          </p>

        </div>
      </div>
      <div class="col-lg-3">
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
          <p id="roboto-help23">
            Servicios Contratados
          </p>
        </div>
        <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">

          <a routerLink="/servicios">
            <p id="roboto-helpblu">
              Agregar Servicio
            </p>
          </a>
        </div>

      </div>
    </div>
  </div>
  <!--segunda-->

  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-3">
        <div class="col-sm-12 ">
          <p id="roboto-help23">
            Datos
            <br>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="col-sm-12 align-self-center text-center">
          <p id="roboto-help23">
            Sucursales

          </p>

        </div>
      </div>
      <div class="col-lg-3">
        <div class="col-sm-12 align-self-center text-center">
          <br>
        </div>
      </div>
    </div>
  </div>
  <!--Tercera-->

  <div class="container h-100">
    <div class="row  h-100">
      <div class="col-lg-3">
        <div class="col-sm-12 " style="background-color: #FFF;">
          <p id="roboto-help23">
            Razon Social
          </p>
          <p class="text-center">
            TRANSPORTE DE CARGA JVC S.A.S
            <br>
          <p id="roboto-help23">
            NIT o Cédula
          </p>
          <p class="text-center">
            109865252
          </p>
          <br>
          <p id="roboto-help23">
            Teléfono
          </p>
          <p class="text-center">
            31652545558
          </p>
          <br>
          <p id="roboto-help23">
            Correo Electrónico
          </p>
          <p class="text-center">
            info@transportejvc.com
          </p>
          <br>
          <p id="roboto-help23">
            Dirección
          </p>
          <p class="text-center">
            Cra 123c # 18-21 Fontibon
          </p>
          <br>
          <p id="roboto-help23">
            Ciudad
          </p>
          <p class="text-center">
            Bogotá
          </p>
          <br>
        </div>
      </div>


      <div class="col-lg-6">
        <div class="col-sm-12 " style="background-color: #FFF;">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">

                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                <button class="btn dropdown-toggle" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Barranquilla
                </button>

              </div>

            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Bogotá
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">

                Errores Gestión Comercial
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Villavicencio
                </button>
              </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="card-body">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Errores Mantenimiento Preventivo
              </div>

            </div>
          </div>


        </div>

      </div>
      <div class="col-lg-3">
        <div class="col-sm-12 " style="background-color: #FFF;">
          <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label id="roboto-help21" class="form-check-label" for="flexCheckChecked">
                Contabilidad
                <br>
                Version
                <br>
                12.5.5.3
              </label>
            </div>
          </div>
          <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label id="roboto-help21" class="form-check-label" for="flexCheckChecked">
                Gestión comercial
                <br>
                Version
                <br>
                21.3.6
              </label>
            </div>
          </div>
          <div class="col-sm-12 align-self-center text-center" style="background-color: #FFF;">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
              <label id="roboto-help21" class="form-check-label" for="flexCheckChecked">
                Syscom 30 Total
                <br>
                Version
                <br>
                2.5.6
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
