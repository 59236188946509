<!--menu blog-->
<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <a href="https://www.facebook.com/Syscom-Sistemas-Comerciales-SA-100343868410571" target="_blank">
          <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
        </a>
      </li>
      <li class="nav-item centrarvertical">
        <a href="">
          <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
        </a>
      </li>
      <li class="nav-item centrarvertical">
        <a href="https://www.instagram.com/empsyscomsabuc/?igshid=14wyzw8775baj" target="_blank">
          <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
        </a>
      </li>
    </ul>
  </div>
</nav>
<br><br>
<head>

  <title>Titulo de Página. 60-70 caracteres como maximo</title>
  <meta name="description" content="Descripcion de pagina. No superar los 155 caracteres." />

  <!-- Schema.org markup for Google+ -->
  <meta itemprop="name" content="Titulo">
  <meta itemprop="description" content="syscom blogsyscom blogsyscom blogsyscom blogsyscom blogsyscom blogsyscom blog">
  <meta itemprop="image" content="https://web.syscom.com.co/src/assets/img/blog/img2.jpg">
  <!-- Twitter Card data -->
  <meta name="twitter:card" content="summary_large_image">
  <meta name="twitter:site" content="@publisher_handle">
  <meta name="twitter:title" content="syscom blog">
  <meta name="twitter:description" content="Descripcion que no supere los 200 caracteres">
  <meta name="twitter:creator" content="elkin villamizar">
  <!-- Twitter summary card with large image. Al menos estas medidas 280x150px -->
  <meta name="twitter:image:src" content="https://web.syscom.com.co/src/assets/img/blog/img2.jpg">
  <!-- Open Graph data -->
  <meta property="og:title" content="Titulo" />
  <meta property="og:type" content="syscom blogsyscom blogsyscom blogsyscom blogsyscom blogsyscom blog" />
  <meta property="og:url" content=" https://web.syscom.com.co/post2" />
  <meta property="og:image" content=" https://web.syscom.com.co/src/assets/img/blog/img2.jpg" />
  <meta property="og:description" content="blogsyscomblogsyscomblogsyscomblogsyscom" />
  <meta property="og:site_name" content="blogsyscom" />
  <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" />
  <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" />
  <meta property="article:section" content="blogsyscomblogsyscomblogsyscomblogsyscomblogsyscom" />
  <meta property="article:tag" content="Article Tag" />
  <meta property="fb:admins" content="ID de Facebook " />

</head>
<!--contenido post 11-->
<div class="container">
  <div class="row justify-content-center ">

    <div class="col-xl-8 embed-responsive embed-responsive-16by9 ">
      <iframe class="embed-responsive-item" width="800" height="364" src="https://www.youtube.com/embed/HaloW-5dBSI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">

      <p class="roboto-center18">¿Necesitas implementar nómina electrónica y ¿no sabes cómo hacerlo? </p>
      <p class="text-justify font-roboto ">
        Hoy hablamos sobre este tema,al que muy seguramente has escuchado hablar mucho por estos días.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post11" class="btn btn-outline-secondary center-block" type="submit" title="ver post" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post11" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post11" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post11" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post11" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 10-->
<div class="container">
  <div class="row justify-content-center ">

    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img10.jpg"
           class="img-fluid" alt="Responsive Image"
           width="800" height="364" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">

      <p class="text-justify font-roboto ">
        El 11 de febrero de 2021, la DIAN emitió la resolución No. 000013 mediante la cual dispuso la implementación y desarrollo, en el sistema de facturación electrónica, de la funcionalidad del documento soporte de pago de nómina electrónica.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post10" class="btn btn-outline-secondary center-block" type="submit" title="ver post" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post10" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post10" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post10" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post10" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 9-->
<div class="container">
  <div class="row justify-content-center ">

    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img9.jpg"
           class="img-fluid" alt="Responsive Image"
           width="800" height="364" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">

      <p class="text-justify font-roboto ">
        Proyecto de decreto propone cambios en tiempos de servicio y en contratación.
        <br><br>
        El decreto 1079 de 2015 será modificado en el capítulo que regula la modalidad de servicio público de transporte terrestre automotor especial.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post9" class="btn btn-outline-secondary center-block" type="submit" title="ver post" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post9" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post9" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post9" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post9" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 7-->
<div class="container">
  <div class="row justify-content-center ">

    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img7.jpg"
           class="img-fluid" alt="Responsive Image"
           width="800" height="364" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">
      <p class="roboto-center18">Diferencias hay entre eCommerce y eBusiness</p>
      <p class="text-justify font-roboto ">
        Algunas personas confunden los términos “E-Business” y E-Commerce o los usan indistintamente. Aunque el E-Commerce en español es referido como Comercio Electrónico, el E-Business no tiene un término equivalente por lo que usaremos ambos nombres en su denominación original en inglés.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post7" class="btn btn-outline-secondary center-block" type="submit" title="ver post 1" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post7" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post7" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post7" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post7" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 6-->
<div class="container">
  <div class="row justify-content-center ">

    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img6.jpg"
           class="img-fluid" alt="Responsive Image"
           width="800" height="364" />
      <br>  <br>
    </div>

    <br />   <br />  <br />
    <div class="col-xl-8 ">
      <p class="roboto-center18">La nómina electrónica, la nueva Propuesta de la DIAN </p>
      <p class="text-justify font-roboto ">
        La Dian busca que la nómina electrónica se convierta en un documento estratégico para la administración tributaria, como lo es la facturación electrónica. El Proyecto de Resolución busca impulsar esta herramienta como un elemento más de la transformación digital de las empresas colombianas.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post6" class="btn btn-outline-secondary center-block" type="submit" title="ver post 6" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post6" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post6" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post6" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post6" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 8-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-9 ">
      <img src="../../../../src/assets/img/blog/img8.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8">

      <p class="text-justify font-roboto ">
        Informamos a la comunidad que el sitio de Auto Factura por motivo de actualización estará inactivo el dia 12 de febrero en el horario de 1:00 p.m a 2:00 p.m
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post8" class="btn btn-outline-secondary center-block" type="submit" title="ver post 8" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post8" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post8" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post8" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post8" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 5-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img5.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">
      <p class="roboto-center18">Redes sociales para las empresas </p>
      <p class="text-justify font-roboto ">
        Hoy en día sabemos que las redes sociales para empresas juegan un papel importante en el mundo de los negocios y que pueden ser útiles como estrategia de posicionamiento para generar una comunidad con nuestros clientes y aumentar las ventas de nuestros productos o servicios.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post5" class="btn btn-outline-secondary center-block" type="submit" title="ver post 5" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post5" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post5" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post5" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post5" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!--contenido post 4-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img4.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">
      <p class="roboto-center18">Nueva Versión Autofactura</p>
      <p class="text-justify font-roboto ">
        Syscom Autofactura es una solución en la nube que le permite generar su factura electrónica sin necesidad de instalar nada. Desde cualquier computador con internet usted puede ingresar con usuario y contraseña, concede reducir la complejidad y aumentar la eficacia de los procesos relacionados a la facturación de las empresas.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post4" class="btn btn-outline-secondary center-block" type="submit" title="ver post 1" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post4" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post4" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post4" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post4" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>

  </div>
</div>
<!--contenido post 3-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img3.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <br />   <br />  <br />
    <div class="col-xl-8 ">
      <p class="roboto-center18">CAMIONES Y VANS, LAS MEJORES ALTERNATIVAS EL TRANSPORTE DE CARGA PARA MOVERSE EN EL CAMPO</p>
      <p class="text-justify font-roboto ">
        Según la información presentada de la Asociación Nacional de Movilidad Sostenible, la reactivación de las ciudades ha permitido que segmentos como carga liviana (< 10,5 toneladas) comiencen a recuperarse, en octubre este segmento fue el único con una variación positiva 79,2% equivalente a 1.856 unidades vendidas.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post3" class="btn btn-outline-secondary center-block" type="submit" title="ver post 1" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.syscom.com.co/post3?fbclid=IwAR1a764W8DeL1xpIzbH882vawnt0v7uAPDycWDB55l2pyRN3504xV7C9us8" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post3" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post3" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post3" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>

  </div>
</div>
<!--post 2-->
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img2.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <div class="col-xl-8 ">
      <p class="roboto-center18">Mejora con curso para llevar la facturación electrónica de una empresa desde la nube</p>
      <p class="text-justify font-roboto ">
        Hoy en día, hay más personas necesitan obtener conocimientos en diversas disciplinas
        a su área de trabajo o no. Esto se debe a que existen distintas plataformas digitales que ponen recursos
        informativos o de aprendizaje al alcance de nuestras manos de manera gratuita.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post2" class="btn btn-outline-secondary center-block" type="submit" title="ver post 1" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://web.syscom.com.co/post2?fbclid=IwAR1hA48WID12RHZxiIY8MkuRKanEwdpNAOKPGLM3P9Y8dgzbafdHw-LhJq0" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página https://web.syscom.com.co/post2?fbclid=IwAR1hA48WID12RHZxiIY8MkuRKanEwdpNAOKPGLM3P9Y8dgzbafdHw-LhJq0" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=https://web.syscom.com.co/post2?fbclid=IwAR1hA48WID12RHZxiIY8MkuRKanEwdpNAOKPGLM3P9Y8dgzbafdHw-LhJq0" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=https://web.syscom.com.co/post2?fbclid=IwAR1hA48WID12RHZxiIY8MkuRKanEwdpNAOKPGLM3P9Y8dgzbafdHw-LhJq0" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>
  </div>
</div>
<!-- contenido post -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <img src="../../../../src/assets/img/blog/img1.jpg"
           class="img-fluid" alt="Responsive Image"
           width="880" height="400" />
      <br>  <br>
    </div>
    <br><br>
    <div class="col-xl-8 ">
      <p class="roboto-center18">Facturación Electrónica</p>
      <p class="text-justify font-roboto ">
        La facturación electrónica ha sido un tema muy discutido recientemente, especialmente durante el último año. Esto se debe a los pronunciamientos de la DIAN, que a principios de 2019 anunció nuevas medidas para la aplicación de la facturación electrónica. Al mismo tiempo, varias empresas propusieron ideas para una facturación electrónica estandarizada.
      </p>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center ">
      <div class="col-xl-8">
        <table>
          <tr>
            <td>
              <button (click)="scrollTop()" routerLink="/post1" class="btn btn-outline-secondary center-block" type="submit" title="ver post 1" name="general">Continuar leyendo</button>
            </td>
            <td width="12px"></td>
            <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
            <td width="8px"></td>
            <td>
              <a href="https://www.facebook.com/sharer/sharer.php?u=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                <img src=".../../../../src/assets/img/blog/facemouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
              </a>
              <a href="https://twitter.com/?status=Me gusta esta página https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                <img src=".../../../../src/assets/img/blog/twitermouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
              </a>
              <a href="https://www.linkedin.com/shareArticle?url=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                <img src=".../../../../src/assets/img/blog/inmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
              </a>
              <a href="http://pinterest.com/pin/create/button/?url=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                     width="35" height="35"
                     onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                     onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <br><br><br><br>

  </div>
</div>


<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe class="responsive-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>

</footer>

