<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <p class="roboto-center18">La nómina electrónica, la nueva Propuesta de la DIAN </p>
    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-8 ">
        <img src="../../../../src/assets/img/blog/img6.jpg"
             class="img-fluid" alt="Responsive Image"
             width="880" height="400" />
        <br>  <br>
      </div>
      <br><br>
      <div class="col-xl-8 ">
        <p class="text-justify font-roboto ">
          La Dian busca que la nómina electrónica se convierta en un documento estratégico para la administración tributaria, como lo es la facturación electrónica. El proyecto de Resolución busca impulsar esta herramienta como un elemento más de la transformación digital de las empresas colombianas.          
          <br><br>
          La integración de esta nueva funcionalidad será un reto no solo para las compañías que deberán reportar a la Dian, sino también para quienes prestan sus servicios con tecnología, uno de ellos será la seguridad en la transmisión de los datos, en el cual Sistemas comerciales SYSCOM está preparado su aplicación, que muy pronto saldrá al mercado.
          <br><br>
          la nómina electrónica está compuesta de conceptos como devengos o deducibles, los cuales serán expuestos mediante operadores autorizados y certificados por la Dian, los cuales harán llegar la información a la entidad utilizando un archivo encriptado, en el caso de que la validación haya sido satisfactoria, la nueva nómina validada y firmada por la Dian será enviada a la entidad o empresa obligada que la generó para su conservación y fines pertinentes.
          <br><br>
          En el caso de que la Dian haya rechazado la validación de la nómina, la empresa que la generó recibirá una notificación electrónica con los errores cometidos y deberá volver a generar una nueva nómina electrónica con las modificaciones; la cual tendrá que volver a ser transmitida para su validación por la Dian.
          <br><br>
          Las personas pertenecientes a la nómina de la empresa que está entregando la información recibirán una notificación con el soporte respectivo.
          <br><br>
          Asimismo, con la publicación de esta Resolución, la Dian también establecerá un calendario de implementación del nuevo sistema de nómina electrónica con varios plazos para el cumplimento periódico y la generación y transmisión de las nóminas de forma electrónica.  El Proyecto de Resolución contempla la posibilidad de que todas aquellas entidades que, de manera voluntaria, decidan implementar la nómina electrónica antes de su obligación puedan hacerlo siempre y cuando cumplan con todas las disposiciones establecidas por la Dian para regular este nuevo sistema.
          <br><br>
          
      </div>
    </div>
    <br> <br>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-8">
          <table>
            <tr>
              <td width="12px"></td>
              <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
              <td width="8px"></td>
              <td>
                <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post6" target="_blank">
                  <img src=".../../../../src/assets/img/blog/facemouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                </a>
                <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post6" target="_blank">
                  <img src=".../../../../src/assets/img/blog/twitermouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post6" target="_blank">
                  <img src=".../../../../src/assets/img/blog/inmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                </a>
                <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post6" target="_blank">
                  <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</div>

<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
