<nav class="navbar sticky-top navbar-expand-lg navbar-light navbar-light-borde" style="background-color: #F4F4F4;">
  <div class="container">

    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>

    <div class="collapse navbar-collapse navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">

        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <!--@Url.Action(" Index","Productos")-->
            <a class="nav-link" (click)="scrollTop()" [routerLink]="['/productos']" title="Ver productos o aplicaciones de Syscom"> Productos</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link" (click)="scrollTop()" [routerLink]="['/compania']" title="Nuestra compañía"> Compañía</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link" (click)="scrollTop()" [routerLink]="['/contacto']" title="Ir a contacto"> Contacto</a>
          </li>
        </ul>
        <div class="dropdown ">
          <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" title="Contactar agencias por Whatsapp">
            <img src=".../../../../src/assets/img/Navegacion/clic.png" width="150" height="48" alt="" loading="lazy">
          </button>
          <div class="container-fluid">
            <ul class="dropdown-menu   ">
              <!--<li><a href="#">Barranquilla</a></li>
              <li><a href="#">Bogot&aacute</a></li>
              <li><a href="https://api.whatsapp.com/send?phone=573173682059&text=Hola!%20Queremos%20ayudarte,%20por%20favor%20deja%20tu%20mensaje." target="_blank">Bucaramanga</a></li>
              <li><a href="#">Medell&iacuten</a></li>-->
              <li> <img src="../../../../src/assets/img/Navegacion/wp-ico.png" width="20" height="20"><a href="https://api.whatsapp.com/send?phone=573174408886&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Barranquilla">Barranquilla</a></li>
              <li><img src="../../../../src/assets/img/Navegacion/wp-ico.png" width="20" height="20" alt=""><a href="https://api.whatsapp.com/send?phone=573164673519&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Bogotá">Bogotá</a></li>
              <li><img src="../../../../src/assets/img/Navegacion/wp-ico.png" width="20" height="20" alt=""><a href="https://api.whatsapp.com/send?phone=573176435107&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Bucaramanga">Bucaramanga</a></li>
              <li><img src="../../../../src/assets/img/Navegacion/wp-ico.png" width="20" height="20" alt=""><a href="https://api.whatsapp.com/send?phone=573187165151&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Medellín">Medellín</a></li>
            </ul>
          </div>
        </div>
        <!--class="form-syscom mr-sm-2 bg-dark"-->
        <form class="form-inline">
          <input id="form-syscom" type="search" placeholder="Buscar" aria-label="Search" title="Buscar contenido">
        </form>
      </div>
    </div>
    <a href="https://webmail.syscom.com.co/Mondo/lang/sys/login.aspx" target="_blank" title="Ir a correo corporativo de Syscom S.A.">
      <img src="../../../../src/assets/img/Navegacion/correo.png" width="50" height="45" alt="Ir al correo corporativo">
    </a>
  </div>
</nav>
