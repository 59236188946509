<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <p class="roboto-center18">Facturación Electrónica</p>
    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
      <div class="container ">
        <div class="row justify-content-center ">
          <div class="col-xl-8 ">
            <img src="../../../../src/assets/img/blog/img1.jpg"
                 class="img-fluid" alt="Responsive Image"
                 width="880" height="400" />
            <br>  <br>
          </div>
          <br><br>
          <div class="col-xl-8 ">
            <p class="text-justify font-roboto ">
              La facturación electrónica ha sido un tema muy discutido recientemente, especialmente durante el último año.
              Esto se debe a los pronunciamientos de la DIAN, que a principios de 2019 anunció nuevas medidas para la aplicación de la
              facturación electrónica. Al mismo tiempo, varias empresas propusieron ideas para una facturación electrónica estandarizada.
              <br><br>
              Los partidarios de una factura electrónica normalizada sostienen que muchos de los problemas que causaron la quiebra de
              varias empresas que habían contratado nuevos proveedores de servicios contractuales se están resolviendo gracias a las ventajas
              tecnológicas que ofrece un sistema normalizado. Por lo tanto, debería ser bastante sencillo aplicar este sistema en muchos ámbitos
              del sector público, desde la asistencia sanitaria hasta la educación.
              <br>    <br>

              En Colombia, se busca que las Pymes comiencen a aplicar cambios en sus procesos administrativos y contables, implementando
              la facturación electrónica de forma gradual en los próximos años. De acuerdo al calendario publicado por la DIAN,
              los sujetos obligados a facturar electrónicamente se agrupan según su código CIIU y cuentan con una fecha máxima para iniciar
              el registro en el servicio informático de factura, al igual que con una fecha tope para iniciar a expedir facturas
              electrónicas de venta.
              <br><br>
              Parte del proceso de registro para poder facturar electrónicamente incluye señalar ante la página web de la DIAN
              cuál será el software que se utilizará para expedir facturas electrónicas.

            </p>
          </div>
        </div>
        <br> 
        <div class="row justify-content-center ">
          <div class="col-xl-4">
            <p class="text-justify font-roboto ">
              Las empresas con actividad principal 4923 deben empezar a facturar electrónicamente el 01 de Noviembre.
              Empresas con actividad económica Transporte de carga por carretera y código CIIU 4923. Es aquel destinado a
              satisfacer las necesidades generales de movilización de cosas de un lugar a otro en vehículos automotores a
              cambio de una remuneración o precio, bajo la responsabilidad de una empresa de transporte legalmente constituida
              y debidamente habilitada en esta modalidad.
            </p>
          </div>
          <div class="col-xl-4">
            <img src="../../../../src/assets/img/blog/img1-post1.jpg"
                 class="img-fluid" alt="Responsive Image"
                 width="310" height="310" />
            <br>  
          </div>
        </div>
        <br>
        <div class="row justify-content-center ">
            <div class="col-xl-8 ">
              <p class="text-justify font-roboto ">
                Recordemos que la función del código CIIU es organizar y clasificar a las empresas por actividades económicas,
                para poder llevar un control de todas las actividades que se realizan en el mercado laboral. Para Colombia,
                se encuentra adaptada la versión 4, que reúne todas las actividades que se realizan el país.
                <br>
                Por último, es importante que te mantengas informado acerca de todos los cambios y actualizaciones
                que presenta la DIAN. No olvides que este es un proceso que aún está en desarrollo y que va a llevarse de forma paulatina.
                <br>    <br>
                También la DIAN publicó el día 14 de octubre del 2020, un taller práctico  de facturación electrónica ingresa en el siguiente link :
                <a href="https://www.facebook.com/152427691450232/posts/5151603224865962/" target="_blank" style="color:blue"> https://www.facebook.com/152427691450232/posts/5151603224865962/.</a>

              </p>
          </div>
        </div>
        <br> <br>
        <div class="container">
          <div class="row justify-content-center ">
            <div class="col-xl-8">
              <table>
                <tr>
                  <td width="12px"></td>
                  <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
                  <td width="8px"></td>
                  <td>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                      <img src=".../../../../src/assets/img/blog/facemouse.png"
                           width="35" height="35"
                           onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                           onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                    </a>
                    <a href="https://twitter.com/?status=Me gusta esta página https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                      <img src=".../../../../src/assets/img/blog/twitermouse.png"
                           width="35" height="35"
                           onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                           onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                    </a>
                    <a href="https://www.linkedin.com/shareArticle?url=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                      <img src=".../../../../src/assets/img/blog/inmouse.png"
                           width="35" height="35"
                           onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                           onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                    </a>
                    <a href="http://pinterest.com/pin/create/button/?url=https://web.syscom.com.co/post1?fbclid=IwAR2rD9SocNuuWmP7SVemtJb7Hi727wemttJiiwGtv2AyYRVCguAKM9V9F70" target="_blank">
                      <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                           width="35" height="35"
                           onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                           onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br><br><br><br>
        </div>
      </div>
      </div>
 
<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe class="responsive-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
