import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { ClsEmail, TipoMensaje, ClsAlert } from 'src/app/Interfaces';
import { ContactoService } from 'src/app/services/contacto.service';


@Component({
  selector: 'app-nuevoticket',
  templateUrl: './nuevoticket.component.html',
  styleUrls: ['./nuevoticket.component.scss']
})
export class NuevoticketComponent implements OnInit {
  public rta: string;
  public VercompAlert: boolean;
  public alertPadre: ClsAlert;
  constructor(private contactoservice: ContactoService,
    @Inject("BASE_URL") baseUrl: string,
  ) { }

  ngOnInit(): void {
    this.VercompAlert = false;
    //this.alertPadre = this.showmessage(TipoMensaje.Danger, "inicio");

  }

  EnviarCorreo2(forma: NgForm, formE: number) {
    this.EnviarCorreo(forma, formE);
  }

  EnviarCorreo(forma: NgForm, formE: number = 0) {
    if (forma.invalid) {
      //alert('invalido');
      Object.values(forma.controls).forEach(control => {
        alert(control.value);
        control.markAllAsTouched();
      });
    }
    else {
      document.getElementById('btnSendP1').setAttribute("disabled", "disabled");
      this.contactoservice.SendEmail(<ClsEmail>forma.value, formE).subscribe(data => {
        this.rta = data;
        if (this.rta != null) {
          if (this.rta.split("___")[0].toLowerCase() == "exito") {
            //Send Mesage
            this.alertPadre = this.showmessage(TipoMensaje.Success, this.rta.split("___")[1]);
            //this.alertcomp.showmessage(TipoMensaje.Success, this.rta.split("___")[1]);
            forma.resetForm();
          }
          else {
            this.alertPadre = this.showmessage(TipoMensaje.Warning, this.rta.split("___")[1]);
            //this.rtaAlert = this.alertcomp.showmessage(TipoMensaje.Warning, this.rta.split("___")[1]);
          }
          this.VercompAlert = true;
          setInterval(() => {
            this.VercompAlert = false;
          }, 10000);
        }
      });
      document.getElementById('btnSendP1').removeAttribute("disabled");
    }
  }

  private showmessage(agtipoalert: TipoMensaje, agmensaje: string): ClsAlert {
    let _alert: ClsAlert;
    switch (agtipoalert) {
      case agtipoalert = 0:
        _alert = { message: agmensaje, title: "Información:", type: "info" };
        break;
      case agtipoalert = 1:
        _alert = { message: agmensaje, title: "Advertencia!", type: "warning" };
        break;
      case agtipoalert = 2:
        _alert = { message: agmensaje, title: "Éxitoso!", type: "success" };
        break;
      case agtipoalert = 3:
        _alert = { message: agmensaje, title: "Error!", type: "danger" };
        break;
      case agtipoalert = 4:
        _alert = { message: agmensaje, title: "Pregunta?", type: "primary" };
        break;
      case agtipoalert = 5:
        _alert = { message: agmensaje, title: "Mensaje:", type: "secondary" };
        break;
      case agtipoalert = 6:
        _alert = { message: agmensaje, title: "Mensaje:", type: "light" };
        break;
      case agtipoalert = 7:
        _alert = { message: agmensaje, title: "Mensaje:", type: "dark" };
        break;
      default:
        _alert = { message: "no ha seleccionado un tipo de mensaje correcto", title: "Mensaje:", type: "dark" };
        break;
    }
    return _alert;
  }
}
