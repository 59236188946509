<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
        <head>
      
          <title>Titulo de Página. 60-70 caracteres como maximo</title>
          <meta name="description" content="Descripcion de pagina. No superar los 155 caracteres." />
                
          <!-- Schema.org markup for Google+ -->
         
          <!-- Open Graph data -->
          <meta property="og:title" content="Titulo" />
          <meta property="og:type" content="syscom blogsyscom blogsyscom blogsyscom blogsyscom blogsyscom blog" />
          <meta property="og:url" content=" https://web.syscom.com.co/post2" />
          <meta property="og:image" content=" https://web.syscom.com.co/src/assets/img/blog/img2.jpg" />
          <meta property="og:description" content="blogsyscomblogsyscomblogsyscomblogsyscom" />
          <meta property="og:site_name" content="blogsyscom" />
          <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" />
          <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" />
          <meta property="article:section" content="blogsyscomblogsyscomblogsyscomblogsyscomblogsyscom" />
          <meta property="article:tag" content="Article Tag" />
          <meta property="fb:admins" content="ID de Facebook " />

        </head>
        <div class="container">
          <div class="row justify-content-center ">
            <div class="col-xl-8 ">
              <p class="roboto-center18">Mejora con curso para llevar la facturación electrónica de una empresa desde la nube</p>

            </div>
          </div>
          <br><br>
          <!-- contenido pos -->
          
              <div class="container ">
                <div class="row justify-content-center ">
                  <div class="col-xl-8 ">
                    <img src="../../../../src/assets/img/blog/img2.jpg"
                         class="img-fluid" alt="Responsive Image"
                         width="880" height="400" />
                    <br>  <br>
                  </div>
                  <br><br>
                      <div class="col-xl-8 ">
                        <p class="text-justify font-roboto ">
                          Hoy en día, hay más personas necesitan obtener conocimientos en diversas disciplinas a su área de trabajo o no.
                          Esto se debe a que existen distintas plataformas digitales que ponen recursos informativos o de aprendizaje al
                          alcance de nuestras manos de manera gratuita.
                          <br><br>
                          En este caso, SYSCOM en línea a desarrollado el curso Syscom Autofactura. Un curso donde aprenderás a manejar de
                          manera profesional nuestra solución en la nube. Le permite generar su factura electrónica.
                          <br><br>
                          Creemos que esta es una buena opción para comenzar, ya que solo necesitas invertir de tu tiempo.
                          <br><br>
                          A continuación, dejamos el primer capitulo del tutorial completo:


                          <a href=" https://bit.ly/352luFc" style="color:blue"> https://bit.ly/352luFc </a>
                          <br><br>

                      </div>
                </div>
                <div class="row justify-content-center ">
                  <div class="col-xl-6">
                    <p align="center"><iframe class="responsive-iframe"  width="600" height="337" src="https://www.youtube.com/embed/Hc__27ggNwQ?list=PLqL6SDnjCgr48ut0iMX76YCNSx-n8ozpV" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

                  </div>

                </div>
                <br> <br>

                <div class="container">
                  <div class="row justify-content-center ">
                    <div class="col-xl-8">
                      <table>
                        <tr>
                          <td width="12px"></td>
                          <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
                          <td width="8px"></td>
                          <td>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://web.syscom.com.co/post2?fbclid=IwAR1yhhCBREzOH-jvxMiJ_Rn0x6IrO2XxnqMn4rT-zzpvlWX_fs1z3b9lH88" target="_blank">
                              <img src=".../../../../src/assets/img/blog/facemouse.png"
                                   width="35" height="35"
                                   onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                                   onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                            </a>
                            <a href="https://twitter.com/?status=Me gusta esta página https://web.syscom.com.co/post2?fbclid=IwAR1yhhCBREzOH-jvxMiJ_Rn0x6IrO2XxnqMn4rT-zzpvlWX_fs1z3b9lH88" target="_blank">
                              <img src=".../../../../src/assets/img/blog/twitermouse.png"
                                   width="35" height="35"
                                   onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                                   onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                            </a>
                            <a href="https://www.linkedin.com/shareArticle?url=https://web.syscom.com.co/post2?fbclid=IwAR1yhhCBREzOH-jvxMiJ_Rn0x6IrO2XxnqMn4rT-zzpvlWX_fs1z3b9lH88" target="_blank">
                              <img src=".../../../../src/assets/img/blog/inmouse.png"
                                   width="35" height="35"
                                   onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                                   onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                            </a>
                            <a href="http://pinterest.com/pin/create/button/?url=https://web.syscom.com.co/post2?fbclid=IwAR1yhhCBREzOH-jvxMiJ_Rn0x6IrO2XxnqMn4rT-zzpvlWX_fs1z3b9lH88" target="_blank">
                              <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                                   width="35" height="35"
                                   onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                                   onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <br><br><br><br>
                </div>
              </div>
        </div>
            <!-- footer -->
            <footer>
              <div class="container-fluid">
                <div class=" mb-2  navbar-light  nav">
                  <div align="center" class="col-xl-4">
                    <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
                  </div>
                  <div class="col-xl-5">
                    <div id="outerdiv">
                      <iframe class="responsive-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                              frameborder="0" marginheight="0" marginwidth="0">
                        Cargando…
                      </iframe>
                    </div>
                  </div>
                  <!-- SnapWidget -->
                  <iframe src="" scrolling="no" width="320" height="680"
                          frameborder="0" marginheight="0" marginwidth="0">
                    Cargando…
                  </iframe>
                </div>
              </div>
            </footer>
