<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/facturaelect']">
          <img src="../../../../src/assets/img/productos/logosmenu/activosfijosm.png" width="170" height="50" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/activosfijos#caracteristicas" title="Ir a caracteristicas"> Características</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/activosfijos/img1.png" class="img-fluid" alt="Responsive Image" width="776" height="386">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-4 ">
      <p class="text-justify roboto">
        El módulo de Activos fijos le permitirá la administración total de los activos de la compañía y la automatización de la depreciación de los mismos, según una estricta y confiable parametrización. Para ello con una hoja de vida detallada de los activos identificando acaracterísticas como: clasificación de activos, datos de compra, asignación de áreas y responsables, método de depreciación aplicado, vida útil correspondiente al mismo, entre otras.
      </p>
    </div>
    <div class="col-xl-5 ">
      <img src=".../../../../src/assets/img/productos/activosfijos/img2.png" class="img-fluid" alt="Responsive Image" width="450" height="393">
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br><br><br><br><br><br>
<!--viñetas dos columnas-->

<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-8">
      <p class="text-justify roboto"> <font class="font-weight-bold">Activos fijos</font> cuenta con distintos procesos relacionados con el manejo de activos, entrega una contabilidad transparente y automática, informes estadísticos y gestión y entre lo mas importante; brinda total seguridad y confiabilidad en la información.</p>
    </div>
  </div>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li><p class="text-justify roboto">Hoja de vida de activos</p></li>
        <li><p class="text-justify roboto">Control de garantias</p></li>
        <li><p class="text-justify roboto">Valorizacion y disminucion de activos</p></li>
        <li><p class="text-justify roboto">Comprobante mensual de depreciacion</p></li>
        <li><p class="text-justify roboto">Hoja de vida de diferidos</p></li>
        <li><p class="text-justify roboto">Valorizacion y disminucion de diferidos</p></li>
      </ul>
    </div>
    <div class="col-xl-5">
      <ul>
        <li><p class="text-justify roboto">Comprobante mensual de amortizacion</p></li>
        <li><p class="text-justify roboto">Resumen de movimiento activos y diferidos</p></li>
        <li><p class="text-justify roboto">Listado de componentes,partes y gastos</p></li>
        <li><p class="text-justify roboto">Contabilidad actual y contabilidad NIIF</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!-- seccion dark  -->
<div id="content-black">
  <div class=" container col-sm-7">
    <br />
    <p id="roboto-blue-dark"> Aprovecha la experiencia de SYSCOM SA, una compañía de tecnología reconocida con excelente respaldo y solidez, para optimizar los procesos de tu negocio.</p>
    <br />
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="font-weight-bold" id="roboto-center18">¿Tienes alguna pregunta?</p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br>
      <img src=".../../../../src/assets/img/productos/activosfijos/img3.png"
           class="img-fluid" alt="Responsive Image"
           width="577" height="293" />
    </div>
  </div>
</div>
<br>
<br>
<br>
