<app-navbar></app-navbar>
<br><br><br><br>
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src="/src/assets/img/productos/construccion.png" class="img-fluid">
    </div>
  </div>
</div>
<br><br><br>
