<app-navbar></app-navbar>
<nav class="navbar  sticky-top navbar-expand-lg navbar-light bg-light zindex-syscom">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/contabilidad']">
          <img src="../../../../src/assets/img/productos/logosmenu/contabilidadm.png" width="180" height="48" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
           <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/contabilidad#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/contabilidad/img1.png" class="img-fluid" alt="Responsive Image" width="800 " height="397">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        La aplicación <font class="font-weight-bold">Contabilidad</font> se constituye
        como la base del sistema de
        información financiera para su empresa,
        le permite el manejo de las
        transacciones contables, generación de
        informes, auxiliares, balances, libros
        oficiales y cierre anual del periodo fiscal,
        facilitando la obtención de información
        contable por la gerencia, el contador o
        los usuarios autorizados para ello, con la
        alta consistencia y confiabilidad.
      </p>
    </div>

    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/contabilidad/img2.png" class="img-fluid" alt="Responsive Image" width="503 " height="385">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>  <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark"> Genera todos los informes requeridos anualmente por la DIAN; generando directamente el archivo XML para cada uno de formatos, con los campos de cada anexo, concepto y terceros.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li><p class="roboto text-justify">Manejo del PUC con códigos contables.</p></li>
        <li><p class="roboto text-justify">Actualización hasta 16 dígitos.</p></li>
        <li><p class="roboto text-justify">Presenta gráficos comparativos entrecuentas para cualquier año.</p></li>
        <li><p class="roboto text-justify">Elabora todos los estados financieros encualquier momento.</p></li>
        <li><p class="roboto text-justify">Permite múltiples formatos de impresión.</p></li>
        <li><p class="roboto text-justify">Genera listados oficiales. Informes gerenciales y tributarios.</p></li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li><p class="roboto text-justify">Certificados de Retención en la Fuente, Reteiva, ICA.</p></li>
        <li><p class="roboto text-justify">Informe estadístico de ingreso y venta por mes comparativo.</p></li>
        <li><p class="roboto text-justify">Acompañamiento del módulo de información exógena DIAN.</p></li>
        <li><p class="roboto text-justify">Manejo de diferentes niveles de seguridad.</p></li>
      </ul>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">¿Tiene alguna pregunta sobre cómo la aplicación <font class="font-weight-bold">Contabilidad </font>puede ayudar a su negocio?</p>
      <p class="text-center roboto">Envíenos un correo electrónico y nos pondremos en contacto en breve.</p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br><br>
      <img src=".../../../../src/assets/img/productos/contabilidad/img3.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="253" />
    </div>
  </div>
</div>
<br>
<br>
<br>
