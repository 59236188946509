<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/plantas']">
          <img src="../../../../src/assets/img/productos/logosmenu/plantasm.png" width="250" height="49" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/plantas#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/plantas#caracteristicas" title="Ir a funcionalidad"> Características</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/plantas/img1.png" class="img-fluid" alt="Responsive Image" width="800 " height="397">
    </div>
  </div>
</div>
<br><br><br><br><br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        La aplicación <font class="font-weight-bold">Administración de Plantas  </font> le permite gestionar
        el funcionamiento de su estación o planta de forma ágil, eficiente y segura. <br> <br>Permite el práctico manejo
        de Kardex de inventarios, compras y ventas, contabilidad automatizada y costeo al día de todos sus productos
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/plantas/img2.png" class="img-fluid" alt="Responsive Image" width="368" height="341">
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br /><br /><br /><br />
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br /><br><br>
<!--texto e imagen 2-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/plantas/img3.png" class="img-fluid" alt="Responsive Image" width="361" height="340">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Controle todas sus unidades de negocio desde el mismo aplicativo, iniciando con la
        compra hasta la venta de combustible y demás productos de inventario ó servicios.<br><br>
        Tenemos interface con TMU6, software que controla el brazo del llenadero y permite
        hacer y gestionar las mezclas de las gasolinas. Examine el inventario de tanques,
        gestión de servitecas y negocio comerciales (Lavaderos y Minimarket) dentro de la estación,
        integrando todo esto en línea a contabilidad syscom.
      </p>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br />
  <div class=" container col-sm-7">
    <p id="roboto-blue-dark"> Nuestro sistema se ajusta ala normatividad colombiana actualizada del Ministerio de minas y energía.</p>
  </div>
  <br />
</div>
<div id="funcionalidad"></div>
<br><br><br><br><br>
<!-- separador negro -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br><br><br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <ul>
        <li>
          <p class="roboto text-justify">
            Nuestro sistema se ajusta a la
            normatividad colombiana actualizada del
            Ministerio de minas y energía.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Permite obtener información sobre el
            movimiento del producto y saldos
            actuales por tanque, bodega, tienda o
            sección de inventarios de su compañía..
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Permite realizar el proceso de calibración
            de surtidores de forma fácil y segura.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Registra el movimiento diario de su
            Estación (Planilla de tanques), en la que
            se registran las ventas de combustible
            realizadas por cada isla, surtidor
            /dispensador y manguera de la eds.
          </p>
        </li>
      </ul>
    </div>
    <div class="col-xl-1">
      <br>
    </div>
    <div class="col-xl-5 ">
      <ul>
        <li>
          <p class="roboto text-justify">
            Se puede discriminar el valor del
            costo del producto (Diesel, Corriente,
            Extra) y el valor de los impuestos;
            tanto en la compra, como en la venta.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Si cuenta con surtidores controlados
            por sistemas electrónicos (Insepet
            (Evolution, NSX, Skype), Speed
            Solutions, Grp, Ingeniería Apropiada,
            Gasolutions, Orion, Servipunto,
            Petrobox u otros).
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Puede importar la lectura de los
            cortes reportados por este sistema,
            con el fin de realizar la planilla
            automáticamente, minimizando
            tiempo y márgenes de error
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br><br><br><br><br>
<!-- seccion dark  -->
<div id="content-black">
  <br />
  <div class=" container col-sm-6">
    <p id="roboto-blue-dark"> Desarrollado 100% con herramientas bajo ambiente Windows, manejo de ambiente gráfico Y Exporta sus informes por correo electrónico.</p>
  </div>
  <br />
</div>
<br /><br /><br />
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-6 align-self-center text-center">
      <p id="roboto-center18">
        ¿Tiene alguna pregunta sobre cómo la aplicación <font class="font-weight-bold">Administración de Plantas </font>puede ayudar a su negocio?
      </p>
      <p class="text-center roboto">Envíenos un correo electrónico y nospondremos en contacto en breve</p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br>
      <img src=".../../../../src/assets/img/productos/plantas/img4.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="253" />
    </div>
  </div>
</div>
<br>
<br>
<br>
