<app-navhelpdesk></app-navhelpdesk>



  <!--seccion 1-->
  <div class="nav" style="background-color: #F4F4F4;">
    <div class="container" style="background-color: #FFF;">
      <div class="container">
        <form class="row gx-3 gy-2 align-items-center">
          <div class="col-sm-3">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
              <label class="form-check-label" for="autoSizingCheck2">
                Seleccionar todo
              </label>
            </div>
          </div>
          <div class="col-sm-3">
            <input class="form-empresa me-2" type="search" placeholder="Search" aria-label="Search">
          </div>
          <div class="col-sm-3">
            <img src="../../../../src/assets/img/helpdesk/ticket/exportar.png" width="90" height="27" class="img-fluid">
          </div>
          <div class="col-auto">
            <p>9 de 26</p>
          </div>
          <div class="col-auto">     
            <img src="../../../../src/assets/img/helpdesk/ticket/flecha1.png" width="80" height="10" class="img-fluid">
          </div>
        </form>
      </div>
    </div>
    <!--seccion 2-->
    <div class="container">
      <div class="row justify-content-center h-100">
        <div class="col-sm-7 align-self-center text-center" style="background-color: #F4F4F4;">
         
        </div>
        <div class="col-sm-1 align-self-center text-center" style="background-color: #F4F4F4;"> </div>
        <div class="col-sm-4 align-self-center text-center" style="background-color: #FFF;">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center h-100">
        <div class="divscroll col-sm-8 align-self-center text-center" style="background-color: #FFF;">
          <div class="container">
            <hr>
            <div class="row">
              <div class="col-md-5 text-justify">
                <img src="../../../../src/assets/img/helpdesk/Nav/Empresas.png" width="45" height="36" class="img-fluid">
                Empresas
              </div>
              <div class="col-md-2">
                Contactos
              </div>
              <div class="col-md-2">
                Sucursales
              </div>
              <div class="col-lg-3">
                Servicios contratados
              </div>
            </div>
            <hr>
            <a routerLink="/sucursales">
              <div class="row">
                <div class="col-md-5 text-justify">
                  <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
                  Deposito de Materiales Silva
                </div>
                <div class="col-md-2">
                  5
                </div>
                <div class="col-md-2">
                  2
                </div>
                <div class="col-lg-3">
                  2
                </div>
              </div>
            </a>
            <hr>
            <a routerLink="/sucursales">
              <div class="row">
                <div class="col-md-5 text-justify ">
                  <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
                  Alferautos
                </div>
                <div class="col-md-2">
                  3
                </div>
                <div class="col-md-2">
                  4
                </div>
                <div class="col-lg-3">
                  4
                </div>
              </div>
            </a>
            <hr>
            <a routerLink="/sucursales">
              <div class="row">
                <div class="col-md-5 text-justify ">
                  <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
                  Transportes BRIO S.A
                </div>
                <div class="col-md-2">
                  5
                </div>
                <div class="col-md-2">
                  2
                </div>
                <div class="col-lg-3">
                  1
                </div>
              </div>
              </a>
              <hr>
          </div>
        </div>
        <div class="col-sm-4  text-center" style="background-color: #F4F4F4;">
          <p id="roboto-help31">
            Filtro
          </p>
          <p id="roboto-help32">
            Creado
          </p>
          <select class="form-select" aria-label="Default select example">
            <option selected>Seleccione una opción</option>
            <option value="1">Hoy</option>
            <option value="2">Ayer</option>
            <option value="3">Hace una semana</option>
            <option value="3">Hace un mes</option>
          </select>
          <br><br>

          <p id="roboto-help32">Empresa</p>

          <select class="form-select" aria-label="Default select example">
            <option selected>Seleccione una opción</option>
            <option value="1">Trasteos Santa Maria</option>
            <option value="2">Trans Piedecuesta</option>
            <option value="3">Trans cargas SA</option>
          </select>   
          <br><br>
          <button routerLink="/verticket" class="btn btn-helpdesk2" type="submit" title="Crear el Ticket">Aplicar</button>
        </div>
      </div>
    </div>

  </div>


