<!-- footer -->
<footer>
  
  <div class=" mb-2  navbar-light  nav" style="background-color: #F4F4F4;">

    <br><br>   <br><br>   <br><br>   <br><br>
    <div class="container center-block">
      <br><br>   <br><br>
      <div class="row justify-content-center  ">
        <div align="center" class="col-xl-3 "><br><br><img src="../../../../src/assets/img/foot-1.png " width="170" height="156" class="img-fluid " alt="... "><br><br></div>
        <div class="col-xl-3 ">
          <div><a class="footer1" (click)="scrollTop()" [routerLink]="['productos']"> Productos</a> </div>
          <div><a class="footer2" (click)="scrollTop()" [routerLink]="['systotalcomercial']"> Syscom Total Comercial</a> </div>
          <div><a class="footer2" (click)="scrollTop()" [routerLink]="['transespecial']"> Gesti&oacute;n Transportes Especiales</a></div>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['activosfijos']"> Activos Fijos</a></div>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['busurbano']"> Buses Urbanos</a></div>
          <div><a class="footer2" (click)="scrollTop()" [routerLink]="['transcarga']"> Camiones</a></div>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['transcarga']"> Gesti&oacute;n Transporte de Carga</a></div>
        </div>
        <div class="col-xl-3">
          <a class="footer1" (click)="scrollTop()" [routerLink]="['compania']"> Compa&ntilde;&iacute;a</a>
          <div><a class="footer2" (click)="scrollTop()" [routerLink]="['compania']"> Sobre Nosostros</a> </div>
          <div><a class="footer2" (click)="scrollTop()" [routerLink]="['historia']"> Historia</a></div>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['trabajemos']"> Trabaja con nosotros</a></div>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['/blog']" target="_blank"> Nuestro Blog </a></div>
        </div>
        <div class="col-xl-3 ">
          <a class="footer1" (click)="scrollTop()" [routerLink]="['contacto']"> Contacta con nosotros</a>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td><a href="https://www.facebook.com/Syscom-Sistemas-Comerciales-SA-100343868410571"
                       target="_blank"><img src="../../../../src/assets/img/face.png "
                                            width="40" height="40" class="responsive "></a></td>
                <td><a href="https://www.instagram.com/empsyscomsabuc/?igshid=14wyzw8775baj"
                       target="_blank"><img src="../../../../src/assets/img/insta.png "
                                            width="40" height="40" class="responsive"></a></td>
                <!--<td><img src="../../../../src/assets/img/tw.png " width="40" height="40" class="responsive "> </td>-->
                <td><a href=" https://www.youtube.com/channel/UC1Z6WblbXPD0KO_tUPbDBcQ/"
                       target="_blank"><img src="../../../../src/assets/img/you.png "
                                            width="40" height="40" class="responsive "></a> </td>
                <td><a href="https://www.linkedin.com/company/syscom-sistemas-comerciales/" target="_blank"> <img src="../../../../src/assets/img/in.png " width="40" height="40" class="responsive " > </a></td>
              </tr>
            </tbody>
          </table>
          <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['terminos']"> Preguntas Frecuentes</a></div>
          <div> <a  href=" https://www.youtube.com/channel/UC1Z6WblbXPD0KO_tUPbDBcQ/"  class="footer2" target="_blank">  SYSCOM en l&iacute;nea</a></div>
        </div>
        <div class="col-xl-3 "></div>
        <div class="col-xl-3 ">
          <div> <a class="footer3" (click)="scrollTop()" [routerLink]="['productos']"> Ver todos los productos</a></div>
        </div>
        <div class="col-xl-3 ">
          <div> <a class="footer3" (click)="scrollTop()" [routerLink]="['terminos']"> Clientes</a></div>
        </div>
        <div class="col-xl-3 ">
          <div> <a class="footer3" (click)="scrollTop()" [routerLink]="['contacto']"> Cont&aacute;ctanos</a></div>
        </div>
        <br><br><br>
        <div class="col-xl-2 "></div>
        <div class="col-xl-2 ">
          <div>
            <a class="footer4"> </a>
          </div>
        </div>
        <div class="col-xl-2 ">
          <div> <a class="footer4" href="https://api.whatsapp.com/send?phone=573176435107&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Bucaramanga"> Bucaramanga -  317 643 5107 </a></div>
        </div>
        <div class="col-xl-2 ">
          <div> <a class="footer4" href="https://api.whatsapp.com/send?phone=573164673519&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Bogotá"> Bogot&aacute; - 316 467 3519</a></div>
        </div>
        <div class="col-xl-2 ">
          <div> <a class="footer4" href="https://api.whatsapp.com/send?phone=573174408886&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con Barranquilla"> Barranquilla - 317 440 8886</a></div>
        </div>
        <div class="col-xl-2 ">
          <div> <a class="footer4" href="https://api.whatsapp.com/send?phone=573187165151&text=Hola!%20Quiero%20hacer%20una%20pregunta%20acerca%20%20de:" target="_blank" title="Chatear por Whatsapp con medellín"> Medell&iacute;n - 318 716 5151</a></div>
        </div>
      </div>
    </div>
    <div class="dark-div"></div>
    <div class="col-xl-2  "></div>
    <div class="col-xl-4 ">
      <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['politica']"> Pol&iacute;tica de privacidad</a></div>
    </div>
    <div class="col-xl-3 ">
      <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['terminos']"> T&eacute;rminos</a></div>
    </div>
    <div class="col-xl-3 ">
      <div> <a class="footer2" (click)="scrollTop()" [routerLink]="['copy']"> Copyright &copy; {{DateTimeNow}} SYSCOM S.A.</a></div>
    </div>
    <div><img src="../../../../src/assets/img/barra.png " width="1750" height="42" class="img-fluid " alt="... "></div>
  </div>
</footer>
