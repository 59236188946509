<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F4F4F4;">
  <!--seccion 2-->
  <div class="container">
    <div class="row justify-content-center h-100 alingtop">
      <div class="col-sm-8 align-bottom text-center" style="background-color: #FFF;">
        <div class="row justify-content-end h-100 ">
          <div class="col-sm-3 align-bottom text-justify">
            <img src="../../../../src/assets/img/helpdesk/empresa/contacto.png" width="170" height="170" class="img-fluid">
          </div>
          <div class="col-sm-4 align-bottom text-center">
            <p id="roboto-help31">
              Alejandro Cortez
            </p>
            <p id="roboto-help22">
              info@transcarga.com
            </p>
            <p id="roboto-help21">
              Empresa:<font id="roboto-help32">TRANSCARGA JVC</font>
            </p>
          </div>
          <div class="col-sm-2 align-bottom">
            <br>
          </div>
          <div class="col-sm-3 align-bottom">
            <br><br><br><br><br>
            <img src="../../../../src/assets/img/helpdesk/empresa/editar.png" width="80" height="20" class="img-fluid">
            <img src="../../../../src/assets/img/helpdesk/empresa/eliminar.png" width="80" height="20" class="img-fluid">
          </div> 
        </div>
      </div>
      <div class="col-sm-4 align-self-center text-center" style="background-color: #FFF;">
        <div class=" h-100">
          <p id="roboto-help31">Datos</p>
          <p id="roboto-help31">Razon Social</p>
          TRANSPORTE DE CARGA JVC
          <p id="roboto-help31">Cargo</p>
          Asesor
          <p id="roboto-help31">teléfono</p>
          318 524 6689
          <p id="roboto-help31">Dirección</p>
          Calle 123d #12-02 Fontibón
          <p id="roboto-help31">Ciudad</p>
          Bogotá
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-sm-8 align-self-center text-center" style="background-color: #FFF;">
        <p class="col-sm-12 text-justify" style="background-color:#F4F4F4;">
          TICKETS
        </p><div class="container">
  <!--Ticket1-->
  <hr>
  <a routerLink="/verticket">
    <div class="row">

      <table>
        <tr>
          <td rowspan="3">
            <img src="../../../../src/assets/img/helpdesk/Nav/tickets.png" width="80" height="64" class="img-fluid">
          </td>
          <td>
            <p class="bg-dark text-white text-table1">cerrado</p>
          </td>
        </tr>
        <tr>
          <td>
            <p id="text-table2">
              10025 syscom 20 Estaciones de Servicio
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p id="text-table3">
              el agente respondio hace 2 dias -Ticket cerrado hace 2 dias
            </p>
          </td>
        </tr>
      </table>
    </div>
    </a>
    <!--Ticket2-->
    <hr>
    <a routerLink="/verticket">
      <div class="row">

        <table>
          <tr>
            <td rowspan="3">
              <img src="../../../../src/assets/img/helpdesk/Nav/tickets.png" width="80" height="64" class="img-fluid">
            </td>
            <td>

              <p class="bg-warning text-white text-table1">En espera de revsion</p>
            </td>
          </tr>
          <tr>
            <td>
              <p id="text-table2">
                10025 syscom 20 Estaciones de Servicio
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p id="text-table3">
                el agente respondio hace 2 dias -Ticket cerrado hace 2 dias
              </p>
            </td>
          </tr>
        </table>
      </div>
    </a>
    <!--Ticket3-->
    <hr>
    <a routerLink="/verticket">
      <div class="row">
        <table>
          <tr>
            <td rowspan="3">
              <img src="../../../../src/assets/img/helpdesk/Nav/tickets.png" width="80" height="64" class="img-fluid">
            </td>
            <td>
              <p class="bg-primary text-white text-table1">En curso</p>
            </td>
          </tr>
          <tr>
            <td>
              <p id="text-table2">
                10025 syscom 20 Estaciones de Servicio
                <!--Ticket1-->
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p id="text-table3">
                el agente respondio hace 2 dias -Ticket cerrado hace 2 dias
              </p>
            </td>
          </tr>
        </table>
      </div>
    </a>
    <hr>
</div>
       </div>
      <div class="col-sm-4  text-center" style="background-color: #FFF;">
      </div>
    </div>
  </div>
</div>


