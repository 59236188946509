import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post6',
  templateUrl: './post6.component.html',
  styleUrls: ['./post6.component.scss']
})
export class Post6Component implements OnInit {

  constructor() { }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  ngOnInit(): void {
  }

}
