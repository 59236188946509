<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <p class="roboto-center18">Redes sociales para las empresas </p>
    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-8 ">
        <img src="../../../../src/assets/img/blog/img5.jpg"
             class="img-fluid" alt="Responsive Image"
             width="880" height="400" />
        <br>  <br>
      </div>
      <br><br>
      <div class="col-xl-8 ">
        <p class="text-justify font-roboto ">
          Hoy en día sabemos que las redes sociales para empresas juegan un papel importante en el mundo de los negocios y que pueden ser útiles como estrategia de posicionamiento para generar una comunidad con nuestros clientes y aumentar las ventas de nuestros productos o servicios.
          <br><br>
          Estas redes son una herramienta de gran ayuda a la hora de mejorar los resultados de búsqueda de tu sector. Para los nuevos algoritmos de Google, tu página web se verá mejor posicionada si también tiene presencia en redes sociales.
          <br><br>
          El uso estratégico de las Redes Sociales o social media puede ayudarte también a mejorar las visitas a tu web, popularizar tus contenidos y mejorar el posicionamiento en buscadores. También te ayudan a crear una comunidad activa en torno a tus servicios y productos y ayudarte a mejorar tus campañas de marketing.
          <br><br>
          Entre las redes sociales más utilizadas en 2020, podemos encontrar las siguientes plataformas:
          <br><br>
          <font class="font-weight-bold">Facebook:</font>  Se utiliza principalmente para relacionarte con el cliente final en negocios B2C. Funciona muy bien para promocionar productos o servicios, aunque siempre hay que hacerlo de manera sutil, ya que el usuario de Facebook no quiere verse avasallado por publicidad.
          <br><br>
          <font class="font-weight-bold">
            Instagram:
          </font>  Al tratarse de la red social que más crecimiento de usuarios está teniendo en los últimos años, apostar por ella es ganar seguro. Instagram consigue una gran interacción con el usuario.
          <br><br>
              <font class="font-weight-bold">
                Twitter:
              </font> Twitter sirve para unos objetivos similares a Facebook, llevar tráfico a la web y promocionar la empresa. Sin embargo, Twitter puede ayudarte también a mejorar la imagen de marca y a saber qué se dice de tu empresa en todo momento.
          <br><br>
              <font class="font-weight-bold">
                Youtube:
              </font> es una herramienta básica para el video-marketing y una de las mejores redes sociales para empresas.   
          <br><br>
              <font class="font-weight-bold">
                Linkedin:
              </font> Es una de las mejores redes sociales para generar contactos profesionales B2B, así como para captar talento de futuros trabajadores de tu empresa o mejorar el posicionamiento de marca.
            <br><br>
            Las redes sociales aumentan el reconocimiento de tu marca: la audiencia se entera quién eres, qué haces y por qué. Es importante que todo esto quede claro con tan solo echarle un vistazo a tu información y foto de perfil en redes. Sé directo y preciso. A partir de lo que muestres, te pueden seguir, contactar o comprar. Mira el perfil de Instagram de la marca de moda Dolores Promesas: directo, cercano, humano.   Sin duda una de las funciones más importantes de las redes sociales es la posibilidad de obtener la opinión directa e inmediata del seguidor, ya que para éste se trata de una forma fácil y rápida de contactar con la empresa. Los propios botones de interacción suponen una respuesta inmediata a nuestras publicaciones, y podremos saber si son interesantes para nuestra comunidad gracias a los comentarios, “Me Gusta”, retuits y veces que se compartan.
            <br><br>
            Antes de abrir perfiles en las redes sociales, piensa que quieres de ellas para tu negocio. Una vez lo tengas claro, piensa si tus contenidos son más visuales o de texto. Si tu temática es generalista o sectorial. Si tu modelo de negocio es B2B o B2C. Si buscas hacer Branding, atención al cliente, posicionarte en buscadores o responder a tus clientes con contenidos originales.
        </p>
      </div>
    </div>
    <br> <br>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-8">
          <table>
            <tr>
              <td width="12px"></td>
              <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
              <td width="8px"></td>
              <td>
                <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/facemouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                </a>
                <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/twitermouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/inmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                </a>
                <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</div>

<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
