<app-navbar></app-navbar>
<nav class="navbar zindex-syscom  sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class=" navbar-brand" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
        <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/facturaelect']">
          <img src="../../../../src/assets/img/productos/logosmenu/facturacionelectm.png" width="245" height="47" class="img-fluid">
        </a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/facturacionelect#caracteristicas" title="Ir a caracteristicas"> Características</a>
          </li>
          <li class="nav-item centrarvertical">
            <a class="nav-link font-weight-bold " href="/facturacionelect#funcionalidad" title="Ir a funcionalidad"> Funcionalidad</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<br>
<br>
<!--imagen centrada-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <img src=".../../../../src/assets/img/productos/facturacion/img1.png" class="img-fluid" alt="Responsive Image" width="813" height="436">
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!--texto e imagen 1-->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        El sistema de <font class="font-weight-bold">Facturación Electrónica</font> permite la expedición,
        recibo, rechazo, conservación y entregade la factura como documento electrónico
        para soportar la transacción de bienes y servicios. Sea justa a las exigencias
        técnicas de la DIAN en cuanto al sistema de validación previa de tal manera que
        sea segura que la información registrada sea verídica y a que previamente la
        factura es verificada y validada por la DIAN.
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/facturacion/img2.png" class="img-fluid" alt="Responsive Image" width="401" height="321">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!-- seccion dark  -->
<div id="content-black">
  <br>
  <div class=" container col-sm-5">
    <p id="roboto-blue-dark"> Nuestro sistema se adapta a la normatividad colombiana vigente de facturación electrónica expedida por la DIAN.</p>
  </div>
  <br>
</div>
<div id="funcionalidad"></div>
<br> <br> <br> <br> <br>
<!--separador azul-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br />
<!--imagen y texto 2-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Funcionalidad</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/facturacion/img3.png" class="img-fluid" alt="Responsive Image" width="380" height="380">
    </div>
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Permite la descarga del xml y pdf de la factura emitida al cliente
        en tiempo real, así como la funcionalidad de reenviar el correo y sus
        archivos adjuntos.<br /><br />
        Permite realizar la gestión de todos los documentos electrónicos del
        nuevo modelo tecnológico implementado por la DIAN
      </p>
    </div>
  </div>
</div>
<div id="caracteristicas"></div>
<br> <br> <br> <br>
<!--separador negro-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_negro.png" width="80" height="8" class="responsive" alt="...">
</div>
<br />
<br> <br> <br>
<!--texto e imagen 3-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Características</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-5">
      <p class="text-justify roboto">
        Gestione el envío, recepción, aceptación y rechazo de las factura selectrónicas
        expedidas a sus clientes de una manera fácil e interactiva. En el sistema se
        podrá descargar el xml y pdf de la factura, así como el reenvío del correo al
        cliente en caso de ser necesario. Se puede hacer el seguimiento de los diferentes
        estados de la factura para generar las respectivas notas débito y crédito
        relacionadas a esta.
      </p>
    </div>
    <div class="col-xl-5">
      <img src=".../../../../src/assets/img/productos/facturacion/img4.png" class="img-fluid" alt="Responsive Image" width="407" height="320">
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!--separador rojo-->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src=".../../../../src/assets/img/sep_rojo.png" width="80" height="8" class="responsive" alt="...">
</div>
<br />
<br> <br>
<!--viñetas dos columnas-->
<div class="container ">
  <p class="font-weight-bold" id="roboto-center18">Nuestra aplicación de Facturación Electrónica es tu complemento perfecto, ya que en el encontrarás</p>
  <br><br>
  <div class="row justify-content-center ">
    <div class="col-xl-6">
      <ul>
        <li>
          <p class="roboto text-justify">
            Es una solución complemente integrada con el software Syscom
            Comercial ofreciendo altos estándares de seguridad e integridad
            de la información.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            En caso de un cambio en la legislación el sistema cuenta con
            soporte técnico y actualizaciones.
          </p>
        </li>
        <li>
          <p class="roboto text-justify">
            Proporciona ahorro de costos en inversiones técnicas y migración
            de datos.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
<br> <br> <br> <br> <br>
<!--contactanos parte-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-7 align-self-center text-center">
      <p id="roboto-center18">
        ¿Tienes Preguntas?
      </p>
      <p class="text-center roboto">Escríbenos y Felizmente las resolvemos</p>
      <span class="input-group-btn">
        <button class="btn btn-syscom" type="button" (click)="scrollTop()" [routerLink]="['/contacto']" target="_top">Contáctanos</button>
      </span>
      <br> <br><br> <br>
      <img src=".../../../../src/assets/img/productos/recursoshum/img5.png"
           class="img-fluid" alt="Responsive Image"
           width="500" height="253" />
    </div>
  </div>
</div>
<br><br><br>
