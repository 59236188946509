<app-navbar></app-navbar>
<br>
<!--titulo centrado-->
<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <p class="Titulos_center">
        Nuestro equipo está listo para ayudar
      </p>
    </div>
  </div>
</div>
<br>
<!-- formulario -->
<!--<div class="container h-100">
  <div class="row justify-content-center h-100">
    <div class="col-sm-8 align-self-center text-center">
      <div class="card shadow">
        <div class="card-body">
          <ul class="nav nav-pills" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><p class="roboto18">Consulta general </p></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><p class="roboto18">¿Necesitas soporte?</p> </a>
            </li>
          </ul>-->
          <!-- Tab panes -->
<!--<div class="tab-content">
  <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <br>
        <p class="roboto18 font-weight-bold">¿Tienes algo en mente que decirnos?</p>
        <form (ngSubmit)="EnviarCorreo(forma)" #forma="ngForm" name="frmAsesores">
          <div class="form-group">
            <input name="nombres" type="text" class="form-control" id="txtNombres" [class.is-invalid]="nombres.invalid && nombres.touched" placeholder="Nombres y apellidos" title="Nombres y apellidos" ngModel required #nombres="ngModel">
            <small *ngIf="nombres.invalid && nombres.touched" class="validacion form-text text-danger">El campo 'Nombres' es obligatorio</small>
          </div>
          <div class="form-group">
            <input name="email" type="email" class="form-control" id="txtEmail" [class.is-invalid]="email.invalid && email.touched" placeholder="Email-correo@dominio.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Email de contacto" ngModel required #email="ngModel">
            <small *ngIf="email.invalid && email.touched" class="validacion form-text text-danger">El campo 'Email' es obligatorio y debe tener un formato de correo</small>
          </div>
          <div class="form-group">
            <input name="telefono" type="tel" class="form-control" id="txtTel" [class.is-invalid]="telefono.invalid && telefono.touched" placeholder="Celular" title="Celular de contacto" required ngModel #telefono="ngModel">
            <small *ngIf="telefono.invalid && telefono.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
          </div>
          <div class="form-group">
            <input name="empresa" type="text" class="form-control" id="txtEmpresa" [class.is-invalid]="empresa.invalid && empresa.touched" placeholder="Empresa" title="Empresa de la cual realiza la solicitud" required ngModel #empresa="ngModel">
            <small *ngIf="empresa.invalid && empresa.touched" class="validacion form-text text-danger">El campo 'Empresa' es obligatorio</small>
          </div>
          <div class="form-group">
            <textarea name="mensaje" class="form-control" id="txtaMensaje" placeholder="Mensaje" rows="3" [class.is-invalid]="mensaje.invalid && mensaje.touched" style="resize:none;" title="Mensaje de interes" required ngModel #mensaje="ngModel"></textarea>
            <small *ngIf="mensaje.invalid && mensaje.touched" class="validacion form-text text-danger">El campo 'Mensaje' es obligatorio</small>
          </div>
          <button id="btnSendP1" class="btn btn-syscom" type="submit" title="Realizar el envío" name="btnEnviar1">Enviar</button>
        </form>
        <div class="mt-3">
          <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
        </div>
      </div>
    </div>
  </div>-->
<!--Formulario soporte y tab-->
<!--<div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <br>
            <p class="roboto18 font-weight-bold">Lo que necesite, el equipo de SYSCOM está aquí para ayudarlo.</p>
            <form (ngSubmit)="EnviarCorreo2(formaa, 1)" #formaa="ngForm" name="frmSoporte">
              <div class="form-group">
                <input name="nombres" type="text" class="form-control" id="txtNombres1" [class.is-invalid]="nombres2.invalid && nombres2.touched" placeholder="Nombres y apellidos" title="Nombres y apellidos" ngModel required #nombres2="ngModel">
                <small *ngIf="nombres2.invalid && nombres2.touched" class="validacion form-text text-danger">El campo 'Nombres' es obligatorio</small>
              </div>
              <div class="form-group">
                <input name="email" type="email" class="form-control" id="txtEmail1" [class.is-invalid]="email2.invalid && email2.touched" placeholder="Email-correo@dominio.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" title="Email de contacto" ngModel required #email2="ngModel">
                <small *ngIf="email2.invalid && email.touched" class="validacion form-text text-danger">El campo 'Email' es obligatorio y debe tener un formato de correo</small>
              </div>
              <div class="form-group">
                <input name="telefono" type="tel" class="form-control" id="txtTel1" [class.is-invalid]="telefono2.invalid && telefono2.touched" placeholder="Celular" title="Celular de contacto" required ngModel #telefono2="ngModel">
                <small *ngIf="telefono2.invalid && telefono.touched" class="validacion form-text text-danger">El campo 'Teléfono' es obligatorio</small>
              </div>
              <div class="form-group">
                <input name="empresa" type="text" class="form-control" id="txtEmpresa1" [class.is-invalid]="empresa2.invalid && empresa2.touched" placeholder="Empresa" title="Empresa de la cual realiza la solicitud" required ngModel #empresa2="ngModel">
                <small *ngIf="empresa2.invalid && empresa2.touched" class="validacion form-text text-danger">El campo 'Empresa' es obligatorio</small>
              </div>
              <div class="form-group">
                <textarea name="mensaje" class="form-control" id="txtaMensaje1" placeholder="Mensaje" rows="3" [class.is-invalid]="mensaje2.invalid && mensaje2.touched" style="resize:none;" title="Mensaje de interes" required ngModel #mensaje2="ngModel" data-envio="1"></textarea>
                <small *ngIf="mensaje2.invalid && mensaje2.touched" class="validacion form-text text-danger">El campo 'Mensaje' es obligatorio</small>
              </div>
              <button id="btnSendP2" class="btn btn-syscom" type="submit" title="Realizar el envío" name="btnEnviar2">Enviar</button>
            </form>
            <div class="mt-3">
              <app-alerts [Alerta]="alertPadre" [VerAlerta]="VercompAlert"></app-alerts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>-->
<!--imagen1 e imagen 2-->
<div class="container h-100">
  <div class="row justify-content-center h-100">

    <div class="col-xl-4">
      <img src="../../../../src/assets/img/contacto/img3.png" class="img-fluid center-block" alt="Responsive Image" width="228" height="321">
      <br /><br />
      <div class="row justify-content-center h-100">
        <div class="col-xl-8">
          <button (click)="scrollTop()" routerLink="/contactogeneral" class="btn btn-outline-secondary center-block" type="submit" title="Realizar el envío" name="general">Contáctanos</button>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <img src="../../../../src/assets/img/contacto/img2.png" class="img-fluid justify-content-lg-center" alt="Responsive Image" width="236" height="335">
      <br /><br />
      <div class="row justify-content-center h-100">
        <div class="col-xl-5">
          <button (click)="scrollTop()" routerLink="/contactosoporte" class="btn btn-outline-secondary center-block" type="submit" title="Realizar el envío" name="soporte">contáctanos</button>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br><br><br>
<!-- contactos -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-2 ">
    </div>
    <div class="col-xl-5 ">
      <p class="text-justify Transportes">Nuestras oficinas</p>
      <br> <br> <br>
    </div>
       <div class=" container">
         <div class="row justify-content-center">
           <div class="col-xl-2 "></div>
           <div class="col-md-4">
             <p class="text-center roboto33">Bucaramanga</p>
             <p class="text-center roboto">Sede Principal</p>
             <p class="text-center roboto">Calle 35 No. 16 - 24 Piso 18</p>
             <p class="text-center roboto">Edif. José Acevedo y Gómez</p>
             <p class="text-center roboto">Cel.:(57) 317 643 5107</p>
           </div>
           <div class="col-md-4">
             <p class="text-center roboto33">Barranquilla</p>
             <p class="text-center roboto">Cra. 53 No. 64 - 72</p>
             <p class="text-center roboto">Oficina 107</p>
             <p class="text-center roboto">Cel.: (57) 317 440 8886</p>
           </div>
           <div class="col-xl-2 "></div>
           <div class="col-xl-12"><br /></div>
           <div class="col-xl-2"></div>
           <div class="col-md-4">
             <p class="text-center roboto33">Bogotá</p>
             <p class="text-center roboto">Cra. 71C No. 53A - 06</p>
             <p class="text-center roboto">Barrio Normandía</p>
             <p class="text-center roboto">Cel: (57) 316 467 3519</p>
           </div>
           <div class="col-md-4">
             <p class="text-center roboto33">Medellín</p>
             <p class="text-center roboto">Cra. 74 No. 48 - Int. 250</p>
             <p class="text-center roboto">C.C. Obelisco</p>
             <p class="text-center roboto">Cel: (57) 318 716 5151</p>
           </div>
           <div class="col-xl-2"></div>
         </div>
      <br> <br> <br> <br>
    </div>
    
    <!-- imagen -->
    <div class="row justify-content-center ">
      
      <div class="col-xl-4">
        <img src="../../../../src/assets/img/contacto/img1.png" width="400" height="171" class="img-fluid" alt="...">
      </div>
      
      <!-- suscribete -->
      <div class="container h-100">
        <div class="row justify-content-center h-100">
          <div class="col-sm-8 align-self-center text-center">
            <p class="Titulos_center ">Suscríbete a nuestra web</p>
            <p class="  text-center roboto ">Puedes recibir en tu correo electrónico las ultimas<br> publicaciones que compartimos en nuestra web.</p>
          </div>
          <div class="col-xl-0.5 ">
          </div>
          <div class="col-xl-5">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Escribe tu email" aria-label="Recipient's username" aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-syscom" type="button" id="button-addon2">Suscribete</button>
              </div>
            </div>
          </div>
        </div>
        <br><br><br>
      </div>
    </div>
  </div>

<br><br><br><br>

<!--</div>-->
