import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { InicioComponent } from './components/inicio/inicio.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CompaniaComponent } from './components/compania/compania.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ContactogeneralComponent } from './components/contactogeneral/contactogeneral.component';
import { ContactosoporteComponent } from './components/contactosoporte/contactosoporte.component';
import { MantvehicularComponent } from './components/productos/mantvehicular/mantvehicular.component';
import { BusurbanoComponent } from './components/productos/busurbano/busurbano.component';
import { ParqueaderoComponent } from './components/productos/parqueadero/parqueadero.component';
import { TranscargaComponent } from './components/productos/transcarga/transcarga.component';
import { TransespecialComponent } from './components/productos/transespecial/transespecial.component';
import { NominarhComponent } from './components/nominarh/nominarh.component';
import { ActivosfijosComponent } from './components/productos/activosfijos/activosfijos.component';
import { ContabilidadComponent } from './components/productos/contabilidad/contabilidad.component';
import { EstacionesComponent } from './components/productos/estaciones/estaciones.component';
import { FacturacioneinventarioComponent } from './components/productos/facturacioneinventario/facturacioneinventario.component';
import { FacturacionelectComponent } from './components/productos/facturacionelect/facturacionelect.component';
import { PlantasComponent } from './components/productos/plantas/plantas.component';
import { SystotalcomercialComponent } from './components/productos/systotalcomercial/systotalcomercial.component';
import { TrabajemosComponent } from './components/compania/trabajemos/trabajemos.component';
import { HistoriaComponent } from './components/compania/historia/historia.component';
import { CopyComponent } from './components/copy/copy.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { PoliticaComponent } from './components/politica/politica.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import {SlidetutorialComponent } from './components/tutorial/slidetutorial/slidetutorial.component';
import { BlogComponent } from './components/blog/blog.component';
import { Post1Component } from './components/blog/post1/post1.component';
import { Post3Component } from './components/blog/post3/post3.component';
import { Post2Component } from './components/blog/post2/post2.component';
import { NgxSocialShareModule } from 'ngx-social-share';
import { Post5Component } from './components/blog/post5/post5.component';
import { Post4Component } from './components/blog/post4/post4.component';

import { HelpdeskComponent } from './components/helpdesk/helpdesk/helpdesk.component';
import { LoginComponent } from './components/helpdesk/login/login.component';
import { NavhelpdeskComponent } from './components/helpdesk/navhelpdesk/navhelpdesk.component';
import { BuscarComponent } from './components/helpdesk/buscar/buscar.component';
import { NuevoticketComponent } from './components/helpdesk/nuevoticket/nuevoticket.component';
import { VerticketComponent } from './components/helpdesk/verticket/verticket.component';
import { TicketsComponent } from './components/helpdesk/tickets/tickets.component';
import { EmpresasComponent } from './components/helpdesk/empresas/empresas.component';
import { CrearempresaComponent } from './components/helpdesk/crearempresa/crearempresa.component';
import { ContactohelpdeskComponent } from './components/helpdesk/contactohelpdesk/contactohelpdesk.component';
import { Post6Component } from './components/blog/post6/post6.component';
import { Post7Component } from './components/blog/post7/post7.component';
import { Post8Component } from './components/blog/post8/post8.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { ContactoshelpComponent } from './components/helpdesk/contactoshelp/contactoshelp.component';
import { CrearcontactoComponent } from './components/helpdesk/crearcontacto/crearcontacto.component';
import { ConocimientoComponent } from './components/helpdesk/conocimiento/conocimiento.component';
import { SucursalesComponent } from './components/helpdesk/sucursales/sucursales.component';
import { CambiarcontrasenaComponent } from './components/helpdesk/cambiarcontrasena/cambiarcontrasena.component';
import { NuevoarticuloComponent } from './components/helpdesk/nuevoarticulo/nuevoarticulo.component';
import { Post9Component } from './components/blog/post9/post9.component';
import { Post10Component } from './components/blog/post10/post10.component';
import { Post11Component } from './components/blog/post11/post11.component';
import { ServiciosComponent } from './components/helpdesk/servicios/servicios.component';


//import { CustomPreloading } from './custom-preloading';

const routes: Routes = [
  { path: '', component: InicioComponent, data: { preload: true } },
  { path: 'compania', component: CompaniaComponent , data: { preload: true } },
  { path: 'productos', component: ProductosComponent, data: { preload: true } },
  { path: 'contacto', component: ContactoComponent, data: { preload: true } },
  { path: 'contactogeneral', component: ContactogeneralComponent, data: { preload: true } },
  { path: 'contactosoporte', component: ContactosoporteComponent, data: { preload: true } },
  { path: 'mantvehicular', component: MantvehicularComponent, data: { preload: true } },
  { path: 'busurbano', component: BusurbanoComponent, data: { preload: true } },
  { path: 'parqueadero', component: ParqueaderoComponent, data: { preload: true } },
  { path: 'transcarga', component: TranscargaComponent, data: { preload: true } },
  { path: 'transespecial', component: TransespecialComponent, data: { preload: true } },
  { path: 'nominarh', component: NominarhComponent, data: { preload: true } },
  { path: 'activosfijos', component: ActivosfijosComponent, data: { preload: true } },
  { path: 'contabilidad', component: ContabilidadComponent, data: { preload: true } },
  { path: 'estaciones', component: EstacionesComponent, data: { preload: true } },
  { path: 'facturacioneinventario', component: FacturacioneinventarioComponent, data: { preload: true } },
  { path: 'facturacionelect', component: FacturacionelectComponent, data: { preload: true } },
  { path: 'plantas', component: PlantasComponent, data: { preload: true } },
  { path: 'systotalcomercial', component: SystotalcomercialComponent, data: { preload: true } },
  { path: 'trabajemos', component: TrabajemosComponent, data: { preload: true } },
  { path: 'historia', component: HistoriaComponent, data: { preload: true } },
  { path: 'copy', component: CopyComponent, data: { preload: true } },
  { path: 'terminos', component: TerminosComponent, data: { preload: true } },
  { path: 'politica', component: PoliticaComponent, data: { preload: true } },
  { path: 'tutorial', component: TutorialComponent, data: { preload: true } },
  { path: 'slidetutorial', component: SlidetutorialComponent, data: { preload: true } },
  { path: 'post1', component: Post1Component, data: { preload: true } },
  { path: 'post2', component: Post2Component, data: { preload: true } },
  { path: 'post3', component: Post3Component, data: { preload: true } },
  { path: 'post4', component: Post4Component, data: { preload: true } },
  { path: 'post5', component: Post5Component, data: { preload: true } },
  { path: 'blog', component: BlogComponent, data: { preload: true } },
  { path: 'helpdesk', component: HelpdeskComponent, data: { preload: true } },
  { path: 'login', component: LoginComponent, data: { preload: true } },
  { path: 'navhelpdesk', component: NavhelpdeskComponent, data: { preload: true } },
  { path: 'buscar', component: BuscarComponent, data: { preload: true } },
  { path: 'nuevoticket', component: NuevoticketComponent, data: { preload: true } },
  { path: 'verticket', component: VerticketComponent, data: { preload: true } },
  { path: 'tickets', component: TicketsComponent, data: { preload: true } },
  { path: 'empresas', component: EmpresasComponent, data: { preload: true } },
  { path: 'crearempresa', component: CrearempresaComponent, data: { preload: true } },
  { path: 'contactohelpdesk', component: ContactohelpdeskComponent, data: { preload: true } },
  { path: 'post6', component: Post6Component, data: { preload: true } },
  { path: 'post7', component: Post7Component, data: { preload: true } },
  { path: 'post8', component: Post8Component, data: { preload: true } },
  { path: 'post9', component: Post9Component, data: { preload: true } },
  { path: 'post10', component: Post10Component, data: { preload: true } },
  { path: 'post11', component: Post11Component, data: { preload: true } },
  { path: 'sitemap', component: SitemapComponent, data: { preload: true } },
  { path: 'contactoshelp', component: ContactoshelpComponent, data: { preload: true } },
  { path: 'crearcontacto', component: CrearcontactoComponent, data: { preload: true } },
  { path: 'conocimiento', component: ConocimientoComponent, data: { preload: true } },
  { path: 'sucursales', component: SucursalesComponent, data: { preload: true } },
  { path: 'cambiarcontrasena', component: CambiarcontrasenaComponent, data: { preload: true } },
  { path: 'nuevoarticulo', component: NuevoarticuloComponent, data: { preload: true } },
  { path: 'servicios', component: ServiciosComponent, data: { preload: true } },
  { path: '**', pathMatch: 'full', redirectTo: '', data: { preload: true } }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), NgxSocialShareModule,],
  exports: [RouterModule]
})
export class AppRoutingModule { }
