<app-navbar></app-navbar>
<br>
<!-- separador -->
<div class="container ">
  <div class="row justify-content-center ">
  </div>
  <img src="../../../../src/assets/img/sep_azul.png" width="80" height="8" class="responsive" alt="...">
</div>
<br>
<!-- contenido texto -->
<div class="container ">
  <div class="row justify-content-center ">
    <div class="col-xl-3 ">
    </div>
    <div class="col-xl-6 ">
      <p class="text-justify font-roboto ">
        Sistemas Comerciales SYSCOM S. A. es una empresa especializada en la implantación de soluciones de software específico
        para diferentes sectores industriales y comerciales, contando para ello con una serie de colaboradores tecnológicos
        de reconocido prestigio: Microsoft.
      </p>
      <br >
    </div>
    <div class="col-xl-3 ">
    </div>
  </div>
  <!-- grupo de trabajo -->
  <div class="row justify-content-center ">
    <div class="col-xl-3 ">
    </div>
    <div class="col-xl-6 ">
      <img src=".../../../../src/assets/img/acerca/img5.png "
           class="img-fluid" alt="Responsive Image"
           width="500" height="242" />
    </div>
    <div class="col-xl-3 ">
    </div>
  </div>
</div>
<br><br>
<!-- contenido vacantes -->
<br><br><br>
<div id="bg-blue-syscom">
  <br><br>
  <div class="p-3 mb-2">
    <div class="container col-xl-6 justify-content-center">
      <div class="container col-xl-7 justify-content-center">
        <p class="text-justify font-roboto ">Unirse al equipo de Sistemas comerciales SYSCOM significa mucho más que trabajar con colegas extraordinarios y apoyar a los héroes de cada día en el crecimiento de sus negocios.</p>
      </div>
        <br><br>
        <!--contenido vacantes seccion white-->
        <div class="row justify-content-center bg-light">
          <div class="col-xl-4 ">
            <p class="font-roboto" align="center">Equipo de Desarrollo</p>
            <p class="font-roboto" align="center">Analista de software</p>
          </div>
          <div class="col-xl-4">
            <p class="font-roboto" align="center">Tiempo completo</p>
            <p class="font-roboto" align="center">Bucaramanga</p>
          </div>
          <div class="col-xl-4" align="center">
            <br />
            <a class="btn btn-secondary" href="mailto:pramirez@syscom.com.co" role="button">Aplicar</a>
          </div>
        </div>
        <br />
        <!--contenido vacantes seccion white-->
        <div class="row justify-content-center bg-light">
          <div class="col-xl-4 ">
            <p class="font-roboto" align="center">Equipo de Desarrollo</p>
            <p class="font-roboto" align="center">Desarrollador</p>
          </div>
          <div class="col-xl-4">
            <p class="font-roboto" align="center">Tiempo completo</p>
            <p class="font-roboto" align="center">Bucaramanga</p>
          </div>
          <div class="col-xl-4" align="center">
            <br />
            <a class="btn btn-secondary" href="mailto:pramirez@syscom.com.co" role="button">Aplicar</a>
          </div>
        </div>
        <br />    
      </div>
    </div>
</div>
