import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post8',
  templateUrl: './post8.component.html',
  styleUrls: ['./post8.component.scss']
})
export class Post8Component implements OnInit {

  constructor() { }

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other

  }
  ngOnInit(): void {
  }

}
