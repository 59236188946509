<app-navhelpdesk></app-navhelpdesk>

<!--seccion 1-->
<div class="nav" style="background-color: #F4F4F4;">
  <div class="container" style="background-color: #FFF;">
    <div class="container">
      <form class="row gx-3 gy-2 align-items-center">
        <div class="col-sm-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
            <label class="form-check-label" for="autoSizingCheck2">
              Seleccionar todo
            </label>
          </div>
        </div>
        <div class="col-sm-3">
          <input class="form-empresa me-2" type="search" placeholder="Search" aria-label="Search">
        </div>
        <div class="col-sm-3">
          <img src="../../../../src/assets/img/helpdesk/ticket/exportar.png" width="90" height="27" class="img-fluid">
        </div>
        <div class="col-auto">
          <p>9 de 26</p>
        </div>
        <div class="col-auto">
          <img src="../../../../src/assets/img/helpdesk/ticket/flecha1.png" width="80" height="10" class="img-fluid">
        </div>
      </form>
    </div>
  </div>
  <!--seccion 2-->
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="col-sm-7  align-self-center text-center" style="background-color: #F4F4F4;">
      </div>
      <div class="col-sm-1 align-self-center text-center" style="background-color: #F4F4F4;"> </div>
      <div class="col-sm-4 align-self-center text-center" style="background-color: #FFF;">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center h-100">
      <div class="divscroll col-sm-8 align-self-center text-center" style="background-color: #FFF;">
        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3" style="background-color:#fff"> 
              Contacto
            </div>
            <div class="col-md-3" style="background-color:#fff">
              Cargo
            </div>
            <div class="col-md-4" style="background-color:#fff">
              Correo Electrónico
            </div>
            <div class="col-md-2" style="background-color:#fff">
              Celular
            </div>
          </div>
          <hr>
        </div>
        <div class="container">
          <hr>
          <a routerLink="/contactohelpdesk">
            <div class="row">     
              <div class="col-md-3" style="background-color:#fff">
                <input class="form-check-input" type="checkbox" id="autoSizingCheck2">
                Alejandro Cortez
              </div>
              <div class="col-md-3" style="background-color:#fff">
                Asesor
              </div>
              <div class="col-md-4" style="background-color:#fff">
                info@transcarga.com
              </div>
              <div class="col-md-2" style="background-color:#fff">
                3183526365
              </div>
            </div>
          </a>
            <hr>
        </div>
      </div>
      <div class="col-sm-4  text-center" style="background-color: #F4F4F4;">
        <p id="roboto-help31">
          Filtro
        </p>
        <p id="roboto-help32">
          Creado
        </p>
        <select class="form-select" aria-label="Default select example">
          <option selected>Desde siempre</option>
          <option value="1">Hoy</option>
          <option value="2">Ayer</option>
          <option value="3">Hace una semana</option>
          <option value="3">Hace un mes</option>
        </select>
        <br><br>
        <button routerLink="/verticket" class="btn btn-helpdesk2" type="submit" title="Crear el Ticket">Aplicar</button>
      </div>
    </div>
  </div>

</div>


