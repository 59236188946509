<!--<app-navbar></app-navbar>-->
<!--<div class="wrapper">-->
<head>
  <mga-google-analytics></mga-google-analytics>
</head>
<div>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>


<app-scroll></app-scroll>

