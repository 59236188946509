<app-navhelpdesk></app-navhelpdesk>

<div class="nav" style="background-color: #F5f6f7;">
  <br>
  <div class="container h-100" style="background-color: #fff;">
    <div class=" row justify-content-center h-100">
      <div class=" col-lg-6">
        <input class="form-empresa me-2" type="search" placeholder="Search" aria-label="Search">
        <br /><br />

     
      </div>
      <div class="col-lg-6">
        <button   routerLink="/nuevoarticulo" class=" btn-helpdesk2 ">Nuevo Articulo</button>
      </div>
    </div>
  </div>
  <br>  <br>
  <div class="container h-100" style="background-color: #fff;">
    <div class=" row justify-content-center h-100">
      <div class="divscroll col-lg-6">
<div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                <button class="btn dropdown-toggle" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Activos Fijos 40
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Errores Activos Fijos 40
                <br>
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Errores Activos Fijos 40
                <br>
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Tutorial Activos Fijos 40
              </div>
              </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">

                <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Gestión Comercial
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">

                Errores Gestión Comercial
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Mantenimiento Preventivo
                </button>
              </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="card-body">

                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Errores Mantenimiento Preventivo
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                <button class=" btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Soporte técnico
                </button>
              </h5>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
              <div class="card-body">

                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">
                Errores Soporte técnico
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Syscom 20 Estaciones de Servicio
                </button>
              </h5>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
              <div class="card-body">
                <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">

             Errores Syscom 20 Estaciones de Servicio
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingSix">
                <h5 class="mb-0">
                  <img src="../../../../src/assets/img/helpdesk/conocimiento/img2.png" class="img-fluid" width="40" height="42" loading="lazy">
                  <button class="btn dropdown-toggle collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive">
                    Syscom 40 Activos Fijos
                  </button>
                </h5>
              </div>
              <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body">
                  <img src="../../../../src/assets/img/helpdesk/conocimiento/img3.png" class="img-fluid" width="25" height="22" loading="lazy">

                  Errores Syscom 40 Activos Fijos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="container h-100">
          <div class="row justify-content-center h-100">
            <div class="col-lg-6">
              <div class="align-self-center text-center" style="border: 3px thick;">
                <table>
                  <tr>
                    <th>
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img1.png" class="img-fluid" width="40" height="55" loading="lazy">
                    </th>
                    <th>
                      <p id="roboto-contend">
                        Error N° 090
                      </p>
                      <p id="roboto-contend1">
                        Creado por Andres Gelvez
                      </p>
                      <p id="roboto-contend2">
                        Hace 2 Horas
                      </p>
                    </th>
                  </tr>
                </table>
              </div>
              <br>
              <div class="align-self-center text-center" style="border: 3px thick;">
                <table>
                  <tr>
                    <th>
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img1.png" class="img-fluid" width="40" height="55" loading="lazy">
                    </th>
                    <th>
                      <p id="roboto-contend">
                        Error N° 011
                      </p>
                      <p id="roboto-contend1">
                        Creado por Yaqueline Lizarazo
                      </p>
                      <p id="roboto-contend2">
                        Hace 8 Horas
                      </p>
                    </th>
                  </tr>
                </table>
              </div>
              <br>
              <div class="align-self-center text-center" style="border: 3px thick;">
                <table>
                  <tr>
                    <th>
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img1.png" class="img-fluid" width="40" height="55" loading="lazy">
                    </th>
                    <th>
                      <p id="roboto-contend">
                        Error N° 023
                      </p>
                      <p id="roboto-contend1">
                        Creado por John Jairo Roa
                      </p>
                      <p id="roboto-contend2">
                        Hace 1 Día
                      </p>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="align-self-center text-center" style="border: 3px thin red;">
                <table>
                  <tr>
                    <th>
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img1.png" class="img-fluid" width="40" height="55" loading="lazy">
                    </th>
                    <th>
                      <p id="roboto-contend">
                        Error No. 111
                      </p>
                      <p id="roboto-contend1">
                        Creado por Heiman Daza
                      </p>
                      <p id="roboto-contend2">
                        Hace 2 Días
                      </p>
                    </th>
                  </tr>
                </table>
              </div>
              <br>
              <div class="align-self-center text-center" style="border: 3px thin red;">
                <table>
                  <tr>
                    <th>
                      <img src="../../../../src/assets/img/helpdesk/conocimiento/img1.png" class="img-fluid" width="40" height="55" loading="lazy">
                    </th>
                    <th>
                      <p id="roboto-contend">
                        Error No. 134
                      </p>
                      <p id="roboto-contend1">
                        Creado por Andres Gelvez
                      </p>
                      <p id="roboto-contend2">
                        Hace 1 semana
                      </p>
                    </th>
                  </tr>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <br>  <br>
</div>

<br><br><br><br>
<br><br><br><br>
