<!--menu blog-->

<nav class="navbar sticky-top navbar-expand-lg navbar-light" style="background-color: #F4F4F4;">
  <div class="container">
    <div class="navbar-nav" data-target=".navbar-collapse" data-toggle="collapse">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <a class="navbar-brand" (click)="scrollTop()" [routerLink]="['/inicio']">
      <img src="../../../../src/assets/img/Navegacion/logo.png" width="180" height="44" class="img-fluid">
    </a>
    <div class="collapse navbar-collapse navbar-brand glyphicon-object-align-right" id="navbarSupportedContent">
      <div class=" navbar-nav mr-auto ml-auto">
      </div>
    </div>
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog1.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog2.png" width="35" height="35" alt="" loading="lazy">
      </li>
      <li class="nav-item centrarvertical">
        <img src=".../../../../src/assets/img/blog/menu-blog3.png" width="35" height="35" alt="" loading="lazy">
      </li>
    </ul>
  </div>
</nav>
<br><br><br>
<div class="container">
  <div class="row justify-content-center ">
    <div class="col-xl-8 ">
      <p class="roboto-center18">Nueva versión de Autofactura</p>
    </div>
  </div>
  <br><br>
  <!-- contenido pos -->
  <div class="container ">
    <div class="row justify-content-center ">
      <div class="col-xl-8 ">
        <img src="../../../../src/assets/img/blog/img4.jpg"
             class="img-fluid" alt="Responsive Image"
             width="880" height="400" />
        <br>  <br>
      </div>
      <br><br>
          <div class="col-xl-8 ">
            <p class="text-justify font-roboto ">
              Syscom Autofactura es una solución en la nube que le permite generar su factura electrónica sin necesidad de instalar nada. Desde cualquier computador con internet usted puede ingresar con usuario y contraseña, concede reducir la complejidad y aumentar la eficacia de los procesos relacionados a la facturación de las empresas.
              <br><br>
              En Syscom Autofactura  estamos para ayudarte a ganar tiempo y tranquilidad mientras mejoras la forma en la que haces tu trabajo, por ello te damos nuevas características  para que puedas enviar sus facturas electrónicas a la DIAN con mayor facilidad.
              <br><br>
              Ventajas que trae la nueva versión de Autofactura:
              <br><br>
            </p>
            <ul>
              <li>Facturación de cuantías menores</li>
              <li>Consulta y exportación del detalle de facturas del mes</li>
              <li>Se podrán facturar productos gravados y con el impuesto al consumo</li>
              <li>Se pueden incluir descuentos en el detalle y descuentos globales a la facturación</li>
              <li>Se pueden registrar cargos adicionales a la factura</li>
              <li>Manejo de autorretención en la fuente y autorretención de ICA</li>
              <li>Personalización de los formatos de impresión con el logo de la empresa</li>
            </ul>
          </div>
    </div>
    <br> <br>
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-xl-8">
          <table>
            <tr>
              <td width="12px"></td>
              <td style="vertical-align:bottom;"><p class="font-weight-bold">Compartir</p></td>
              <td width="8px"></td>
              <td>
                <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/facemouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/face2.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/facemouse.png' " />
                </a>
                <a href="https://twitter.com/?status=Me gusta esta página http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/twitermouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/twiter.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/twitermouse.png' " />
                </a>
                <a href="https://www.linkedin.com/shareArticle?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/inmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/in.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/inmouse.png' " />
                </a>
                <a href="http://pinterest.com/pin/create/button/?url=http://www.syscom.com.co/post3" target="_blank">
                  <img src=".../../../../src/assets/img/blog/pinterestmouse.png"
                       width="35" height="35"
                       onMouseOver="this.src='.../../../../src/assets/img/blog/pinterest.png' "
                       onMouseOut="this.src='.../../../../src/assets/img/blog/pinterestmouse.png' " />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</div>

<!-- footer -->
<footer>
  <div class="container-fluid">
    <div class=" mb-2  navbar-light  nav">
      <div align="center" class="col-xl-4">
        <img src="../../../../src/assets/img/blog/logo-blog.png " width="250" height="230" class="img-fluid " alt="... "><br><br>
      </div>
      <div class="col-xl-5">
        <div id="outerdiv">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfB4cWTgb0vxbkqW9_xWHVMTQmIxNIaGuqElfmFGadkVwIMtA/viewform?embedded=true" scrolling="no" width="500" height="660"
                  frameborder="0" marginheight="0" marginwidth="0">
            Cargando…
          </iframe>
        </div>
      </div>
      <!-- SnapWidget -->
      <iframe src="" scrolling="no" width="320" height="680"
              frameborder="0" marginheight="0" marginwidth="0">
        Cargando…
      </iframe>
    </div>
  </div>
</footer>
